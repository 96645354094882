'use client';
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getSession, signIn, signOut, useSession } from 'next-auth/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DEFAULT_AUTH_PROVIDER } from './next-auth';
const SILENT_AUTH_KEY = 'silent-auth';
const SILENT_AUTH_EXPIRY = 60 * 60 * 1 * 1000; // 1 hour
const storage = (key) => ({
    setItem: (value) => {
        return window.localStorage.setItem(key, value);
    },
    getItem: () => {
        return window.localStorage.getItem(key);
    },
    removeItem: () => {
        return window.localStorage.removeItem(key);
    },
});
const silentAuthStorage = storage(SILENT_AUTH_KEY);
var SessionStatus;
(function (SessionStatus) {
    SessionStatus["Loading"] = "loading";
    SessionStatus["Unauthenticated"] = "unauthenticated";
    SessionStatus["Authenticated"] = "authenticated";
})(SessionStatus || (SessionStatus = {}));
function useSilentAuth(isSilentAuthEnabled, status) {
    const [isLoading, setLoading] = useState(() => status === SessionStatus.Unauthenticated);
    useEffect(() => {
        if (status === SessionStatus.Unauthenticated && isSilentAuthEnabled) {
            const silentAuthTimestamp = silentAuthStorage.getItem();
            const isValidTimestamp = !!silentAuthTimestamp && !isNaN(+silentAuthTimestamp);
            const hasCompletedSilentSignin = isValidTimestamp &&
                // time elapsed is smaller than the expiry
                Date.now() - +silentAuthTimestamp < SILENT_AUTH_EXPIRY;
            if (hasCompletedSilentSignin) {
                return;
            }
            setLoading(true);
            signIn(DEFAULT_AUTH_PROVIDER, {}, {
                prompt: 'none',
            })
                .catch(() => {
                setLoading(false);
            })
                .finally(() => {
                silentAuthStorage.setItem(Date.now().toString());
            });
        }
    }, [isSilentAuthEnabled, status]);
    return { isLoading: status !== SessionStatus.Authenticated && isLoading };
}
function newSessionHandler(session) {
    if ((session === null || session === void 0 ? void 0 : session.error) === 'RefreshAccessTokenError') {
        signIn(DEFAULT_AUTH_PROVIDER); // Force sign in to hopefully resolve error
    }
}
let refreshTokenPromise = null;
export function useAuthInitialization(options = {}) {
    var _a;
    const { shouldForceAuthentication, idp, isSilentAuthEnabled = true, isAuthenticationRequired, } = options;
    const { data: session, status, update: updateSession, } = useSession({
        required: !!isAuthenticationRequired,
        onUnauthenticated() {
            signIn(DEFAULT_AUTH_PROVIDER, undefined, Object.assign({}, (idp && { fed_idp: idp })));
        },
    });
    const { isLoading } = useSilentAuth(isSilentAuthEnabled, status);
    const accessToken = (_a = session === null || session === void 0 ? void 0 : session.accessToken) !== null && _a !== void 0 ? _a : null;
    const isNotAuthenticatedButRequired = shouldForceAuthentication && status === SessionStatus.Unauthenticated;
    useEffect(() => {
        if (shouldForceAuthentication)
            signIn(DEFAULT_AUTH_PROVIDER); // Force sign in to hopefully resolve error
    }, [shouldForceAuthentication]);
    useEffect(() => {
        if (session)
            newSessionHandler(session);
    }, [session]);
    const getAccessToken = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!accessToken)
            return null;
        const isTokenExpired = !(session === null || session === void 0 ? void 0 : session.claims) || session.claims.exp * 1000 < Date.now();
        if (isTokenExpired) {
            // make sure that the updateSession handler is only called once at a time
            if (!refreshTokenPromise) {
                refreshTokenPromise = getSession()
                    .then((session) => {
                    if (session === null || session === void 0 ? void 0 : session.error) {
                        if (session.error === 'RefreshAccessTokenError') {
                            return signIn(DEFAULT_AUTH_PROVIDER).then(() => null); // Force sign in to hopefully resolve error
                        }
                        return null;
                    }
                    return session;
                })
                    .then((session) => {
                    var _a;
                    return (_a = session === null || session === void 0 ? void 0 : session.accessToken) !== null && _a !== void 0 ? _a : null;
                })
                    .finally(() => {
                    refreshTokenPromise = null;
                    updateSession();
                });
            }
            return refreshTokenPromise;
        }
        return accessToken;
    }), [accessToken, session === null || session === void 0 ? void 0 : session.claims, updateSession]);
    return useMemo(() => {
        var _a, _b;
        return {
            getAccessToken,
            isSessionExpired: false,
            authorize: (params) => {
                signIn(DEFAULT_AUTH_PROVIDER, undefined, params);
            },
            claims: (_a = session === null || session === void 0 ? void 0 : session.claims) !== null && _a !== void 0 ? _a : null,
            isInitialized: status !== SessionStatus.Loading && !isLoading && !isNotAuthenticatedButRequired,
            isLoading: status === SessionStatus.Loading || isLoading,
            isLoggedIn: status === SessionStatus.Authenticated,
            userInfo: (_b = session === null || session === void 0 ? void 0 : session.userInfo) !== null && _b !== void 0 ? _b : null,
            logout: () => {
                const searchParams = new URLSearchParams({
                    id_token_hint: session === null || session === void 0 ? void 0 : session.idToken,
                    previous_page: window.location.pathname,
                });
                signOut({
                    // logic to redirect to the correct page after logout
                    callbackUrl: `signOut?id_token_hint=${searchParams.get('id_token_hint')}&previous_page=${searchParams.get('previous_page')}`,
                });
            },
        };
    }, [
        getAccessToken,
        isLoading,
        isNotAuthenticatedButRequired,
        session === null || session === void 0 ? void 0 : session.claims,
        session === null || session === void 0 ? void 0 : session.idToken,
        session === null || session === void 0 ? void 0 : session.userInfo,
        status,
    ]);
}
