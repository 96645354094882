var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { arrayToTree } from 'performant-array-to-tree';
import print from 'prntr';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useMeasure } from 'react-use';
import { useContentExport } from '../../context/ContentExportProvider';
import { TocEntriesDocument, } from '../../generated/content-api/graphql';
import { CreateDocumentDocument, DocumentDocument, } from '../../generated/content-export/graphql';
import { deptFirstSearch } from '../../helpers/deptFirstSearch';
import { openWindow } from '../../helpers/openWindow';
import { prependHostToUrls } from '../../helpers/prependHostToUrls';
import { CONTENT_EXPORT_TYPES } from '../ContentExportPlugin/types';
import { defaultInitialState, reducer } from './reducer';
const ContentExportDrawerContext = React.createContext({});
const useContentExportDrawer = () => {
    const context = React.useContext(ContentExportDrawerContext);
    if (context === undefined) {
        throw new Error('useContentExportDrawer must be used within a ContentExportDrawerProvider');
    }
    return context;
};
const ContentExportDrawerProvider = (_a) => {
    var { swsId, type, activeId, canExportFullDocument, canExportParts, limit, taxviceShareBaseUrl, onDrawerClose, children, locale = 'nl-NL', brandingKey = 'titan' } = _a, rest = __rest(_a, ["swsId", "type", "activeId", "canExportFullDocument", "canExportParts", "limit", "taxviceShareBaseUrl", "onDrawerClose", "children", "locale", "brandingKey"]);
    const [drawerHeaderRef, { height: drawerScrollOffset }] = useMeasure();
    const { siteName, applicationKey, urls, exportClient, contentClient, onError } = useContentExport();
    const initialState = Object.assign(Object.assign({}, defaultInitialState), { defaultCanExportFullDocument: canExportFullDocument, canExportFullDocument,
        canExportParts,
        limit, contentParts: [], type: type || CONTENT_EXPORT_TYPES.PRINT });
    const [state, dispatch] = useReducer(reducer, initialState);
    const transformData = useCallback((arr = []) => {
        const transformedData = arr.map((_a) => {
            var { anchor, id, label, name } = _a, child = __rest(_a, ["anchor", "id", "label", "name"]);
            return (Object.assign({ id, value: anchor, label: label || name }, child));
        });
        return arrayToTree(transformedData, { dataField: null });
    }, []);
    const getChildIdsByTreeItem = useCallback((items) => items.reduce((values, item) => {
        var _a;
        return [
            ...values,
            item.value,
            ...(((_a = item.children) === null || _a === void 0 ? void 0 : _a.length) ? getChildIdsByTreeItem(item.children) : []),
        ];
    }, []), []);
    const getFlatIdsArray = useCallback((arr) => arr.map((item) => item.anchor), []);
    const onTreeDataSuccess = useCallback((data) => {
        var _a, _b, _c;
        const toc = ((_b = (_a = data.content) === null || _a === void 0 ? void 0 : _a.toc) === null || _b === void 0 ? void 0 : _b.children) || [];
        const treeOptions = transformData(toc);
        const treeIds = getFlatIdsArray(toc);
        dispatch({ type: 'SET_TREE_OPTIONS', payload: { treeOptions } });
        dispatch({ type: 'SET_TREE_IDS', payload: { treeIds } });
        dispatch({ type: 'SET_DOCUMENT_TITLE', payload: { title: (_c = data.content) === null || _c === void 0 ? void 0 : _c.title } });
    }, [getFlatIdsArray, transformData]);
    const { loading: isTreeDataLoading, error: treeDataError } = useQuery(TocEntriesDocument, {
        variables: {
            swsId,
            applicationKey,
        },
        client: contentClient,
        onCompleted: onTreeDataSuccess,
    });
    const url = useMemo(() => 
    // eslint-disable-next-line no-nested-ternary
    typeof window !== 'undefined'
        ? window.location.href.includes('#')
            ? window.location.href.substr(0, window.location.href.indexOf('#'))
            : window.location.href
        : '', []);
    const [createDocument, { data: mutationData, error: mutationError }] = useMutation(CreateDocumentDocument, {
        variables: {
            input: {
                swsId,
                contentParts: state.isAllChecked ? [] : state.contentParts,
                siteName,
                applicationKey,
                urls: prependHostToUrls(urls),
                url,
                locale,
                brandingKey,
            },
        },
        client: exportClient,
    });
    const { data: subscriptionData, error: subscriptionError } = useSubscription(DocumentDocument, {
        variables: {
            jobId: (mutationData === null || mutationData === void 0 ? void 0 : mutationData.createDocument.jobId) || '',
            token: (mutationData === null || mutationData === void 0 ? void 0 : mutationData.createDocument.token) || '',
        },
        skip: !(mutationData === null || mutationData === void 0 ? void 0 : mutationData.createDocument.jobId) || !mutationData.createDocument.token,
        client: exportClient,
    });
    useEffect(() => {
        // Clean Up
        return () => {
            dispatch({ type: 'RESET' });
        };
    }, []);
    const onClose = useCallback(() => {
        dispatch({ type: 'RESET' });
        onDrawerClose();
    }, [onDrawerClose]);
    const onSelectionChange = useCallback((contentParts) => {
        // splice the array with limit if shareToTaxvice is used.
        const contentPartsFiltered = type === CONTENT_EXPORT_TYPES.SHARE_TO_TAXVICE
            ? contentParts.splice(0, limit)
            : contentParts;
        dispatch({ type: 'TOGGLE_SELECT_ALL', payload: { isChecked: false } });
        dispatch({ type: 'SET_CONTENT_PARTS', payload: { contentParts: contentPartsFiltered } });
    }, [limit, type]);
    const onPrint = useCallback(() => {
        dispatch({
            type: 'SET_LOADING',
        });
        createDocument();
    }, [createDocument]);
    const onShareToTaxvice = useCallback(() => {
        var _a;
        if (!taxviceShareBaseUrl) {
            throw new Error('Property taxviceShareBaseUrl is not defined');
        }
        const ndfrUri = new URL(`${window.location.origin}${urls.contentUrl}/${swsId}`);
        let ndfrName = (_a = state.documentTitle) !== null && _a !== void 0 ? _a : '';
        if (!state.isAllChecked && state.contentParts && state.contentParts.length > 0) {
            // get the last element of the array and add it as a hash key
            const firstElement = state.contentParts[state.contentParts.length - 1];
            ndfrUri.hash = `#${firstElement}`;
            if (firstElement) {
                // find title of chapter and add to end of the title of the document
                const activeItem = deptFirstSearch(firstElement, state.treeOptions, {
                    idField: 'value',
                    childrenField: 'children',
                });
                const chapterTitle = activeItem === null || activeItem === void 0 ? void 0 : activeItem.label;
                if (chapterTitle) {
                    ndfrName += ` - ${chapterTitle}`;
                }
            }
        }
        const shareUrl = new URL(taxviceShareBaseUrl);
        shareUrl.searchParams.append('ndfr_name', ndfrName);
        shareUrl.searchParams.append('ndfr_url', ndfrUri.toString());
        openWindow(shareUrl.toString(), 632, 768);
    }, [
        state.contentParts,
        state.documentTitle,
        state.isAllChecked,
        state.treeOptions,
        swsId,
        taxviceShareBaseUrl,
        urls.contentUrl,
    ]);
    const onSubmit = useCallback(() => {
        if (type === CONTENT_EXPORT_TYPES.SHARE_TO_TAXVICE) {
            onShareToTaxvice();
        }
        else {
            onPrint();
        }
    }, [onPrint, onShareToTaxvice, type]);
    const onReset = useCallback(() => {
        dispatch({ type: 'RESET' });
    }, []);
    const onChangeSelectAll = useCallback((isChecked) => {
        dispatch({ type: 'TOGGLE_SELECT_ALL', payload: { isChecked } });
        dispatch({ type: 'RESET_CONTENT_PARTS' });
    }, []);
    const handleClose = useCallback(() => {
        // On Safari 14+ reload the page when drawer closes because of a bug when printing twice
        // @see https://github.com/crabbly/Print.js/issues/528
        onClose();
    }, [onClose]);
    useEffect(() => {
        // the items that are selected in the options tree
        let contentParts = [];
        // if there is an 'active id', add it to the content parts
        if (activeId) {
            const activeItem = deptFirstSearch(activeId, state.treeOptions, {
                idField: 'value',
                childrenField: 'children',
            });
            // only add content parts, if there are options in the tree
            contentParts =
                state.treeOptions.length > 0
                    ? [activeId, ...((activeItem === null || activeItem === void 0 ? void 0 : activeItem.children) ? getChildIdsByTreeItem(activeItem.children) : [])]
                    : [];
        }
        dispatch({ type: 'SET_CONTENT_PARTS', payload: { contentParts } });
        // if there are no options in the tree, select all if the user can export full document
        const selectAll = !isTreeDataLoading &&
            !!canExportFullDocument &&
            state.treeOptions.length === 0 &&
            contentParts.length === 0;
        onChangeSelectAll(selectAll);
    }, [
        activeId,
        isTreeDataLoading,
        state.treeOptions,
        canExportFullDocument,
        onChangeSelectAll,
        getChildIdsByTreeItem,
    ]);
    useEffect(() => {
        if (!(subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.documentUpdated.documentUrl) || typeof window === 'undefined')
            return;
        switch (type) {
            case 'print':
                print({
                    type: 'pdf',
                    documentTitle: `${swsId}-pdf`,
                    printable: subscriptionData.documentUpdated.documentUrl,
                    onIncompatibleBrowser: () => dispatch({ type: 'SET_ERROR' }),
                    onError: () => dispatch({ type: 'SET_ERROR' }),
                    onLoadingStart: () => dispatch({ type: 'SET_LOADING' }),
                    onLoadingEnd: () => dispatch({ type: 'RESET' }),
                    onPrintDialogClose: () => handleClose(),
                });
                break;
            case 'download':
                window.location.href = subscriptionData.documentUpdated.documentUrl;
                onClose();
                break;
        }
    }, [type, swsId, subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.documentUpdated.documentUrl, handleClose, onClose]);
    useEffect(() => {
        const hasDocumentFailed = (subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.documentUpdated.status) === 'FAILED';
        if (hasDocumentFailed) {
            dispatch({ type: 'SET_ERROR' });
        }
    }, [subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.documentUpdated.status]);
    useEffect(() => {
        if (mutationError || subscriptionError) {
            dispatch({ type: 'SET_ERROR' });
            onError === null || onError === void 0 ? void 0 : onError(mutationError ? 'mutation' : 'subscription', mutationError || subscriptionError);
        }
    }, [subscriptionError, mutationError, onError]);
    const store = useMemo(() => (Object.assign(Object.assign(Object.assign({}, state), { isTreeDataLoading,
        treeDataError,
        type,
        drawerHeaderRef,
        drawerScrollOffset,
        activeId,
        onClose,
        onSubmit,
        onSelectionChange,
        onReset,
        onChangeSelectAll }), rest)), [
        state,
        isTreeDataLoading,
        treeDataError,
        type,
        drawerHeaderRef,
        drawerScrollOffset,
        activeId,
        onClose,
        onSubmit,
        onSelectionChange,
        onReset,
        onChangeSelectAll,
        rest,
    ]);
    return (_jsx(ContentExportDrawerContext.Provider, { value: store, children: children }));
};
export { ContentExportDrawerContext, ContentExportDrawerProvider, useContentExportDrawer };
