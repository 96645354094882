import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Long: { input: any; output: any };
};

export type AboutItem = {
  __typename?: 'AboutItem';
  /** Alternate identifier(s) (URI) */
  altIdentifier: Array<Scalars['String']['output']>;
  /** Alternate key(s) */
  altKey: Array<Scalars['String']['output']>;
  alternative: Array<AlternativeItem>;
  /** case date (only used for case law) */
  caseDate: Array<Scalars['String']['output']>;
  /** case number(s) (only used for case law) */
  caseNumber: Array<Scalars['String']['output']>;
  /** case type(s) (only used for case law) (controlled vocabulary) */
  caseType: Array<Scalars['String']['output']>;
  /** contributor(s) other than author/creator */
  contributor: Array<ContributorItem>;
  /** author(s)/creator(s) (http://dublincore.org/documents/dcmi-terms/) */
  creator: Array<CreatorItem>;
  displayTitle?: Maybe<Scalars['String']['output']>;
  documentDate?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  /** link to the content of identifier */
  identifierLink?: Maybe<ContentForAboutId>;
  /** link element used for publications referencing this primary source */
  isReferencedBy: Array<IsReferencedByItem>;
  part: Array<PartItem>;
  /** rich text party information for case law. XHTML format */
  parties?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: Array<Scalars['String']['output']>;
  valid?: Maybe<ValidModel>;
};

export type AboutItemIdentifierLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type AllArticles = {
  __typename?: 'AllArticles';
  /** all articles that user has access to */
  articles: Array<MagazineArticle>;
};

export type AlternativeItem = {
  __typename?: 'AlternativeItem';
  /** title */
  title: Scalars['String']['output'];
  /** type */
  type?: Maybe<Scalars['String']['output']>;
};

export type Article = Content & {
  __typename?: 'Article';
  /** authors of the article */
  articleAuthors: Array<Author>;
  /** parts of the content */
  contentParts: Array<ContentPart>;
  /** URL to the footnotes */
  footNotes?: Maybe<Scalars['String']['output']>;
  /** does the content type has toc */
  hasToc: Scalars['Boolean']['output'];
  /** id for fetching the content */
  id: Scalars['String']['output'];
  /**
   * Is legislative overview
   * @deprecated Overviews not shown in Article type anymore
   */
  isLegislativeOverview: Scalars['Boolean']['output'];
  /** Is magazine article */
  isMagazineArticle: Scalars['Boolean']['output'];
  /** link(s) to content */
  link?: Maybe<ContentLink>;
  /** SWS metadata for the document */
  metadata: SwsCompactMetadata;
  /** link to the original source of the document */
  originalSourceUrl: Scalars['String']['output'];
  /** other articles from edition - if Article is magazineArticle */
  otherArticles?: Maybe<Array<BasicMagazineArticle>>;
  /** pdf attachment */
  pdfAttachment?: Maybe<Scalars['String']['output']>;
  /** previous and next article from current edition - if Article is magazineArticle */
  prevNextArticle: PrevNextLinks;
  /** subtitle */
  subtitle: Scalars['String']['output'];
  /** title for the content */
  title: Scalars['String']['output'];
  /** The table of contents of this document */
  toc?: Maybe<ArticleTocEntry>;
};

export type ArticleContentPartsArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleFootNotesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleOtherArticlesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ArticlePrevNextArticleArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleContentPart = ContentPart & {
  __typename?: 'ArticleContentPart';
  /** anchor id to get part of the content */
  anchor: Scalars['String']['output'];
  /** all anchor ids in the content part */
  anchors: Array<Scalars['String']['output']>;
  /** depth level of the part of the content */
  depth: Scalars['Int']['output'];
  /** footNotes in one content-part */
  footNotes: Array<FootNote>;
  /** html of the content part with anchor id */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with anchor id */
  htmlBlob: Scalars['String']['output'];
  /** the id of the content part */
  id: Scalars['String']['output'];
  /** true if htmlBlob content is empty */
  isContentEmpty: Scalars['Boolean']['output'];
  /** title for the part of the content */
  title: Scalars['String']['output'];
};

export type ArticleData = {
  __typename?: 'ArticleData';
  /** link to the law article */
  articleLink: Scalars['String']['output'];
  /** The title of the referenced law article */
  title: Scalars['String']['output'];
};

export type ArticleLawTocEntry = LawTocEntry & {
  __typename?: 'ArticleLawTocEntry';
  /** The location anchor */
  anchor: Scalars['String']['output'];
  /** The name of the article */
  articleName: Scalars['String']['output'];
  /** The number of the article */
  articleNumber: Scalars['String']['output'];
  /** The canonical location to retrieve the (containing) document */
  canonicalLink: Scalars['String']['output'];
  /** Lower-level table of contents */
  entries: Array<LawTocEntry>;
  /** does it have sublevels */
  hasChildren: Scalars['Boolean']['output'];
  /** id of the tocEntry */
  id: Scalars['String']['output'];
  /** The location to retrieve the section from */
  link: Scalars['String']['output'];
  /** The link ID */
  linkID: Scalars['String']['output'];
  /** The name of the section */
  name: Scalars['String']['output'];
  /** id of the parent node */
  parentId?: Maybe<Scalars['String']['output']>;
  /** article subtitle */
  subtitle: Scalars['String']['output'];
};

/** Table of contents */
export type ArticleTocEntry = {
  __typename?: 'ArticleTocEntry';
  /** The location anchor */
  anchor: Scalars['String']['output'];
  /** The canonical location to retrieve the (containing) document */
  canonicalLink: Scalars['String']['output'];
  /** The sub-sections beloning to this section */
  entries: Array<ArticleTocEntry>;
  /** does it have sublevels */
  hasChildren: Scalars['Boolean']['output'];
  /** id of the tocEntry */
  id: Scalars['String']['output'];
  /** The location to retrieve the section from */
  link: Scalars['String']['output'];
  /** The link ID */
  linkID: Scalars['String']['output'];
  /** The name of the section */
  name: Scalars['String']['output'];
  /** id of the parent node */
  parentId?: Maybe<Scalars['String']['output']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  /** URL for fetching the actual file */
  blob: Scalars['String']['output'];
  /** MIME type of the attachment */
  mimeType: Scalars['String']['output'];
  /** title of the attachment */
  title: Scalars['String']['output'];
};

export type Author = {
  __typename?: 'Author';
  /** Author function */
  function: Scalars['String']['output'];
  /** Author name */
  name: Scalars['String']['output'];
};

/** Table of contents */
export type BaseTocEntry = {
  /** max number of sublevels */
  depth: Scalars['Int']['output'];
  /** does it have sublevels */
  hasChildren: Scalars['Boolean']['output'];
  /** The name of the section */
  name: Scalars['String']['output'];
  /** id of the parent node */
  parentId?: Maybe<Scalars['String']['output']>;
};

export type BasicMagazineArticle = {
  __typename?: 'BasicMagazineArticle';
  /** identifier  */
  id: Scalars['String']['output'];
  /** reference link */
  linkItem: ContentForAboutId;
  /** article teaser */
  teaser: Scalars['String']['output'];
  /** title */
  title: Scalars['String']['output'];
};

export type BasicMagazineArticleTeaserArgs = {
  showEllipsis?: InputMaybe<Scalars['Boolean']['input']>;
  truncate?: InputMaybe<Scalars['Int']['input']>;
};

export type BesluitReference = {
  __typename?: 'BesluitReference';
  /** The text within the anchor element */
  anchorText: Scalars['String']['output'];
  /** Canonical link */
  canonicalLink?: Maybe<Scalars['String']['output']>;
  /** The kenmerk of the content being linked to */
  kenmerk?: Maybe<Scalars['String']['output']>;
  /** link status for reference */
  linkStatus: Scalars['String']['output'];
  /** Document type of the linked to document */
  linkType: Scalars['String']['output'];
  /** The text within the paragraph element */
  referenceText: Scalars['String']['output'];
  /** The date on which the content being linked to has gotten signature */
  signatureDate?: Maybe<Scalars['String']['output']>;
  /** The source of the content being linked to */
  source?: Maybe<Scalars['String']['output']>;
  /** Canonical link for Bron column */
  sourceCanonicalLink?: Maybe<Scalars['String']['output']>;
  /** link status for NTFR publication */
  sourceLinkStatus: Scalars['String']['output'];
  /** Whether the content being linked to is still valid */
  status: Scalars['String']['output'];
  /** Alternate key */
  swsLinkAltKey: Scalars['String']['output'];
  /** Link ID */
  swsLinkID?: Maybe<Scalars['String']['output']>;
  /** The title of the content being linkted to */
  title?: Maybe<Scalars['String']['output']>;
  /** The date from which the content being linked to is valid */
  validityDate?: Maybe<Scalars['String']['output']>;
};

export type Besluiten = Tab & {
  __typename?: 'Besluiten';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** list of references */
  references: Array<BesluitReference>;
  /** list of ruling references */
  rulingReferences: Array<RulingReference>;
};

export type BesluitenReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type BesluitenRulingReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type BesluitenVersion = {
  __typename?: 'BesluitenVersion';
  /** link to the besluiten version */
  besluitenLink: ContentForAboutId;
  /** kenmerk number of besluiten */
  kenmerk: Scalars['String']['output'];
};

export type Book = Content & {
  __typename?: 'Book';
  /** authors of the book */
  authors: Array<Author>;
  /** parts of the content */
  contentParts: Array<ContentPart>;
  /** book edition number */
  editionNumber?: Maybe<Scalars['String']['output']>;
  /** URL to the footnotes */
  footNotes?: Maybe<Scalars['String']['output']>;
  /** does the content type has toc */
  hasToc: Scalars['Boolean']['output'];
  /** id for fetching the content */
  id: Scalars['String']['output'];
  /** book isbn number */
  isbn?: Maybe<Scalars['String']['output']>;
  /** link(s) to content */
  link?: Maybe<ContentLink>;
  /** SWS metadata for the document */
  metadata: SwsCompactMetadata;
  /** link to the original source of the document */
  originalSourceUrl: Scalars['String']['output'];
  /** pdf attachment */
  pdfAttachment?: Maybe<Scalars['String']['output']>;
  /** book content only available in pdf */
  pdfOnly: Scalars['Boolean']['output'];
  /** list of older versions of a book */
  previousVersions: Array<BookOlderVersion>;
  /** publication date for books */
  publicationDate?: Maybe<Scalars['String']['output']>;
  /** title for the content */
  title: Scalars['String']['output'];
  /** The table of contents of this document */
  toc?: Maybe<BookTocEntry>;
};

export type BookContentPartsArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type BookFootNotesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type BookLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type BookPreviousVersionsArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type BookContentPart = ContentPart & {
  __typename?: 'BookContentPart';
  /** anchor id to get part of the content */
  anchor: Scalars['String']['output'];
  /** all anchor ids in the content part */
  anchors: Array<Scalars['String']['output']>;
  /** depth level of the part of the content */
  depth: Scalars['Int']['output'];
  /** footNotes in one content-part */
  footNotes: Array<FootNote>;
  /** html of the content part with anchor id */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with anchor id */
  htmlBlob: Scalars['String']['output'];
  /** the id of the content part */
  id: Scalars['String']['output'];
  /** true if htmlBlob content is empty */
  isContentEmpty: Scalars['Boolean']['output'];
  /** title for the part of the content */
  title: Scalars['String']['output'];
};

export type BookOlderVersion = {
  __typename?: 'BookOlderVersion';
  /** older version identifier */
  identifier: Scalars['String']['output'];
  /** link to the content of identifier */
  identifierLink?: Maybe<ContentForAboutId>;
  /** older version publication date */
  publicationDate: Scalars['String']['output'];
  /** older version publication identifier */
  publicationIdentifier: Scalars['String']['output'];
  /** older version title */
  title: Scalars['String']['output'];
};

/** Table of contents */
export type BookTocEntry = {
  __typename?: 'BookTocEntry';
  /** The location anchor */
  anchor: Scalars['String']['output'];
  /** The canonical location to retrieve the (containing) document */
  canonicalLink: Scalars['String']['output'];
  /** The sub-sections beloning to this section */
  entries: Array<BookTocEntry>;
  /** does it have sublevels */
  hasChildren: Scalars['Boolean']['output'];
  /** id of the tocEntry */
  id: Scalars['String']['output'];
  /** The location to retrieve the section from */
  link: Scalars['String']['output'];
  /** The link ID */
  linkID: Scalars['String']['output'];
  /** The name of the section */
  name: Scalars['String']['output'];
  /** id of the parent node */
  parentId?: Maybe<Scalars['String']['output']>;
};

export type CaseLaw = Content & {
  __typename?: 'CaseLaw';
  /** number of article */
  articleNumber?: Maybe<Scalars['String']['output']>;
  /** Author */
  author: Array<Author>;
  /** Case date */
  caseDate: Scalars['String']['output'];
  /** case number when ECLI is in cassation */
  cassationCaseNumber?: Maybe<Scalars['String']['output']>;
  /** parts of the content */
  contentParts: Array<ContentPart>;
  /** display title for edition overview page */
  editionPageTitle: Scalars['String']['output'];
  /** URL to the footnotes */
  footNotes?: Maybe<Scalars['String']['output']>;
  /**
   * document processed by moonlit
   * @deprecated Not processing caseLaws with moonlit anymore
   */
  hasMoonlit: Scalars['Boolean']['output'];
  /** does the content type has toc */
  hasToc: Scalars['Boolean']['output'];
  /** id for fetching the content */
  id: Scalars['String']['output'];
  /** Is magazine article */
  isMagazineArticle: Scalars['Boolean']['output'];
  /** link(s) to content */
  link?: Maybe<ContentLink>;
  /** SWS metadata for the document */
  metadata: SwsCompactMetadata;
  /** attachments of old dutch ECLIs */
  oldEcliPdf?: Maybe<ReferenceItem>;
  /** link to the original source of the document */
  originalSourceUrl: Scalars['String']['output'];
  /** other articles from edition - if caselaw is magazineArticle */
  otherArticles?: Maybe<Array<BasicMagazineArticle>>;
  /** pdf attachment */
  pdfAttachment?: Maybe<Scalars['String']['output']>;
  /** previous and next article from current edition - if caselaw is magazineArticle */
  prevNextArticle: PrevNextLinks;
  /** publication date */
  publicationDate?: Maybe<Scalars['String']['output']>;
  /** Tax year */
  taxYear?: Maybe<Scalars['String']['output']>;
  /** title for the content */
  title: Scalars['String']['output'];
  /** The table of contents of this document */
  toc?: Maybe<CaseLawTocEntry>;
};

export type CaseLawContentPartsArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CaseLawFootNotesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CaseLawLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CaseLawOtherArticlesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CaseLawPrevNextArticleArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CaseLawContentPart = ContentPart & {
  __typename?: 'CaseLawContentPart';
  /** anchor id to get part of the content */
  anchor: Scalars['String']['output'];
  /** all anchor ids in the content part */
  anchors: Array<Scalars['String']['output']>;
  /** depth level of the part of the content */
  depth: Scalars['Int']['output'];
  /** footNotes in one content-part */
  footNotes: Array<FootNote>;
  /** html of the content part with anchor id */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with anchor id */
  htmlBlob: Scalars['String']['output'];
  /** the id of the content part */
  id: Scalars['String']['output'];
  /** true if htmlBlob content is empty */
  isContentEmpty: Scalars['Boolean']['output'];
  /** title for the part of the content */
  title: Scalars['String']['output'];
};

export type CaseLawTocEntry = {
  __typename?: 'CaseLawTocEntry';
  /** The location anchor */
  anchor: Scalars['String']['output'];
  /** The canonical location to retrieve the (containing) document */
  canonicalLink: Scalars['String']['output'];
  /** The sub-sections beloning to this section */
  entries: Array<CaseLawTocEntry>;
  /** does it have sublevels */
  hasChildren: Scalars['Boolean']['output'];
  /** id of the tocEntry */
  id: Scalars['String']['output'];
  /** The location to retrieve the section from */
  link: Scalars['String']['output'];
  /** The link ID */
  linkID: Scalars['String']['output'];
  /** The name of the section */
  name: Scalars['String']['output'];
  /** id of the parent node */
  parentId?: Maybe<Scalars['String']['output']>;
};

export type CodeNews = Tab & {
  __typename?: 'CodeNews';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** list of related news references */
  references: Array<CodeNewsReference>;
};

export type CodeNewsReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
};

export type CodeNewsReference = {
  __typename?: 'CodeNewsReference';
  /** Document date */
  documentDate: Scalars['String']['output'];
  /** identifier */
  identifier: Scalars['String']['output'];
  /** news reference */
  reference: ReferenceItem;
  /** title */
  title: Scalars['String']['output'];
};

export type CodeRechtspraak = Tab & {
  __typename?: 'CodeRechtspraak';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** list of related rechtspraak references */
  references: Array<CodeRechtspraakReference>;
};

export type CodeRechtspraakReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
};

export type CodeRechtspraakReference = {
  __typename?: 'CodeRechtspraakReference';
  /** Case date */
  caseDate: Scalars['String']['output'];
  /** identifier */
  identifier: Scalars['String']['output'];
  /** news reference */
  reference: ReferenceItem;
  /** title */
  title: Scalars['String']['output'];
};

export type Commentaar = Tab & {
  __typename?: 'Commentaar';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** The list of commentaries that reference this part/document */
  references: Array<CommentaryReference>;
};

export type Commentary = Content & {
  __typename?: 'Commentary';
  /** list of authors */
  authors: Array<Scalars['String']['output']>;
  /** parts of the content */
  contentParts: Array<ContentPart>;
  /** URL to the footnotes */
  footNotes?: Maybe<Scalars['String']['output']>;
  /** does the content type has toc */
  hasToc: Scalars['Boolean']['output'];
  /** id for fetching the content */
  id: Scalars['String']['output'];
  /** link(s) to content */
  link?: Maybe<ContentLink>;
  /** SWS metadata for the document */
  metadata: SwsCompactMetadata;
  /** link to the original source of the document */
  originalSourceUrl: Scalars['String']['output'];
  /** subtitle for the content */
  subtitle: Scalars['String']['output'];
  /** the tabs */
  tabs: Array<Tab>;
  /** Thematic drawer, if available */
  thematicDrawer?: Maybe<ThematicDrawer>;
  /** title for the content */
  title: Scalars['String']['output'];
  /** The table of contents of this document */
  toc?: Maybe<TocEntry>;
};

export type CommentaryContentPartsArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CommentaryFootNotesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CommentaryLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CommentaryContentPart = ContentPart & {
  __typename?: 'CommentaryContentPart';
  /** anchor id to get part of the content */
  anchor: Scalars['String']['output'];
  /** all anchor ids in the content part */
  anchors: Array<Scalars['String']['output']>;
  /** depth level of the part of the content */
  depth: Scalars['Int']['output'];
  /** footNotes in one content-part */
  footNotes: Array<FootNote>;
  /** html of the content part with anchor id */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with anchor id */
  htmlBlob: Scalars['String']['output'];
  /** the id of the content part */
  id: Scalars['String']['output'];
  /** true if htmlBlob content is empty */
  isContentEmpty: Scalars['Boolean']['output'];
  /** title for the part of the content */
  title: Scalars['String']['output'];
};

export type CommentaryReference = {
  __typename?: 'CommentaryReference';
  /** The displayTitle of the commentary */
  displayTitle: Scalars['String']['output'];
  /** The sws ID of the commentary */
  identifier?: Maybe<Scalars['String']['output']>;
  /** link status for commentary */
  linkStatus: Scalars['String']['output'];
  /** sources commentary is part of */
  sources: Array<Scalars['String']['output']>;
  /** The subtitle of the commentary */
  subtitle: Scalars['String']['output'];
  /** The teaser for this commentary */
  teaser?: Maybe<Scalars['String']['output']>;
  /** The title of the commentary */
  title: Scalars['String']['output'];
};

/** Fragment of content */
export type Content = {
  /** parts of the content */
  contentParts: Array<ContentPart>;
  /** URL to the footnotes */
  footNotes?: Maybe<Scalars['String']['output']>;
  /** does the content type has toc */
  hasToc: Scalars['Boolean']['output'];
  /** id for fetching the content */
  id: Scalars['String']['output'];
  /** link(s) to content */
  link?: Maybe<ContentLink>;
  /** SWS metadata for the document */
  metadata: SwsCompactMetadata;
  /** link to the original source of the document */
  originalSourceUrl: Scalars['String']['output'];
  /** title for the content */
  title: Scalars['String']['output'];
};

/** Fragment of content */
export type ContentContentPartsArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

/** Fragment of content */
export type ContentFootNotesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Fragment of content */
export type ContentLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ContentLink = {
  __typename?: 'ContentLink';
  /** Primary link to document */
  primaryLink: Link;
  /** Secondary link to document */
  secondaryLink?: Maybe<Link>;
};

export type ContentPart = {
  /** anchor id to get part of the content */
  anchor: Scalars['String']['output'];
  /** all anchor ids in the content part */
  anchors: Array<Scalars['String']['output']>;
  /** depth level of the part of the content */
  depth: Scalars['Int']['output'];
  /** footNotes in one content-part */
  footNotes: Array<FootNote>;
  /** html of the content part with anchor id */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with anchor id */
  htmlBlob: Scalars['String']['output'];
  /** the id of the content part */
  id: Scalars['String']['output'];
  /** true if htmlBlob content is empty */
  isContentEmpty: Scalars['Boolean']['output'];
  /** title for the part of the content */
  title: Scalars['String']['output'];
};

export type ContentPartHit = {
  __typename?: 'ContentPartHit';
  /** id of the content part */
  partId: Scalars['String']['output'];
  /** number of time the search word is found in content part */
  totalResults: Scalars['Int']['output'];
};

export type ContributorItem = {
  __typename?: 'ContributorItem';
  familyName?: Maybe<Scalars['String']['output']>;
  function?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  initials?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nick?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  suffix?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CreatorItem = {
  __typename?: 'CreatorItem';
  displayName?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  familyNamePrefix?: Maybe<Scalars['String']['output']>;
  function?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  initials?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nick?: Maybe<Scalars['String']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  shortInitials?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type DateItem = {
  __typename?: 'DateItem';
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Table of contents */
export type DocumentTocEntry = BaseTocEntry &
  SubLevelTocEntry & {
    __typename?: 'DocumentTocEntry';
    /** The location anchor */
    anchor: Scalars['String']['output'];
    /** max number of sublevels */
    depth: Scalars['Int']['output'];
    /** id of the document */
    documentId?: Maybe<Scalars['String']['output']>;
    /** Lower-level table of contents */
    entries: Array<SubLevelTocEntry>;
    /** does it have sublevels */
    hasChildren: Scalars['Boolean']['output'];
    /** id of the tocEntry */
    id: Scalars['String']['output'];
    /** The location to retrieve the section from */
    link: Scalars['String']['output'];
    /** The name of the section */
    name: Scalars['String']['output'];
    /** id of the parent node */
    parentId?: Maybe<Scalars['String']['output']>;
    /** Subtitle of section */
    subtitle: Scalars['String']['output'];
    /** Title of section */
    title: Scalars['String']['output'];
  };

export type Download = {
  __typename?: 'Download';
  /** document date */
  documentDate: Scalars['String']['output'];
  /** excerpt */
  excerpt: Scalars['String']['output'];
  /** download link of ZIP */
  href: Scalars['String']['output'];
  /** identifier */
  id: Scalars['String']['output'];
  /** name of download */
  name: Scalars['String']['output'];
};

export type Downloads = {
  __typename?: 'Downloads';
  /** List of all downloads available for subscription */
  items: Array<Download>;
  /** Total number of downloads */
  total: Scalars['Int']['output'];
};

export type EcliMetadata = {
  __typename?: 'EcliMetadata';
  /** case date of ecli */
  caseDate?: Maybe<Scalars['String']['output']>;
  /** ecli identifier */
  identifier?: Maybe<Scalars['String']['output']>;
  /** document references including about */
  link?: Maybe<ContentForAboutId>;
  /** name derived from altkey */
  name?: Maybe<Scalars['String']['output']>;
  /** case date of ecli */
  publicationDate?: Maybe<Scalars['String']['output']>;
};

export type EcliMetadataLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type Edition = {
  /** article number range in one edition */
  articleNumberRange: Scalars['String']['output'];
  /** edition articles */
  articles?: Maybe<Array<MagazineArticle>>;
  /** common title for magazine */
  commonTitle: Scalars['String']['output'];
  /** publication date for magazine */
  date?: Maybe<Scalars['String']['output']>;
  /** pdf of the full edition of magazine */
  editionPDF?: Maybe<Scalars['String']['output']>;
  /** id of edition which is combination of year and magazine number */
  id: Scalars['String']['output'];
  /** magazine identifier */
  magazineId: Scalars['String']['output'];
  /** edition number */
  number: Scalars['String']['output'];
  /** identifier of doc when edition pdf only  */
  pdfEditionId?: Maybe<Scalars['String']['output']>;
  /** previous and next edition of current edition */
  prevNextEdition: PrevNextLinks;
  /** article sections */
  sections?: Maybe<Array<Scalars['String']['output']>>;
  /** short title of edition */
  shortTitle: Scalars['String']['output'];
  /** title of edition */
  title: Scalars['String']['output'];
  /** total number of articles for one edition */
  totalCount: Scalars['Long']['output'];
  /** article types */
  types?: Maybe<Array<Scalars['String']['output']>>;
  /** edition year */
  year: Scalars['String']['output'];
};

export type EditionArticlesArgs = {
  from?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<Scalars['String']['input']>>;
  size?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EditionPrevNextEditionArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type EditionTitleArgs = {
  onlyYear?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EditionWithNumber = {
  __typename?: 'EditionWithNumber';
  /** edition number */
  edition?: Maybe<Scalars['String']['output']>;
  /** publication number in edition */
  publicationNumbers?: Maybe<Scalars['String']['output']>;
};

export type EntireLawTocEntry = LawTocEntry & {
  __typename?: 'EntireLawTocEntry';
  /** The location anchor */
  anchor: Scalars['String']['output'];
  /** The canonical location to retrieve the (containing) document */
  canonicalLink: Scalars['String']['output'];
  /** Lower-level table of contents */
  entries: Array<LawTocEntry>;
  /** does it have sublevels */
  hasChildren: Scalars['Boolean']['output'];
  /** id of the tocEntry */
  id: Scalars['String']['output'];
  /** The location to retrieve the section from */
  link: Scalars['String']['output'];
  /** The link ID */
  linkID: Scalars['String']['output'];
  /** The name of the section */
  name: Scalars['String']['output'];
  /** id of the parent node */
  parentId?: Maybe<Scalars['String']['output']>;
  /** Subtitle of section */
  subtitle: Scalars['String']['output'];
};

export type Eurlex = Content & {
  __typename?: 'Eurlex';
  /** parts of the content */
  contentParts: Array<ContentPart>;
  /** URL to the footnotes */
  footNotes?: Maybe<Scalars['String']['output']>;
  /** does the content type has toc */
  hasToc: Scalars['Boolean']['output'];
  /** id for fetching the content */
  id: Scalars['String']['output'];
  /** link(s) to content */
  link?: Maybe<ContentLink>;
  /** SWS metadata for the document */
  metadata: SwsCompactMetadata;
  /** link to the original source of the document */
  originalSourceUrl: Scalars['String']['output'];
  /** pdf attachment */
  pdfAttachment?: Maybe<Scalars['String']['output']>;
  /** title for the content */
  title: Scalars['String']['output'];
  /** The table of contents of this document */
  toc?: Maybe<LawTocEntry>;
  /** available translations of document */
  translations: Array<Translation>;
};

export type EurlexContentPartsArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type EurlexFootNotesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type EurlexLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type EurlexContentPart = ContentPart & {
  __typename?: 'EurlexContentPart';
  /** anchor id to get part of the content */
  anchor: Scalars['String']['output'];
  /** all anchor ids in the content part */
  anchors: Array<Scalars['String']['output']>;
  /** depth level of the part of the content */
  depth: Scalars['Int']['output'];
  /** footNotes in one content-part */
  footNotes: Array<FootNote>;
  /** html of the content part with anchor id */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with anchor id */
  htmlBlob: Scalars['String']['output'];
  /** the id of the content part */
  id: Scalars['String']['output'];
  /** true if htmlBlob content is empty */
  isContentEmpty: Scalars['Boolean']['output'];
  /** title for the part of the content */
  title: Scalars['String']['output'];
};

export type Explanations = Tab & {
  __typename?: 'Explanations';
  /** given code has editorial explanation or not */
  hasEditorial: Scalars['Boolean']['output'];
  /** html of explanation */
  html: Scalars['String']['output'];
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** explanation sws identifier */
  identifier?: Maybe<Scalars['String']['output']>;
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** subchapter explanations */
  subChapters: Array<SubChapter>;
};

export type ExplanationsHtmlArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ExternalReference = LinkReference & {
  __typename?: 'ExternalReference';
  /** The canonical link to the referenced content */
  canonicalLink?: Maybe<Scalars['String']['output']>;
  /** The title of the referenced content */
  title?: Maybe<Scalars['String']['output']>;
};

export type ExternalSource = Content & {
  __typename?: 'ExternalSource';
  /** parts of the content */
  contentParts: Array<ContentPart>;
  /** URL to the footnotes */
  footNotes?: Maybe<Scalars['String']['output']>;
  /** does the content type has toc */
  hasToc: Scalars['Boolean']['output'];
  /** html of the external document */
  html: Scalars['String']['output'];
  /** id for fetching the content */
  id: Scalars['String']['output'];
  /** link(s) to content */
  link?: Maybe<ContentLink>;
  /** SWS metadata for the document */
  metadata: SwsCompactMetadata;
  /** link to the original source of the document */
  originalSourceUrl: Scalars['String']['output'];
  /** title for the content */
  title: Scalars['String']['output'];
};

export type ExternalSourceContentPartsArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type ExternalSourceFootNotesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ExternalSourceLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type FeedItem = {
  __typename?: 'FeedItem';
  /** month */
  month: Scalars['Int']['output'];
  /** number of new updates from month */
  updatesNumber: Scalars['Int']['output'];
  /** year */
  year: Scalars['Int']['output'];
};

export type FootNote = {
  __typename?: 'FootNote';
  /** anchor of the footnote */
  anchor: Scalars['String']['output'];
  /** html of the footnote */
  html: Scalars['String']['output'];
  /** id of the footnote */
  id: Scalars['String']['output'];
};

export type FootNoteHtmlArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type GenMagazine = Magazine & {
  __typename?: 'GenMagazine';
  /** magazine articles */
  articles: AllArticles;
  /** common title for magazine */
  commonTitle: Scalars['String']['output'];
  /** all years when magazine was published */
  editionYears: Array<Scalars['String']['output']>;
  /** editions of magazine */
  editions: Array<Edition>;
  /** publication identifier for magazine */
  id: Scalars['String']['output'];
  /** date when last edition was published */
  lastPublished?: Maybe<Scalars['String']['output']>;
  /** legislative overview (Wetgevingsoverzicht) of magazine */
  legislativeOverview?: Maybe<LegislativeOverview>;
  /** title of magazine */
  title: Scalars['String']['output'];
  /** total number of editions for one magazine */
  totalCount: Scalars['Long']['output'];
};

export type GenMagazineArticlesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type GenMagazineEditionsArgs = {
  from?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type GenericLawTocEntry = LawTocEntry & {
  __typename?: 'GenericLawTocEntry';
  /** The location anchor */
  anchor: Scalars['String']['output'];
  /** Articles in section */
  articles: Scalars['String']['output'];
  /** The canonical location to retrieve the (containing) document */
  canonicalLink: Scalars['String']['output'];
  /** Lower-level table of contents */
  entries: Array<LawTocEntry>;
  /** does it have sublevels */
  hasChildren: Scalars['Boolean']['output'];
  /** id of the tocEntry */
  id: Scalars['String']['output'];
  /** The location to retrieve the section from */
  link: Scalars['String']['output'];
  /** The link ID */
  linkID: Scalars['String']['output'];
  /** The name of the section */
  name: Scalars['String']['output'];
  /** id of the parent node */
  parentId?: Maybe<Scalars['String']['output']>;
  /** Subtitle of section */
  subtitle: Scalars['String']['output'];
  /** Title of section */
  title: Scalars['String']['output'];
};

export type GenericTocEntry = BaseTocEntry &
  SubLevelTocEntry & {
    __typename?: 'GenericTocEntry';
    /** The location anchor */
    anchor: Scalars['String']['output'];
    /** max number of sublevels */
    depth: Scalars['Int']['output'];
    /** Lower-level table of contents */
    entries: Array<SubLevelTocEntry>;
    /** does it have sublevels */
    hasChildren: Scalars['Boolean']['output'];
    /** id of the tocEntry */
    id: Scalars['String']['output'];
    /** The location to retrieve the section from */
    link: Scalars['String']['output'];
    /** The name of the section */
    name: Scalars['String']['output'];
    /** id of the parent node */
    parentId?: Maybe<Scalars['String']['output']>;
    /** Subtitle of section */
    subtitle: Scalars['String']['output'];
    /** Title of section */
    title: Scalars['String']['output'];
  };

export type GoodsCode = {
  __typename?: 'GoodsCode';
  /** 10 digit goods code without whitespaces */
  code: Scalars['String']['output'];
  /** the tabs */
  tabs: Array<Tab>;
};

export type HasFormatItem = {
  __typename?: 'HasFormatItem';
  identifier?: Maybe<Scalars['String']['output']>;
  part: Array<PartItem>;
  resource: Array<ResourceItem>;
};

export type IsReferencedByItem = {
  __typename?: 'IsReferencedByItem';
  /** Alternate key(s) */
  altKey: Array<Scalars['String']['output']>;
  displayTitle?: Maybe<Scalars['String']['output']>;
  documentDate?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  part: Array<PartItem>;
  title?: Maybe<Scalars['String']['output']>;
  type: Array<Scalars['String']['output']>;
};

export type Jurisprudentie = Tab & {
  __typename?: 'Jurisprudentie';
  /** html of the tab displayed in non table format */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with tab */
  htmlBlob?: Maybe<Scalars['String']['output']>;
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** list of case law references */
  references: Array<CaseLawReference>;
};

export type JurisprudentieHtmlArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type JurisprudentieHtmlBlobArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type JurisprudentieReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  fromYear?: InputMaybe<Scalars['Int']['input']>;
  toYear?: InputMaybe<Scalars['Int']['input']>;
};

export type Kern = Tab & {
  __typename?: 'Kern';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** ID */
  id: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** Link to the content of this tab */
  link: Scalars['String']['output'];
};

export type KernLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type LatestEditions = {
  __typename?: 'LatestEditions';
  /** all editions that user has access to */
  editions: Array<Edition>;
  /** all magazines that user has access to */
  magazines: Array<Magazine>;
};

export type LatestEditionsEditionsArgs = {
  from?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type LatestEditionsMagazinesArgs = {
  from?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type Law = Content & {
  __typename?: 'Law';
  /** parts of the content */
  contentParts: Array<ContentPart>;
  /** kenmerk of the current besluiten */
  currentBesluiten: Scalars['String']['output'];
  /** URL to the footnotes */
  footNotes?: Maybe<Scalars['String']['output']>;
  /** does the content type has toc */
  hasToc: Scalars['Boolean']['output'];
  /** id for fetching the content */
  id: Scalars['String']['output'];
  /** is this version of law expired */
  isExpired: Scalars['Boolean']['output'];
  /** id of latest version of this law */
  latestVersionOfLaw?: Maybe<Scalars['String']['output']>;
  /** link(s) to content */
  link?: Maybe<ContentLink>;
  /** SWS metadata for the document */
  metadata: SwsCompactMetadata;
  /** next versions of besluiten */
  nextBesluiten: Array<Maybe<BesluitenVersion>>;
  /** link to the original source of the document */
  originalSourceUrl: Scalars['String']['output'];
  /** previous versions of besluiten */
  previousBesluiten: Array<Maybe<BesluitenVersion>>;
  /** previous versions of this document */
  previousVersions: Array<LawArticleVersion>;
  /** title for the content */
  title: Scalars['String']['output'];
  /** The table of contents of this document */
  toc?: Maybe<LawTocEntry>;
  /** Law validity information */
  validityStatus?: Maybe<ValidityStatus>;
};

export type LawContentPartsArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type LawFootNotesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type LawLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type LawNextBesluitenArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  documentKey: Scalars['String']['input'];
};

export type LawPreviousBesluitenArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  documentKey: Scalars['String']['input'];
};

export type LawArticleVersion = {
  __typename?: 'LawArticleVersion';
  /** link to specific version of law article in bwb */
  bwbCanonicalLink?: Maybe<ContentForAboutId>;
  /** date */
  date: Scalars['String']['output'];
  /** date retroactive */
  dateRetroactive: Scalars['String']['output'];
  /** kamerdossier */
  dossierNumber: Scalars['String']['output'];
  /** is a temporal/provisional version of a treaty */
  isTemporalTreatyVersion: Scalars['Boolean']['output'];
  /** multiple versions for one date */
  lines: Array<MultipleLawVersion>;
  /** publications */
  publications: Array<PublicationsInOp>;
  /** enactment publications */
  publicationsEnactment: Array<PublicationsInOp>;
  /** text to be shown in publication enactment field */
  textInPubEnactment: Scalars['String']['output'];
  /** text to be shown in publication field */
  textInPublication: Scalars['String']['output'];
  /** type of law change */
  versionType: Scalars['String']['output'];
};

export type LawArticleVersionBwbCanonicalLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type LawContentPart = ContentPart & {
  __typename?: 'LawContentPart';
  /** anchor id to get part of the content */
  anchor: Scalars['String']['output'];
  /** all anchor ids in the content part */
  anchors: Array<Scalars['String']['output']>;
  /** The article “number” of this content part */
  article?: Maybe<Scalars['String']['output']>;
  /** depth level of the part of the content */
  depth: Scalars['Int']['output'];
  /** footNotes in one content-part */
  footNotes: Array<FootNote>;
  /** html of the content part with anchor id */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with anchor id */
  htmlBlob: Scalars['String']['output'];
  /** the id of the content part */
  id: Scalars['String']['output'];
  /** true if htmlBlob content is empty */
  isContentEmpty: Scalars['Boolean']['output'];
  /** is article vervallen */
  isExpired: Scalars['Boolean']['output'];
  tabs: Array<Tab>;
  /** title for the part of the content */
  title: Scalars['String']['output'];
};

export type LawJurisprudentie = Tab & {
  __typename?: 'LawJurisprudentie';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** list of case law references */
  references: Array<LawJurisprudentieReference>;
};

export type LawJurisprudentieReferencesArgs = {
  article: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  swsId: Scalars['String']['input'];
};

export type LawJurisprudentieReference = {
  __typename?: 'LawJurisprudentieReference';
  /** caseDate */
  caseDate: Scalars['String']['output'];
  /** caseNumber */
  caseNumber: Scalars['String']['output'];
  /** creator */
  creator: Scalars['String']['output'];
  /** documentDate */
  documentDate: Scalars['String']['output'];
  /** Ecli ID */
  ecli?: Maybe<Scalars['String']['output']>;
  /** Ecli canonical link */
  ecliCanonicalLink?: Maybe<Scalars['String']['output']>;
  /** ecli link status for reference */
  ecliLinkStatus: Scalars['String']['output'];
  /** identifier */
  identifier: Scalars['String']['output'];
  /** case law reference */
  reference?: Maybe<ReferenceItem>;
  /** title */
  title: Scalars['String']['output'];
};

export type LawNews = Tab & {
  __typename?: 'LawNews';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** list of news references */
  references: Array<LawNewsReference>;
};

export type LawNewsReferencesArgs = {
  article: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  swsId: Scalars['String']['input'];
};

export type LawNewsReference = {
  __typename?: 'LawNewsReference';
  /** documentDate */
  documentDate: Scalars['String']['output'];
  /** identifier */
  identifier: Scalars['String']['output'];
  /** newsType */
  newsType: Scalars['String']['output'];
  /** news reference */
  reference?: Maybe<ReferenceItem>;
  /** title */
  title: Scalars['String']['output'];
};

export type LawPracticeNoteReference = {
  __typename?: 'LawPracticeNoteReference';
  /** authors */
  authors: Array<Scalars['String']['output']>;
  /** documentDate */
  documentDate: Scalars['String']['output'];
  /** identifier */
  identifier: Scalars['String']['output'];
  /** practice note reference */
  reference?: Maybe<ReferenceItem>;
  /** teaser */
  teaser?: Maybe<Scalars['String']['output']>;
  /** title */
  title: Scalars['String']['output'];
};

export type LawPracticeNotes = Tab & {
  __typename?: 'LawPracticeNotes';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** list of practice note references */
  references: Array<LawPracticeNoteReference>;
};

export type LawPracticeNotesReferencesArgs = {
  article: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  swsId: Scalars['String']['input'];
};

export type LawPublication = {
  __typename?: 'LawPublication';
  /** law publication id */
  id: Scalars['String']['output'];
  /** law publication name */
  name: Scalars['String']['output'];
};

export type LawReference = LinkReference & {
  __typename?: 'LawReference';
  /** The canonical link to the referenced content */
  canonicalLink?: Maybe<Scalars['String']['output']>;
  /** link status for reference */
  linkStatus: Scalars['String']['output'];
  /** The SWS ID of the reference */
  swsLinkID?: Maybe<Scalars['String']['output']>;
  /** The title of the referenced content */
  title?: Maybe<Scalars['String']['output']>;
};

export type LawReferenceSection = {
  __typename?: 'LawReferenceSection';
  /** List of law and external references */
  references: Array<Scalars['String']['output']>;
  /** The title of references section */
  title: Scalars['String']['output'];
};

export type LawReferenceSectionReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Table of contents */
export type LawTocEntry = {
  /** Lower-level table of contents */
  entries: Array<LawTocEntry>;
  /** does it have sublevels */
  hasChildren: Scalars['Boolean']['output'];
  /** The name of the section */
  name: Scalars['String']['output'];
  /** id of the parent node */
  parentId?: Maybe<Scalars['String']['output']>;
};

export type LegalAreaItem = {
  __typename?: 'LegalAreaItem';
  identifier: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type LegislativeOverview = {
  __typename?: 'LegislativeOverview';
  /** editorial date of legislative overview */
  editorialDate: Scalars['String']['output'];
  /** overview identifier */
  identifier: Scalars['String']['output'];
  /** document references including about */
  link: ContentForAboutId;
  /** editorial date of legislative overview */
  title: Scalars['String']['output'];
};

export type LegislativeOverviewLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type Link = {
  __typename?: 'Link';
  /** true if link is external, false if it's relative link */
  isExternal: Scalars['Boolean']['output'];
  /** link url */
  url: Scalars['String']['output'];
};

/** link reference to a document */
export type LinkReference = {
  /** The canonical link to the referenced content */
  canonicalLink?: Maybe<Scalars['String']['output']>;
  /** The title of the referenced content */
  title?: Maybe<Scalars['String']['output']>;
};

export type LiteratureReference = {
  __typename?: 'LiteratureReference';
  /** The author of the referenced content */
  author?: Maybe<Scalars['String']['output']>;
  /** The canonical link to the referenced content */
  canonicalLink?: Maybe<Scalars['String']['output']>;
  /** link status for reference */
  linkStatus: Scalars['String']['output'];
  /** Document type of the linked to document */
  linkType: Scalars['String']['output'];
  /** Magazine edition of the article in the year of publishing (NTFR only) */
  number?: Maybe<Scalars['String']['output']>;
  /** Publication date of the article magazine (NTFR only) */
  publicationDate?: Maybe<Scalars['String']['output']>;
  /** The name of the publication */
  publicationName?: Maybe<Scalars['String']['output']>;
  /** Index number of the article in the year of publishing (NTFR only) */
  publicationNumber?: Maybe<Scalars['String']['output']>;
  /** The year the referenced content was published */
  publicationYear?: Maybe<Scalars['String']['output']>;
  /** The source of the referenced content */
  source?: Maybe<Scalars['String']['output']>;
  /** The url of the source */
  sourceUrl?: Maybe<Scalars['String']['output']>;
  /** The SWS ID of the reference */
  swsLinkID?: Maybe<Scalars['String']['output']>;
  /** The title of the referenced content */
  title: Scalars['String']['output'];
};

export type LiteratureReferenceSourceUrlArgs = {
  magazineBaseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type Literatuur = Tab & {
  __typename?: 'Literatuur';
  /** html of the tab displayed in non table format */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with tab */
  htmlBlob?: Maybe<Scalars['String']['output']>;
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** List of literature references */
  references: Array<LiteratureReference>;
};

export type LiteratuurHtmlArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type LiteratuurHtmlBlobArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type LiteratuurReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type Magazine = {
  /** magazine articles */
  articles: AllArticles;
  /** common title for magazine */
  commonTitle: Scalars['String']['output'];
  /** all years when magazine was published */
  editionYears: Array<Scalars['String']['output']>;
  /** editions of magazine */
  editions: Array<Edition>;
  /** publication identifier for magazine */
  id: Scalars['String']['output'];
  /** date when last edition was published */
  lastPublished?: Maybe<Scalars['String']['output']>;
  /** legislative overview (Wetgevingsoverzicht) of magazine */
  legislativeOverview?: Maybe<LegislativeOverview>;
  /** title of magazine */
  title: Scalars['String']['output'];
  /** total number of editions for one magazine */
  totalCount: Scalars['Long']['output'];
};

export type MagazineArticlesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type MagazineEditionsArgs = {
  from?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type MagazineArticle = Content & {
  __typename?: 'MagazineArticle';
  /** abstract field from metadata */
  abstract?: Maybe<Scalars['String']['output']>;
  /** jur.publ article annotators */
  annotators: Array<Author>;
  /** article number */
  articleNumber?: Maybe<Scalars['String']['output']>;
  /** article authors */
  authors: Array<Author>;
  /** parts of the content */
  contentParts: Array<ContentPart>;
  /** ECLIs connected to article */
  eclis: Array<EcliMetadata>;
  /** magazine edition */
  edition: Scalars['String']['output'];
  /** display title for edition overview page */
  editionPageTitle: Scalars['String']['output'];
  /** URL to the footnotes */
  footNotes?: Maybe<Scalars['String']['output']>;
  /** magazine article has at least one referenced pdf */
  hasPdfRefs: Scalars['Boolean']['output'];
  /** does the content type has toc */
  hasToc: Scalars['Boolean']['output'];
  /** id of article */
  id: Scalars['String']['output'];
  /** magazine article has subtype news */
  isNews: Scalars['Boolean']['output'];
  /** magazine article is old pdf document */
  isOldPdf: Scalars['Boolean']['output'];
  /** is pdf of an older edition */
  isPdfEdition: Scalars['Boolean']['output'];
  /** article keywords */
  keywords: Array<Scalars['String']['output']>;
  /** link(s) to content */
  link?: Maybe<ContentLink>;
  /** SWS metadata for the document */
  metadata: SwsCompactMetadata;
  /** article title from metadata */
  metadataTitle: Scalars['String']['output'];
  /** magazine edition number */
  number: Scalars['String']['output'];
  /** link to the original source of the document */
  originalSourceUrl: Scalars['String']['output'];
  /** other articles from edition */
  otherArticles?: Maybe<Array<BasicMagazineArticle>>;
  /** pdf attachment */
  pdfAttachment?: Maybe<Scalars['String']['output']>;
  /** magazine article content only available in pdf */
  pdfOnly: Scalars['Boolean']['output'];
  /** previous and next article from current edition */
  prevNextArticle: PrevNextLinks;
  /** article magazine popular abbreviation */
  publicationCommonTitle?: Maybe<Scalars['String']['output']>;
  /** article magazine publication date */
  publicationDate?: Maybe<Scalars['String']['output']>;
  /** article magazine */
  publicationIdentifier: Scalars['String']['output'];
  /** document references including about */
  references: Array<ReferenceItem>;
  /** article section */
  section?: Maybe<Scalars['String']['output']>;
  /** sws id of article */
  swsId: Scalars['String']['output'];
  /** article teaser */
  teaser: Scalars['String']['output'];
  /** article title */
  title: Scalars['String']['output'];
  /** type */
  type: Array<Scalars['String']['output']>;
  /** article volume */
  volume?: Maybe<Scalars['String']['output']>;
  /** magazine edition year */
  year: Scalars['String']['output'];
};

export type MagazineArticleContentPartsArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MagazineArticleFootNotesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MagazineArticleLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MagazineArticleOtherArticlesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MagazineArticlePrevNextArticleArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MagazineArticleReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MagazineArticleTeaserArgs = {
  showEllipsis?: InputMaybe<Scalars['Boolean']['input']>;
  truncate?: InputMaybe<Scalars['Int']['input']>;
};

export type MagazineArticleContentPart = ContentPart & {
  __typename?: 'MagazineArticleContentPart';
  /** anchor id to get part of the content */
  anchor: Scalars['String']['output'];
  /** all anchor ids in the content part */
  anchors: Array<Scalars['String']['output']>;
  /** depth level of the part of the content */
  depth: Scalars['Int']['output'];
  /** footNotes in one content-part */
  footNotes: Array<FootNote>;
  /** html of the content part with anchor id */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with anchor id */
  htmlBlob: Scalars['String']['output'];
  /** the id of the content part */
  id: Scalars['String']['output'];
  /** true if htmlBlob content is empty */
  isContentEmpty: Scalars['Boolean']['output'];
  /** title for the part of the content */
  title: Scalars['String']['output'];
};

export type MagazineEdition = Edition & {
  __typename?: 'MagazineEdition';
  /** article number range in one edition */
  articleNumberRange: Scalars['String']['output'];
  /** edition articles */
  articles?: Maybe<Array<MagazineArticle>>;
  /** common title for magazine */
  commonTitle: Scalars['String']['output'];
  /** publication date for magazine */
  date?: Maybe<Scalars['String']['output']>;
  /** pdf of the full edition of magazine */
  editionPDF?: Maybe<Scalars['String']['output']>;
  /** id of edition which is combination of year and magazine number */
  id: Scalars['String']['output'];
  /** magazine identifier */
  magazineId: Scalars['String']['output'];
  /** edition number */
  number: Scalars['String']['output'];
  /** identifier of doc when edition pdf only  */
  pdfEditionId?: Maybe<Scalars['String']['output']>;
  /** previous and next edition of current edition */
  prevNextEdition: PrevNextLinks;
  /** article sections */
  sections?: Maybe<Array<Scalars['String']['output']>>;
  /** short title of edition */
  shortTitle: Scalars['String']['output'];
  /** title of edition */
  title: Scalars['String']['output'];
  /** total number of articles for one edition */
  totalCount: Scalars['Long']['output'];
  /** article types */
  types?: Maybe<Array<Scalars['String']['output']>>;
  /** edition year */
  year: Scalars['String']['output'];
};

export type MagazineEditionArticlesArgs = {
  from?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<Scalars['String']['input']>>;
  size?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MagazineEditionPrevNextEditionArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MagazineEditionTitleArgs = {
  onlyYear?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MetadataResult = {
  __typename?: 'MetadataResult';
  /** Identifier */
  id: Scalars['String']['output'];
  /** SWS metadata for the document */
  metadata: SwsCompactMetadata;
};

export type MetadataResults = {
  __typename?: 'MetadataResults';
  /** The search results */
  results: Array<MetadataResult>;
  /** The total number of search results */
  totalCount: Scalars['Long']['output'];
};

export type MonthlyUpdate = {
  __typename?: 'MonthlyUpdate';
  /** list of all sections available */
  allSections: Array<Scalars['String']['output']>;
  /** list of all types available */
  allTypes: Array<Scalars['String']['output']>;
  /** total number of updates from month */
  totalNumberOfUpdates: Scalars['Int']['output'];
  /** all updates from month grouped by sections */
  updatesBySection: Array<ReferencesBySection>;
};

export type MonthlyUpdatesFeed = {
  __typename?: 'MonthlyUpdatesFeed';
  /** identifierLinkItem */
  items: Array<FeedItem>;
  /** latest document updated */
  lastUpdated: Scalars['String']['output'];
};

export type MultipleLawVersion = {
  __typename?: 'MultipleLawVersion';
  /** date retroactive */
  dateRetroactive: Scalars['String']['output'];
  /** related dossiers */
  dossierNumber: Scalars['String']['output'];
  /** publications */
  publications: Array<PublicationsInOp>;
  /** enactment publications */
  publicationsEnactment: Array<PublicationsInOp>;
  /** text to be shown in publication enactment field */
  textInPubEnactment: Scalars['String']['output'];
  /** text to be shown in publication field */
  textInPublication: Scalars['String']['output'];
  /** type of law change */
  versionType: Scalars['String']['output'];
};

/** Navigation drawer info */
export type NavigationDrawerInfo = {
  /** Navigation drawer identifier */
  id: Scalars['String']['output'];
  /** subject - first level of navigation drawer */
  subjectId: Scalars['String']['output'];
  /** subject title */
  subjectName: Scalars['String']['output'];
  /** Navigation drawer title */
  title: Scalars['String']['output'];
  /** identifier of the toc entry that links to documentKey  */
  tocId: Scalars['String']['output'];
};

export type News = Content & {
  __typename?: 'News';
  /** news authors */
  authors: Array<Author>;
  /** parts of the content */
  contentParts: Array<ContentPart>;
  /** URL to the footnotes */
  footNotes?: Maybe<Scalars['String']['output']>;
  /** news has at least one referenced pdf */
  hasPdfRefs: Scalars['Boolean']['output'];
  /** does the content type has toc */
  hasToc: Scalars['Boolean']['output'];
  /** id of news */
  id: Scalars['String']['output'];
  /** news keywords */
  keywords: Array<Scalars['String']['output']>;
  /** link(s) to content */
  link?: Maybe<ContentLink>;
  /** SWS metadata for the document */
  metadata: SwsCompactMetadata;
  /** link to the original source of the document */
  originalSourceUrl: Scalars['String']['output'];
  /** pdf attachment */
  pdfAttachment?: Maybe<Scalars['String']['output']>;
  /** news publication date */
  publicationDate?: Maybe<Scalars['String']['output']>;
  /** news publication identifier */
  publicationIdentifier: Scalars['String']['output'];
  /** publication number */
  publicationNumber?: Maybe<Scalars['String']['output']>;
  /** publication title */
  publicationTitle?: Maybe<Scalars['String']['output']>;
  /** document references including about */
  references: Array<ReferenceItem>;
  /** news section */
  section?: Maybe<Scalars['String']['output']>;
  /** news teaser */
  teaser: Scalars['String']['output'];
  /** news title */
  title: Scalars['String']['output'];
  /** news publication year */
  year: Scalars['String']['output'];
};

export type NewsContentPartsArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type NewsFootNotesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type NewsLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type NewsReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type NewsTeaserArgs = {
  showEllipsis?: InputMaybe<Scalars['Boolean']['input']>;
  truncate?: InputMaybe<Scalars['Int']['input']>;
};

export type NewsContentPart = ContentPart & {
  __typename?: 'NewsContentPart';
  /** anchor id to get part of the content */
  anchor: Scalars['String']['output'];
  /** all anchor ids in the content part */
  anchors: Array<Scalars['String']['output']>;
  /** depth level of the part of the content */
  depth: Scalars['Int']['output'];
  /** footNotes in one content-part */
  footNotes: Array<FootNote>;
  /** html of the content part with anchor id */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with anchor id */
  htmlBlob: Scalars['String']['output'];
  /** the id of the content part */
  id: Scalars['String']['output'];
  /** true if htmlBlob content is empty */
  isContentEmpty: Scalars['Boolean']['output'];
  /** title for the part of the content */
  title: Scalars['String']['output'];
};

export type NewsFeedItem = {
  __typename?: 'NewsFeedItem';
  /** concise citation of news */
  conciseCitation?: Maybe<Scalars['String']['output']>;
  /** identifier */
  identifier: Scalars['String']['output'];
  /** publication date */
  publicationDate?: Maybe<Scalars['String']['output']>;
  /** identifier of news publisher */
  publicationIdentifier: Scalars['String']['output'];
  /** title of news publisher */
  publicationTitle: Scalars['String']['output'];
  /** news section */
  section?: Maybe<Scalars['String']['output']>;
  /** news teaser */
  teaser: Scalars['String']['output'];
  /** title of news */
  title: Scalars['String']['output'];
};

export type NewsFeedItemTeaserArgs = {
  showEllipsis?: InputMaybe<Scalars['Boolean']['input']>;
  truncate?: InputMaybe<Scalars['Int']['input']>;
};

export type NewsPublisher = {
  __typename?: 'NewsPublisher';
  /** id of publisher */
  identifier: Scalars['String']['output'];
  /** date of last news published */
  lastUpdated: Scalars['String']['output'];
  /** available sections from news publisher */
  sections: Array<Scalars['String']['output']>;
  /** title of publisher */
  title: Scalars['String']['output'];
};

export type Op = Content & {
  __typename?: 'OP';
  /** Case number of the official publication */
  caseNumber?: Maybe<Scalars['String']['output']>;
  /** parts of the content */
  contentParts: Array<ContentPart>;
  /** URL to the footnotes */
  footNotes?: Maybe<Scalars['String']['output']>;
  /** does the content type has toc */
  hasToc: Scalars['Boolean']['output'];
  /** id for fetching the content */
  id: Scalars['String']['output'];
  /** link(s) to content */
  link?: Maybe<ContentLink>;
  /** Meeting year of the official publication */
  meetingYear?: Maybe<Scalars['String']['output']>;
  /** SWS metadata for the document */
  metadata: SwsCompactMetadata;
  /** link to the original source of the document */
  originalSourceUrl: Scalars['String']['output'];
  /** Pdf attachment link of the official publication */
  pdfAttachment?: Maybe<Scalars['String']['output']>;
  /** Subtitle of the official publication */
  subtitle: Scalars['String']['output'];
  /** title for the content */
  title: Scalars['String']['output'];
};

export type OpContentPartsArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type OpFootNotesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type OpLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type OpContentPart = ContentPart & {
  __typename?: 'OPContentPart';
  /** anchor id to get part of the content */
  anchor: Scalars['String']['output'];
  /** all anchor ids in the content part */
  anchors: Array<Scalars['String']['output']>;
  /** depth level of the part of the content */
  depth: Scalars['Int']['output'];
  /** footNotes in one content-part */
  footNotes: Array<FootNote>;
  /** html of the content part with anchor id */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with anchor id */
  htmlBlob: Scalars['String']['output'];
  /** the id of the content part */
  id: Scalars['String']['output'];
  /** true if htmlBlob content is empty */
  isContentEmpty: Scalars['Boolean']['output'];
  /** title for the part of the content */
  title: Scalars['String']['output'];
};

export type OpReference = {
  __typename?: 'OpReference';
  /** The canonical location to retrieve the (containing) document */
  canonicalLink?: Maybe<Scalars['String']['output']>;
  /** official publication id */
  id?: Maybe<Scalars['String']['output']>;
  /** link status for reference */
  linkStatus?: Maybe<Scalars['String']['output']>;
  /** number in the dossier */
  number?: Maybe<Scalars['String']['output']>;
  /** The canonical location to retrieve the pdf document */
  pdfAttachment?: Maybe<Scalars['String']['output']>;
  /** publication date */
  publicationDate?: Maybe<Scalars['String']['output']>;
  /** dossier title */
  title?: Maybe<Scalars['String']['output']>;
};

export type OpschriftLawTocEntry = LawTocEntry & {
  __typename?: 'OpschriftLawTocEntry';
  /** The location anchor */
  anchor: Scalars['String']['output'];
  /** Lower-level table of contents */
  entries: Array<LawTocEntry>;
  /** does it have sublevels */
  hasChildren: Scalars['Boolean']['output'];
  /** id of the tocEntry */
  id: Scalars['String']['output'];
  /** Canonical link to this law */
  lawCanonicalLink: Scalars['String']['output'];
  /** The name of the section */
  name: Scalars['String']['output'];
  /** id of the parent node */
  parentId?: Maybe<Scalars['String']['output']>;
};

export type Overview = Content & {
  __typename?: 'Overview';
  /** parts of the content */
  contentParts: Array<ContentPart>;
  /** URL to the footnotes */
  footNotes?: Maybe<Scalars['String']['output']>;
  /** does the content type has toc */
  hasToc: Scalars['Boolean']['output'];
  /** id for fetching the content */
  id: Scalars['String']['output'];
  /** Is magazine article */
  isMagazineArticle: Scalars['Boolean']['output'];
  /** link(s) to content */
  link?: Maybe<ContentLink>;
  /** SWS metadata for the document */
  metadata: SwsCompactMetadata;
  /** link to the original source of the document */
  originalSourceUrl: Scalars['String']['output'];
  /** authors of overview */
  overviewAuthors: Array<Author>;
  /** title for the content */
  title: Scalars['String']['output'];
};

export type OverviewContentPartsArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type OverviewFootNotesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type OverviewLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type OverviewContentPart = ContentPart & {
  __typename?: 'OverviewContentPart';
  /** anchor id to get part of the content */
  anchor: Scalars['String']['output'];
  /** all anchor ids in the content part */
  anchors: Array<Scalars['String']['output']>;
  /** depth level of the part of the content */
  depth: Scalars['Int']['output'];
  /** footNotes in one content-part */
  footNotes: Array<FootNote>;
  /** html of the content part with anchor id */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with anchor id */
  htmlBlob: Scalars['String']['output'];
  /** the id of the content part */
  id: Scalars['String']['output'];
  /** true if htmlBlob content is empty */
  isContentEmpty: Scalars['Boolean']['output'];
  /** title for the part of the content */
  title: Scalars['String']['output'];
};

export type PnGerelateerdeOnderwerpen = Tab & {
  __typename?: 'PNGerelateerdeOnderwerpen';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** list of related topic references */
  references: Array<PnRelatedTopicReference>;
};

export type PnGerelateerdeOnderwerpenReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PnJurisprudentie = Tab & {
  __typename?: 'PNJurisprudentie';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** list of case law references */
  references: Array<PnCaseLawReference>;
};

export type PnJurisprudentieReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PnLiteratuur = Tab & {
  __typename?: 'PNLiteratuur';
  /** html of the tab displayed in non table format */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with tab */
  htmlBlob?: Maybe<Scalars['String']['output']>;
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
};

export type PnLiteratuurHtmlArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PnLiteratuurHtmlBlobArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PnNavigationDrawerInfo = NavigationDrawerInfo & {
  __typename?: 'PNNavigationDrawerInfo';
  /** Navigation drawer identifier */
  id: Scalars['String']['output'];
  /** subject - first level of navigation drawer */
  subjectId: Scalars['String']['output'];
  /** subject title */
  subjectName: Scalars['String']['output'];
  /** Navigation drawer title */
  title: Scalars['String']['output'];
  /** identifier of the toc entry that links to documentKey  */
  tocId: Scalars['String']['output'];
};

export type PnNews = Tab & {
  __typename?: 'PNNews';
  /** html of the tab displayed in non table format */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with tab */
  htmlBlob?: Maybe<Scalars['String']['output']>;
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** tab is automatically generated or not */
  isGenerated: Scalars['Boolean']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** list of related news references */
  references: Array<PnNewsReference>;
};

export type PnNewsHtmlArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PnNewsHtmlBlobArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PnNewsReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PnTools = Tab & {
  __typename?: 'PNTools';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** list of tools references */
  references: Array<PnToolsReference>;
};

export type PnToolsReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PnVerdieping = Tab & {
  __typename?: 'PNVerdieping';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** list of verdieping references */
  references: Array<PnVerdiepingReference>;
};

export type PnVerdiepingReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PnVerdiepingReference = {
  __typename?: 'PNVerdiepingReference';
  /** Creators */
  creators: Array<Scalars['String']['output']>;
  /** Document date */
  documentDate: Scalars['String']['output'];
  /** verdieping reference */
  reference?: Maybe<ReferenceItem>;
};

export type Parlementair = Tab & {
  __typename?: 'Parlementair';
  /** The list of parliamentary history dossiers */
  dossiers: Array<ParliamentaryHistoryReference>;
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
};

export type ParliamentaryHistoryReference = {
  __typename?: 'ParliamentaryHistoryReference';
  /** dossier number */
  id?: Maybe<Scalars['String']['output']>;
  /** List of official publications within dossier */
  references: Array<OpReference>;
  /** dossier title */
  title: Scalars['String']['output'];
};

export type ParliamentaryHistoryReferenceReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PartItem = {
  __typename?: 'PartItem';
  /** Legislation article number */
  article?: Maybe<Scalars['String']['output']>;
};

export type PracticeNote = Content & {
  __typename?: 'PracticeNote';
  /** list of authors */
  authors: Array<Author>;
  /** parts of the content */
  contentParts: Array<ContentPart>;
  /** URL to the footnotes */
  footNotes?: Maybe<Scalars['String']['output']>;
  /** does the content type has toc */
  hasToc: Scalars['Boolean']['output'];
  /** id for fetching the content */
  id: Scalars['String']['output'];
  /** is practice note link lijst */
  isLinkList: Scalars['Boolean']['output'];
  /** link(s) to content */
  link?: Maybe<ContentLink>;
  /** SWS metadata for the document */
  metadata: SwsCompactMetadata;
  /** link to the original source of the document */
  originalSourceUrl: Scalars['String']['output'];
  /** Navigation drawer info */
  pnNavigationDrawerInfo?: Maybe<PnNavigationDrawerInfo>;
  /** subtitle for the content */
  subtitle: Scalars['String']['output'];
  /** the tabs */
  tabs: Array<Tab>;
  /** title for the content */
  title: Scalars['String']['output'];
  /** The table of contents of this document */
  toc?: Maybe<PracticeNoteTocEntry>;
};

export type PracticeNoteContentPartsArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type PracticeNoteFootNotesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PracticeNoteLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PracticeNotePnNavigationDrawerInfoArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type PracticeNoteContentPart = ContentPart & {
  __typename?: 'PracticeNoteContentPart';
  /** anchor id to get part of the content */
  anchor: Scalars['String']['output'];
  /** all anchor ids in the content part */
  anchors: Array<Scalars['String']['output']>;
  /** depth level of the part of the content */
  depth: Scalars['Int']['output'];
  /** footNotes in one content-part */
  footNotes: Array<FootNote>;
  /** html of the content part with anchor id */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with anchor id */
  htmlBlob: Scalars['String']['output'];
  /** the id of the content part */
  id: Scalars['String']['output'];
  /** true if htmlBlob content is empty */
  isContentEmpty: Scalars['Boolean']['output'];
  /** title for the part of the content */
  title: Scalars['String']['output'];
};

export type PracticeNoteTocEntry = {
  __typename?: 'PracticeNoteTocEntry';
  /** The location anchor */
  anchor: Scalars['String']['output'];
  /** The canonical location to retrieve the (containing) document */
  canonicalLink: Scalars['String']['output'];
  /** The sub-sections belonging to this section */
  entries: Array<PracticeNoteTocEntry>;
  /** does it have sublevels */
  hasChildren: Scalars['Boolean']['output'];
  /** id of the tocEntry */
  id: Scalars['String']['output'];
  /** The location to retrieve the section from */
  link: Scalars['String']['output'];
  /** The link ID */
  linkID: Scalars['String']['output'];
  /** The name of the section */
  name: Scalars['String']['output'];
  /** id of the parent node */
  parentId?: Maybe<Scalars['String']['output']>;
};

export type PrevNextLinks = {
  __typename?: 'PrevNextLinks';
  /** link to next  */
  next?: Maybe<ContentForAboutId>;
  /** link to previous */
  previous?: Maybe<ContentForAboutId>;
};

export type PropertyItem = {
  __typename?: 'PropertyItem';
  key?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** provision for structured data */
  property: Array<PropertyItem>;
  value?: Maybe<Scalars['String']['output']>;
};

export type PublicationNameModel = {
  __typename?: 'PublicationNameModel';
  identifier: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type PublicationsInOp = {
  __typename?: 'PublicationsInOp';
  /** link to op's */
  opLink?: Maybe<ContentForAboutId>;
  /** text of op's publication */
  text: Scalars['String']['output'];
};

export type PublicationsInOpOpLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PublishedItem = {
  __typename?: 'PublishedItem';
  /** abstract/summary (http://dublincore.org/documents/dcmi-terms/) */
  abstract?: Maybe<Scalars['String']['output']>;
  /** the unit of aggregation for a content collection (http://www.prismstandard.org/specifications/3.0/PRISM_Basic_Metadata_3.0.pdf) */
  aggregationType?: Maybe<Scalars['String']['output']>;
  /** concise citation */
  conciseCitation?: Maybe<Scalars['String']['output']>;
  isbn?: Maybe<Scalars['String']['output']>;
  issn?: Maybe<Scalars['String']['output']>;
  /** issue identifier (http://www.prismstandard.org/specifications/3.0/PRISM_Basic_Metadata_3.0.pdf) */
  issueName?: Maybe<Scalars['String']['output']>;
  /** issue number within a volume (http://www.prismstandard.org/specifications/3.0/PRISM_Basic_Metadata_3.0.pdf) */
  number?: Maybe<Scalars['String']['output']>;
  /** identifies the page range for the published print version of the resource (http://www.prismstandard.org/specifications/3.0/PRISM_Basic_Metadata_3.0.pdf) */
  pageRange?: Maybe<Scalars['String']['output']>;
  /** publication date (http://www.prismstandard.org/specifications/3.0/PRISM_Basic_Metadata_3.0.pdf) */
  publicationDate?: Maybe<Scalars['String']['output']>;
  publicationName?: Maybe<PublicationNameModel>;
  /** publication number */
  publicationNumber?: Maybe<Scalars['String']['output']>;
  /** publication year */
  publicationYear?: Maybe<Scalars['String']['output']>;
  /** name of the publication section in which the resource is categorized  (http://www.prismstandard.org/specifications/3.0/PRISM_Basic_Metadata_3.0.pdf) */
  section?: Maybe<Scalars['String']['output']>;
  /** name of the subsection of the publication in which the resource appears (http://www.prismstandard.org/specifications/3.0/PRISM_Basic_Metadata_3.0.pdf) */
  subsection1?: Maybe<Scalars['String']['output']>;
  /** a short description of the article designed to increase reader interest (http://www.prismstandard.org/specifications/3.0/PRISM_Basic_Metadata_3.0.pdf), to be used for metadescription (SEO) */
  teaser?: Maybe<Scalars['String']['output']>;
  /** volume portion of the common Volume, Number scheme (http://www.prismstandard.org/specifications/3.0/PRISM_Basic_Metadata_3.0.pdf) */
  volume?: Maybe<Scalars['String']['output']>;
};

export type PublisherModel = {
  __typename?: 'PublisherModel';
  identifier: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

/** Central Search queries */
export type Query = {
  __typename?: 'Query';
  /** fetch all articles */
  allArticles: AllArticles;
  /** fetch all news */
  allNews: Array<NewsFeedItem>;
  /** Fetch goods code with available tabs */
  code?: Maybe<GoodsCode>;
  /** Fetch content for one specific document */
  content?: Maybe<Content>;
  /** Downloads avialable in subscription */
  downloads?: Maybe<Downloads>;
  /** Fetch explanation(toelichting) content by customs code */
  explanations?: Maybe<Explanations>;
  /** FullLawHistory */
  fullLawHistory: Array<LawArticleVersion>;
  inPageSearch?: Maybe<Array<ContentPartHit>>;
  /** fetch latest editions */
  latestEditions: LatestEditions;
  /** Find commentaries for certain law document */
  lawCommentaries?: Maybe<LawTocEntry>;
  /** LawSwitchDrawer */
  lawSwitchDrawer?: Maybe<BaseTocEntry>;
  /** Find magazines */
  magazines: Array<GenMagazine>;
  /** Retrieve bulk metadata results */
  metadata: MetadataResults;
  /** Get updates about new docs by sections for a given month */
  monthlyUpdates?: Maybe<MonthlyUpdate>;
  /** List of monthly updates from last 12 months */
  monthlyUpdatesFeed?: Maybe<MonthlyUpdatesFeed>;
  /** Navigation drawer */
  navigationDrawer?: Maybe<BaseTocEntry>;
  /** Pick up news document for homepage */
  news?: Maybe<Array<News>>;
  /** Find news publishers */
  newsPublishers: Array<NewsPublisher>;
  /** Pick up document ids to create sitemap */
  sitemapIds?: Maybe<Array<SiteMapResults>>;
  /** Fetch available editions from year */
  sourceOverviewTocEditions?: Maybe<SourceEditionsByYear>;
  /** Pick up years with edition for source overview page */
  sourceOverviewTocYears?: Maybe<SourceYears>;
};

/** Central Search queries */
export type QueryAllArticlesArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Central Search queries */
export type QueryAllNewsArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sections?: InputMaybe<Array<Scalars['String']['input']>>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Central Search queries */
export type QueryCodeArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
};

/** Central Search queries */
export type QueryContentArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  documentKey: Scalars['String']['input'];
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  parts?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Central Search queries */
export type QueryDownloadsArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
};

/** Central Search queries */
export type QueryExplanationsArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  explanationType: ExplanationType;
  getEditorial?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Central Search queries */
export type QueryFullLawHistoryArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  documentKey: Scalars['String']['input'];
};

/** Central Search queries */
export type QueryInPageSearchArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  documentKey: Scalars['String']['input'];
  query: QueryParameters;
};

/** Central Search queries */
export type QueryLatestEditionsArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  days?: InputMaybe<Scalars['Int']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Central Search queries */
export type QueryLawCommentariesArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  documentKey: Scalars['String']['input'];
  key?: InputMaybe<Array<Scalars['String']['input']>>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  publicationIdentifiers?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Central Search queries */
export type QueryLawSwitchDrawerArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  publicationIdentifiers?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Central Search queries */
export type QueryMagazinesArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Central Search queries */
export type QueryMetadataArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  ids: Array<Scalars['String']['input']>;
};

/** Central Search queries */
export type QueryMonthlyUpdatesArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Scalars['Int']['input']>;
  publicationIdentifiers?: InputMaybe<Array<Scalars['String']['input']>>;
  sections?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** Central Search queries */
export type QueryMonthlyUpdatesFeedArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  publicationIdentifiers?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Central Search queries */
export type QueryNavigationDrawerArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  docId?: InputMaybe<Scalars['String']['input']>;
  documentKey: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Central Search queries */
export type QueryNewsArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Central Search queries */
export type QueryNewsPublishersArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Central Search queries */
export type QuerySitemapIdsArgs = {
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  documentKey: Scalars['String']['input'];
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Central Search queries */
export type QuerySourceOverviewTocEditionsArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** Central Search queries */
export type QuerySourceOverviewTocYearsArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryParameters = {
  /** Force the following previously unmatched clauses to match all hits. */
  includeUnmatchedClauses?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Text to search for. If value is 'null', all results will be returned. */
  text?: InputMaybe<Scalars['String']['input']>;
};

export type ReferenceItem = {
  __typename?: 'ReferenceItem';
  /** reference link */
  linkItem: ContentForAboutId;
  /** reference title */
  title: Scalars['String']['output'];
};

export type ReferencesBySection = {
  __typename?: 'ReferencesBySection';
  /** identifier */
  identifier: Scalars['String']['output'];
  /** number of updates from given month for this section */
  numberOfUpdates: Scalars['Int']['output'];
  /** section */
  section: Scalars['String']['output'];
  /** updates */
  updates: Array<SimpleReference>;
};

export type ReferencesItem = {
  __typename?: 'ReferencesItem';
  /** Alternate identifier(s) (URI) */
  altIdentifier: Array<Scalars['String']['output']>;
  /** Alternate key(s) */
  altKey: Array<Scalars['String']['output']>;
  displayTitle?: Maybe<Scalars['String']['output']>;
  documentDate?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  /** link to the content of identifier */
  identifierLink?: Maybe<ContentForAboutId>;
  part: Array<PartItem>;
  title?: Maybe<Scalars['String']['output']>;
  type: Array<Scalars['String']['output']>;
};

export type ReferencesItemIdentifierLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type RelationItem = {
  __typename?: 'RelationItem';
  /** Alternate key(s) */
  altKey: Array<Scalars['String']['output']>;
  displayTitle?: Maybe<Scalars['String']['output']>;
  documentDate?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  part: Array<PartItem>;
  title?: Maybe<Scalars['String']['output']>;
  type: Array<Scalars['String']['output']>;
};

export type RelationItemDisplayTitleArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  resolveLinks?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ResourceItem = {
  __typename?: 'ResourceItem';
  format: Scalars['String']['output'];
  identifier: Scalars['String']['output'];
  language?: Maybe<Scalars['String']['output']>;
};

export type Ruling = Content & {
  __typename?: 'Ruling';
  /** parts of the content */
  contentParts: Array<ContentPart>;
  /** URL to the footnotes */
  footNotes?: Maybe<Scalars['String']['output']>;
  /** does the content type has toc */
  hasToc: Scalars['Boolean']['output'];
  /** id for fetching the content */
  id: Scalars['String']['output'];
  /** is document a pdf-only ruling */
  isRulingPdf: Scalars['Boolean']['output'];
  /** kenmerk */
  kenmerk: Scalars['String']['output'];
  /** link(s) to content */
  link?: Maybe<ContentLink>;
  /** SWS metadata for the document */
  metadata: SwsCompactMetadata;
  /** link to the original source of the document */
  originalSourceUrl: Scalars['String']['output'];
  /** pdf attachment */
  pdfAttachment?: Maybe<Scalars['String']['output']>;
  /** publication date */
  publicationDate?: Maybe<Scalars['String']['output']>;
  /** publication title */
  publicationTitle?: Maybe<Scalars['String']['output']>;
  /** status of ruling */
  status: Scalars['String']['output'];
  /** title for the content */
  title: Scalars['String']['output'];
};

export type RulingContentPartsArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type RulingFootNotesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type RulingLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type RulingContentPart = ContentPart & {
  __typename?: 'RulingContentPart';
  /** anchor id to get part of the content */
  anchor: Scalars['String']['output'];
  /** all anchor ids in the content part */
  anchors: Array<Scalars['String']['output']>;
  /** depth level of the part of the content */
  depth: Scalars['Int']['output'];
  /** footNotes in one content-part */
  footNotes: Array<FootNote>;
  /** html of the content part with anchor id */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with anchor id */
  htmlBlob: Scalars['String']['output'];
  /** the id of the content part */
  id: Scalars['String']['output'];
  /** true if htmlBlob content is empty */
  isContentEmpty: Scalars['Boolean']['output'];
  /** title for the part of the content */
  title: Scalars['String']['output'];
};

export type RulingReference = {
  __typename?: 'RulingReference';
  /** Link to ruling */
  canonicalLink: Scalars['String']['output'];
  /** ruling identifier */
  identifier: Scalars['String']['output'];
  /** The kenmerk of the content being linked to */
  kenmerk: Scalars['String']['output'];
  /** link status for reference */
  linkStatus: Scalars['String']['output'];
  /** The date from which the content being linked to is valid */
  publicationDate?: Maybe<Scalars['String']['output']>;
  /** Whether the content being linked to is still valid */
  status: Scalars['String']['output'];
  /** The title of the content being linkted to */
  title: Scalars['String']['output'];
};

export type SimpleReference = {
  __typename?: 'SimpleReference';
  /** documentDate */
  documentDate: Scalars['String']['output'];
  /** identifier */
  identifier: Scalars['String']['output'];
  /** identifierLinkItem */
  identifierLinkItem: ContentForAboutId;
  /** section */
  section: Scalars['String']['output'];
  /** title */
  title: Scalars['String']['output'];
  /** types */
  types: Scalars['String']['output'];
};

export type SiteMapResults = {
  __typename?: 'SiteMapResults';
  /** document identifier */
  id: Scalars['String']['output'];
  /** date when the document was last processed */
  lastUpdated: Scalars['String']['output'];
  /** document type */
  swsType: Array<Scalars['String']['output']>;
};

export type SourceEditionsByYear = {
  __typename?: 'SourceEditionsByYear';
  /** edition from year */
  editions?: Maybe<Array<EditionWithNumber>>;
  /** source identifier */
  source?: Maybe<Scalars['String']['output']>;
  /** edition year */
  year?: Maybe<Scalars['String']['output']>;
};

export type SourceYears = {
  __typename?: 'SourceYears';
  /** source identifier */
  source?: Maybe<Scalars['String']['output']>;
  /** years from source */
  years?: Maybe<Array<YearFromSource>>;
};

export type SubChapter = {
  __typename?: 'SubChapter';
  /** code of subchapter */
  code: Scalars['String']['output'];
  /** title of subchapter */
  title: Scalars['String']['output'];
};

export type SubLevelTocEntry = {
  /** The location anchor */
  anchor: Scalars['String']['output'];
  /** id of the tocEntry */
  id: Scalars['String']['output'];
  /** The location to retrieve the section from */
  link: Scalars['String']['output'];
  /** Subtitle of section */
  subtitle: Scalars['String']['output'];
  /** Title of section */
  title: Scalars['String']['output'];
};

export type SubjectItem = {
  __typename?: 'SubjectItem';
  identifier: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type SwsCompactMetadata = {
  __typename?: 'SwsCompactMetadata';
  /** container for metadata about primary source */
  about: Array<AboutItem>;
  /** abstract/summary (http://dublincore.org/documents/dcmi-terms/). XHTML format */
  abstract?: Maybe<Scalars['String']['output']>;
  /** changes to the document */
  alertChange: Array<Scalars['String']['output']>;
  /** Alternate identifier(s) (URI) */
  altIdentifier: Array<Scalars['String']['output']>;
  /** Alternate key(s) */
  altKey: Array<Scalars['String']['output']>;
  /** alternative title(s) used for abbreviations (laws), popular names (case law), etc. */
  alternative: Array<AlternativeItem>;
  /** target audience(s) (controlled vocabulary, see http://confluence.sdu.nl/confluence/display/BETTY/Lists+of+values+%28URIs%29+for+controlled+vocabularies) */
  audience: Array<Scalars['String']['output']>;
  /** contributor(s) other than author/creator */
  contributor: Array<ContributorItem>;
  /** the extent or scope of the content of the resource (http://dublincore.org/documents/dcmi-terms/) */
  coverage?: Maybe<Scalars['String']['output']>;
  /** date created (http://dublincore.org/documents/dcmi-terms/) */
  created?: Maybe<Scalars['String']['output']>;
  /** author(s)/creator(s) (http://dublincore.org/documents/dcmi-terms/) */
  creator: Array<CreatorItem>;
  /** catch-all provision for dates */
  date: Array<DateItem>;
  /** title used for displaying in search results (including publication details) */
  displayTitle?: Maybe<Scalars['String']['output']>;
  /** document date (to be used for sorting), relevant date to be determined for each information type */
  documentDate?: Maybe<Scalars['String']['output']>;
  /** ECLI value */
  ecli: Array<Scalars['String']['output']>;
  /** file format (only to be specified if not standard document type) */
  format?: Maybe<Scalars['String']['output']>;
  /** express formal relations between documents */
  hasFormat: Array<HasFormatItem>;
  /** SWS document key */
  identifier: Scalars['String']['output'];
  /** instantie value */
  instantie?: Maybe<Scalars['String']['output']>;
  /** English translations of Scheepvaart regulation. Should contain the (versionless) BWBID of the original regulation */
  isExpressionOf?: Maybe<Scalars['String']['output']>;
  /** identification of logical collection(s) (controlled vocabulary) */
  isPartOf: Array<Scalars['String']['output']>;
  /** a related resource of which the described resource is a version, edition, or adaptation (http://dublincore.org/documents/dcmi-terms/) (only used for legislation) */
  isVersionOf?: Maybe<Scalars['String']['output']>;
  /** keyword(s) */
  keyword: Array<Scalars['String']['output']>;
  /** document language */
  language?: Maybe<Scalars['String']['output']>;
  /** legal area(s) (values based on the list of codes specified for rechtsgebiedenindeling) */
  legalArea: Array<LegalAreaItem>;
  /** date modified (http://dublincore.org/documents/dcmi-terms/) */
  modified?: Maybe<Scalars['String']['output']>;
  /** processing type to be used by Enrichment to determine the processing path */
  processingType?: Maybe<Scalars['String']['output']>;
  /** catch-all provision for classifications */
  property: Array<PropertyItem>;
  /** container for publication metadata */
  published: Array<PublishedItem>;
  publisher?: Maybe<PublisherModel>;
  /** link element used for references to primary sources */
  references: Array<ReferencesItem>;
  /** catch-all provision for links */
  relation: Array<RelationItem>;
  resource: Array<ResourceItem>;
  /** access rights (http://dublincore.org/documents/dcmi-terms/) (controlled vocabulary, see http://confluence.sdu.nl/confluence/display/BETTY/Lists+of+values+%28URIs%29+for+controlled+vocabularies) */
  rights?: Maybe<Scalars['String']['output']>;
  /** source (controlled vocabulary, see http://confluence.sdu.nl/confluence/display/BETTY/Lists+of+values+%28URIs%29+for+controlled+vocabularies) */
  source: Array<Scalars['String']['output']>;
  /** the topic of the resource (http://dublincore.org/documents/dcmi-terms/#terms-subject) */
  subject: Array<SubjectItem>;
  /** subtitle */
  subtitle?: Maybe<Scalars['String']['output']>;
  /** a short description of the article designed to increase reader interest (http://www.prismstandard.org/specifications/3.0/PRISM_Basic_Metadata_3.0.pdf), to be used for metadescription (SEO) */
  teaser?: Maybe<Scalars['String']['output']>;
  /** original title (not enhanced with publication details) */
  title: Scalars['String']['output'];
  /** information type(s) (controlled vocabulary, see http://confluence.sdu.nl/confluence/display/COMIGS/informationTypes+%28nieuwe+waardenlijst%2C+Engelstalige+waarden%29) */
  type: Array<Scalars['String']['output']>;
  valid?: Maybe<ValidModel>;
};

/** A tab (top-level part) of a document */
export type Tab = {
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
};

export type ThematicDrawer = {
  __typename?: 'ThematicDrawer';
  /** Document id of the drawer */
  id: Scalars['String']['output'];
  /** Publication identifier associated with thematic drawer */
  publicationIdentifier: Scalars['String']['output'];
};

export type TocEntry = {
  __typename?: 'TocEntry';
  /** The location anchor */
  anchor: Scalars['String']['output'];
  /** The canonical location to retrieve the (containing) document */
  canonicalLink: Scalars['String']['output'];
  /** The sub-sections belonging to this section */
  entries: Array<TocEntry>;
  /** does it have sublevels */
  hasChildren: Scalars['Boolean']['output'];
  /** id of the tocEntry */
  id: Scalars['String']['output'];
  /** The location to retrieve the section from */
  link: Scalars['String']['output'];
  /** The link ID */
  linkID: Scalars['String']['output'];
  /** The name of the section */
  name: Scalars['String']['output'];
  /** id of the parent node */
  parentId?: Maybe<Scalars['String']['output']>;
};

export type Tool = Content & {
  __typename?: 'Tool';
  /** abstract */
  abstract?: Maybe<Scalars['String']['output']>;
  /** Attachments such as spreadsheets */
  attachments: Array<Attachment>;
  /** list of authors */
  authors: Array<Author>;
  /** parts of the content */
  contentParts: Array<ContentPart>;
  /** URL to the footnotes */
  footNotes?: Maybe<Scalars['String']['output']>;
  /** does the content type has toc */
  hasToc: Scalars['Boolean']['output'];
  /** id for fetching the content */
  id: Scalars['String']['output'];
  /** link(s) to content */
  link?: Maybe<ContentLink>;
  /** SWS metadata for the document */
  metadata: SwsCompactMetadata;
  /** link to the original source of the document */
  originalSourceUrl: Scalars['String']['output'];
  /** References to externally hosted tools */
  references: Array<ToolReference>;
  /** the tabs */
  tabs: Array<Tab>;
  /** title for the content */
  title: Scalars['String']['output'];
  /** Navigation drawer info */
  toolsNavigationDrawerInfo?: Maybe<ToolsNavigationDrawerInfo>;
};

export type ToolContentPartsArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type ToolFootNotesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ToolLinkArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ToolToolsNavigationDrawerInfoArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ToolContentPart = ContentPart & {
  __typename?: 'ToolContentPart';
  /** anchor id to get part of the content */
  anchor: Scalars['String']['output'];
  /** all anchor ids in the content part */
  anchors: Array<Scalars['String']['output']>;
  /** depth level of the part of the content */
  depth: Scalars['Int']['output'];
  /** footNotes in one content-part */
  footNotes: Array<FootNote>;
  /** html of the content part with anchor id */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with anchor id */
  htmlBlob: Scalars['String']['output'];
  /** the id of the content part */
  id: Scalars['String']['output'];
  /** true if htmlBlob content is empty */
  isContentEmpty: Scalars['Boolean']['output'];
  /** title for the part of the content */
  title: Scalars['String']['output'];
};

export type ToolReference = {
  __typename?: 'ToolReference';
  /** Tool url on the platform */
  href: Scalars['String']['output'];
  /** Tool title */
  title: Scalars['String']['output'];
  /** Tool identifier on the platform */
  toolID: Scalars['String']['output'];
  /** Tool type/platform */
  toolType: Scalars['String']['output'];
};

export type ToolReferenceHrefArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type Tools = Tab & {
  __typename?: 'Tools';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** list of tools (?) */
  tools: Array<Scalars['String']['output']>;
};

export type ToolsJurisprudentie = Tab & {
  __typename?: 'ToolsJurisprudentie';
  /** html of the tab */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with tab */
  htmlBlob?: Maybe<Scalars['String']['output']>;
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
};

export type ToolsJurisprudentieHtmlArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ToolsJurisprudentieHtmlBlobArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ToolsLaw = Tab & {
  __typename?: 'ToolsLaw';
  /** html of the tab */
  html: Scalars['String']['output'];
  /** link to fetch the part of the content with tab */
  htmlBlob?: Maybe<Scalars['String']['output']>;
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
};

export type ToolsLawHtmlArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ToolsLawHtmlBlobArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ToolsNavigationDrawerInfo = NavigationDrawerInfo & {
  __typename?: 'ToolsNavigationDrawerInfo';
  /** Navigation drawer identifier */
  id: Scalars['String']['output'];
  /** subject - first level of navigation drawer */
  subjectId: Scalars['String']['output'];
  /** subject title */
  subjectName: Scalars['String']['output'];
  /** Navigation drawer title */
  title: Scalars['String']['output'];
  /** identifier of the toc entry that links to documentKey  */
  tocId: Scalars['String']['output'];
};

export type ToolsNews = Tab & {
  __typename?: 'ToolsNews';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** list of related news references */
  references: Array<PnNewsReference>;
};

export type ToolsNewsReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ToolsRelatedTopics = Tab & {
  __typename?: 'ToolsRelatedTopics';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** list of related topic references */
  references: Array<PnRelatedTopicReference>;
};

export type ToolsRelatedTopicsReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type TopLevelLawTocEntry = LawTocEntry & {
  __typename?: 'TopLevelLawTocEntry';
  /** Lower-level table of contents */
  entries: Array<LawTocEntry>;
  /** does it have sublevels */
  hasChildren: Scalars['Boolean']['output'];
  /** The name of the section */
  name: Scalars['String']['output'];
  /** id of the parent node */
  parentId?: Maybe<Scalars['String']['output']>;
  /** selected publications */
  publications: Array<LawPublication>;
};

export type TopLevelTocEntry = BaseTocEntry & {
  __typename?: 'TopLevelTocEntry';
  /** max number of sublevels */
  depth: Scalars['Int']['output'];
  /** Lower-level table of contents */
  entries: Array<SubLevelTocEntry>;
  /** does it have sublevels */
  hasChildren: Scalars['Boolean']['output'];
  /** The name of the section */
  name: Scalars['String']['output'];
  /** id of the parent node */
  parentId?: Maybe<Scalars['String']['output']>;
};

export type Translation = {
  __typename?: 'Translation';
  /** is currently opened translation */
  isCurrent: Scalars['Boolean']['output'];
  /** language of document */
  language: Scalars['String']['output'];
  /** link to translation */
  linkItem: ContentForAboutId;
};

export type TranslationLinkItemArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ValidModel = {
  __typename?: 'ValidModel';
  end?: Maybe<Scalars['String']['output']>;
  endRetroactive?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
  startRetroactive?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ValidityStatus = {
  __typename?: 'ValidityStatus';
  /** End date */
  end?: Maybe<Scalars['String']['output']>;
  /** End retroactive date */
  endRetroactive?: Maybe<Scalars['String']['output']>;
  /** Start date */
  start?: Maybe<Scalars['String']['output']>;
  /** Start retroactive date */
  startRetroactive?: Maybe<Scalars['String']['output']>;
  /** Validity status */
  status?: Maybe<Scalars['String']['output']>;
};

export type Versies = Tab & {
  __typename?: 'Versies';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** The list of article versions */
  versions: Array<LawArticleVersion>;
};

export type Wenr = Tab & {
  __typename?: 'Wenr';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** List of law and external references */
  references: Array<LinkReference>;
  /** List of law references sections */
  sectionReferences: Array<LawReferenceSection>;
};

export type WenrReferencesArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type WetsArtikel = {
  __typename?: 'WetsArtikel';
  /** The HTML-anchor of the article in the law */
  articleAnchor?: Maybe<Scalars['String']['output']>;
  /** title and link to the article */
  articleData: Array<ArticleData>;
  /** The contents of the article */
  articleHtmlBlob?: Maybe<Scalars['String']['output']>;
  /** Is article valid or not */
  isExpired?: Maybe<Scalars['Boolean']['output']>;
  /** is commentary about the multiple article of the same law */
  isMultipleArticle: Scalars['Boolean']['output'];
  /** is commentary about the whole law */
  isWholeLaw: Scalars['Boolean']['output'];
  /** The identifier of the law  */
  lawIdentifier: Scalars['String']['output'];
  /** The title of the article */
  lawTitle: Scalars['String']['output'];
  /** The validity of the article */
  lawValidityEnd?: Maybe<Scalars['String']['output']>;
  /** The validity of the article */
  lawValidityStart?: Maybe<Scalars['String']['output']>;
};

export type WetsArtikelArticleDataArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type WetsArtikelArticleHtmlBlobArgs = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
};

export type WetsTab = Tab & {
  __typename?: 'WetsTab';
  /** Icon for the tab */
  icon: Scalars['String']['output'];
  /** String to display for the tab */
  label: Scalars['String']['output'];
  /** title and link to the article */
  laws: Array<Maybe<WetsArtikel>>;
};

export type YearFromSource = {
  __typename?: 'YearFromSource';
  /** has edition in year */
  hasEdition?: Maybe<Scalars['Boolean']['output']>;
  /** source publication year */
  year?: Maybe<Scalars['String']['output']>;
};

export type CaseLawReference = {
  __typename?: 'caseLawReference';
  /** The part of the text that is the actual link */
  anchorText: Scalars['String']['output'];
  /** Canonical link */
  canonicalLink?: Maybe<Scalars['String']['output']>;
  /** Case date */
  caseDate: Scalars['String']['output'];
  /** Case number */
  caseNumber: Scalars['String']['output'];
  /** Contributor */
  contributor: Scalars['String']['output'];
  /** Creator */
  creator: Scalars['String']['output'];
  /** Ecli ID */
  ecli?: Maybe<Scalars['String']['output']>;
  /** Ecli canonical link */
  ecliCanonicalLink?: Maybe<Scalars['String']['output']>;
  /** ecli link status for reference */
  ecliLinkStatus: Scalars['String']['output'];
  /** is caselaw(ecli) reference in cassation */
  isInCassation: Scalars['Boolean']['output'];
  /** Name of the publication linked to */
  linkPublicationName: Scalars['String']['output'];
  /** Issue number of the publication linked to */
  linkPublicationNumber: Scalars['String']['output'];
  /** Publication year of the issue linked to */
  linkPublicationYear: Scalars['String']['output'];
  /** link status for reference */
  linkStatus: Scalars['String']['output'];
  /** Document type of the linked to document */
  linkType: Scalars['String']['output'];
  /** conciseCitation of document */
  publication?: Maybe<Scalars['String']['output']>;
  /** The rest of the text of this reference, excluding the anchorText */
  referenceText: Scalars['String']['output'];
  /** Link ID */
  swsLinkID?: Maybe<Scalars['String']['output']>;
  /** Title */
  title: Scalars['String']['output'];
};

export type ContentForAboutId = {
  __typename?: 'contentForAboutId';
  /** link to the content for the identifier */
  link: Scalars['String']['output'];
  /** is document available or not */
  linkStatus: Scalars['String']['output'];
};

/** Possible values for the types for explanation */
export enum ExplanationType {
  /** Chapter (Hoofdstuk) code type */
  CHAPTER = 'chapter',
  /** Goods code type */
  GOODS = 'goods',
  /** Section (Afdeling) code type */
  SECTION = 'section',
}

export type PnCaseLawReference = {
  __typename?: 'pnCaseLawReference';
  /** Abstract */
  abstract: Scalars['String']['output'];
  /** Canonical link */
  canonicalLink?: Maybe<Scalars['String']['output']>;
  /** Case date */
  caseDate: Scalars['String']['output'];
  /** Instantie */
  creator: Scalars['String']['output'];
  /** Ecli references */
  ecliReferences: Array<ReferenceItem>;
  /** link status for reference */
  linkStatus: Scalars['String']['output'];
  /** Publication */
  publication: Scalars['String']['output'];
  /** Link ID */
  swsLinkID?: Maybe<Scalars['String']['output']>;
  /** Title */
  title: Scalars['String']['output'];
};

export type PnNewsReference = {
  __typename?: 'pnNewsReference';
  /** Document date */
  documentDate: Scalars['String']['output'];
  /** News type */
  newsType: Scalars['String']['output'];
  /** related topic reference */
  reference?: Maybe<ReferenceItem>;
  /** News title */
  title: Scalars['String']['output'];
};

export type PnRelatedTopicReference = {
  __typename?: 'pnRelatedTopicReference';
  /** Abstract */
  abstract: Scalars['String']['output'];
  /** Authors */
  creators: Array<Scalars['String']['output']>;
  /** Document date */
  documentDate: Scalars['String']['output'];
  /** related topic reference */
  reference?: Maybe<ReferenceItem>;
};

export type PnToolsReference = {
  __typename?: 'pnToolsReference';
  /** Creator */
  creator: Scalars['String']['output'];
  /** Editorial date */
  editorialDate: Scalars['String']['output'];
  /** tools reference */
  reference?: Maybe<ReferenceItem>;
};

type CommentaryLawTocEntryArticleLawTocEntryFragment = {
  __typename?: 'ArticleLawTocEntry';
  subtitle: string;
  canonicalLink: string;
  name: string;
  id: string;
  label: string;
  to: string;
};

type CommentaryLawTocEntryEntireLawTocEntryFragment = {
  __typename?: 'EntireLawTocEntry';
  subtitle: string;
  linkID: string;
  name: string;
  id: string;
  label: string;
  to: string;
};

type CommentaryLawTocEntryGenericLawTocEntryFragment = {
  __typename?: 'GenericLawTocEntry';
  subtitle: string;
  canonicalLink: string;
  name: string;
  id: string;
  label: string;
  suffix: string;
};

type CommentaryLawTocEntryOpschriftLawTocEntryFragment = {
  __typename?: 'OpschriftLawTocEntry';
  lawCanonicalLink: string;
  name: string;
  id: string;
  label: string;
};

type CommentaryLawTocEntryTopLevelLawTocEntryFragment = {
  __typename?: 'TopLevelLawTocEntry';
  name: string;
};

export type CommentaryLawTocEntryFragment =
  | CommentaryLawTocEntryArticleLawTocEntryFragment
  | CommentaryLawTocEntryEntireLawTocEntryFragment
  | CommentaryLawTocEntryGenericLawTocEntryFragment
  | CommentaryLawTocEntryOpschriftLawTocEntryFragment
  | CommentaryLawTocEntryTopLevelLawTocEntryFragment;

export type CommentaryLawTocQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  key?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  publicationIdentifiers?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type CommentaryLawTocQuery = {
  __typename?: 'Query';
  lawCommentaries?:
    | {
        __typename?: 'ArticleLawTocEntry';
        name: string;
        children: Array<
          | {
              __typename?: 'ArticleLawTocEntry';
              subtitle: string;
              canonicalLink: string;
              name: string;
              id: string;
              label: string;
              to: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'EntireLawTocEntry';
              subtitle: string;
              linkID: string;
              name: string;
              id: string;
              label: string;
              to: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'GenericLawTocEntry';
              subtitle: string;
              canonicalLink: string;
              name: string;
              id: string;
              label: string;
              suffix: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'OpschriftLawTocEntry';
              lawCanonicalLink: string;
              name: string;
              id: string;
              label: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'TopLevelLawTocEntry';
              name: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
        >;
      }
    | {
        __typename?: 'EntireLawTocEntry';
        name: string;
        children: Array<
          | {
              __typename?: 'ArticleLawTocEntry';
              subtitle: string;
              canonicalLink: string;
              name: string;
              id: string;
              label: string;
              to: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'EntireLawTocEntry';
              subtitle: string;
              linkID: string;
              name: string;
              id: string;
              label: string;
              to: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'GenericLawTocEntry';
              subtitle: string;
              canonicalLink: string;
              name: string;
              id: string;
              label: string;
              suffix: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'OpschriftLawTocEntry';
              lawCanonicalLink: string;
              name: string;
              id: string;
              label: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'TopLevelLawTocEntry';
              name: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
        >;
      }
    | {
        __typename?: 'GenericLawTocEntry';
        name: string;
        children: Array<
          | {
              __typename?: 'ArticleLawTocEntry';
              subtitle: string;
              canonicalLink: string;
              name: string;
              id: string;
              label: string;
              to: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'EntireLawTocEntry';
              subtitle: string;
              linkID: string;
              name: string;
              id: string;
              label: string;
              to: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'GenericLawTocEntry';
              subtitle: string;
              canonicalLink: string;
              name: string;
              id: string;
              label: string;
              suffix: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'OpschriftLawTocEntry';
              lawCanonicalLink: string;
              name: string;
              id: string;
              label: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'TopLevelLawTocEntry';
              name: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
        >;
      }
    | {
        __typename?: 'OpschriftLawTocEntry';
        name: string;
        children: Array<
          | {
              __typename?: 'ArticleLawTocEntry';
              subtitle: string;
              canonicalLink: string;
              name: string;
              id: string;
              label: string;
              to: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'EntireLawTocEntry';
              subtitle: string;
              linkID: string;
              name: string;
              id: string;
              label: string;
              to: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'GenericLawTocEntry';
              subtitle: string;
              canonicalLink: string;
              name: string;
              id: string;
              label: string;
              suffix: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'OpschriftLawTocEntry';
              lawCanonicalLink: string;
              name: string;
              id: string;
              label: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'TopLevelLawTocEntry';
              name: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
        >;
      }
    | {
        __typename?: 'TopLevelLawTocEntry';
        name: string;
        publications: Array<{ __typename?: 'LawPublication'; id: string; name: string }>;
        children: Array<
          | {
              __typename?: 'ArticleLawTocEntry';
              subtitle: string;
              canonicalLink: string;
              name: string;
              id: string;
              label: string;
              to: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'EntireLawTocEntry';
              subtitle: string;
              linkID: string;
              name: string;
              id: string;
              label: string;
              to: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'GenericLawTocEntry';
              subtitle: string;
              canonicalLink: string;
              name: string;
              id: string;
              label: string;
              suffix: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'OpschriftLawTocEntry';
              lawCanonicalLink: string;
              name: string;
              id: string;
              label: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
          | {
              __typename?: 'TopLevelLawTocEntry';
              name: string;
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'EntireLawTocEntry';
                    subtitle: string;
                    linkID: string;
                    name: string;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericLawTocEntry';
                    subtitle: string;
                    canonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                    suffix: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    lawCanonicalLink: string;
                    name: string;
                    id: string;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; name: string }
              >;
            }
        >;
      }
    | null;
};

type LawSwitcherItemDocumentTocEntryFragment = {
  __typename?: 'DocumentTocEntry';
  hasChildren: boolean;
  depth: number;
  parentId?: string | null;
  lawId: string;
  label: string;
};

type LawSwitcherItemGenericTocEntryFragment = {
  __typename?: 'GenericTocEntry';
  id: string;
  title: string;
  hasChildren: boolean;
  depth: number;
  parentId?: string | null;
  label: string;
};

export type LawSwitcherItemFragment =
  | LawSwitcherItemDocumentTocEntryFragment
  | LawSwitcherItemGenericTocEntryFragment;

export type LawSwitcherQueryVariables = Exact<{
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  publicationIdentifiers?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type LawSwitcherQuery = {
  __typename?: 'Query';
  lawSwitchDrawer?:
    | { __typename?: 'DocumentTocEntry' }
    | { __typename?: 'GenericTocEntry' }
    | {
        __typename?: 'TopLevelTocEntry';
        depth: number;
        hasChildren: boolean;
        label: string;
        children: Array<
          | { __typename?: 'DocumentTocEntry' }
          | {
              __typename?: 'GenericTocEntry';
              id: string;
              title: string;
              hasChildren: boolean;
              depth: number;
              parentId?: string | null;
              label: string;
              children: Array<
                | {
                    __typename?: 'DocumentTocEntry';
                    hasChildren: boolean;
                    depth: number;
                    parentId?: string | null;
                    lawId: string;
                    label: string;
                  }
                | { __typename?: 'GenericTocEntry' }
              >;
            }
        >;
      }
    | null;
};

export type DownloadsQueryVariables = Exact<{
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type DownloadsQuery = {
  __typename?: 'Query';
  downloads?: {
    __typename: 'Downloads';
    total: number;
    items: Array<{
      __typename: 'Download';
      id: string;
      name: string;
      excerpt: string;
      href: string;
      documentDate: string;
    }>;
  } | null;
};

export type EditionsQueryVariables = Exact<{
  days?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type EditionsQuery = {
  __typename?: 'Query';
  latestEditions: {
    __typename?: 'LatestEditions';
    editions: Array<{
      __typename?: 'MagazineEdition';
      magazineId: string;
      commonTitle: string;
      id: string;
      number: string;
      date?: string | null;
    }>;
  };
};

export type LatestNewsQueryVariables = Exact<{
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type LatestNewsQuery = {
  __typename?: 'Query';
  news?: Array<{
    __typename?: 'News';
    id: string;
    title: string;
    teaser: string;
    publicationDate?: string | null;
  }> | null;
};

export type LawHistoryQueryVariables = Exact<{
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  documentKey: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
}>;

export type LawHistoryQuery = {
  __typename?: 'Query';
  fullLawHistory: Array<{
    __typename?: 'LawArticleVersion';
    date: string;
    versionType: string;
    dossierNumber: string;
    dateRetroactive: string;
    textInPubEnactment: string;
    isTemporalTreatyVersion: boolean;
    bwbCanonicalLink?: {
      __typename?: 'contentForAboutId';
      link: string;
      linkStatus: string;
    } | null;
    publicationsEnactment: Array<{
      __typename?: 'PublicationsInOp';
      text: string;
      opLink?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
    }>;
    lines: Array<{
      __typename?: 'MultipleLawVersion';
      versionType: string;
      dateRetroactive: string;
      dossierNumber: string;
      textInPubEnactment: string;
      publicationsEnactment: Array<{
        __typename?: 'PublicationsInOp';
        text: string;
        opLink?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
      }>;
    }>;
  }>;
};

export type MagazineEditionYearsQueryVariables = Exact<{
  magazineId?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type MagazineEditionYearsQuery = {
  __typename?: 'Query';
  magazines: Array<{ __typename?: 'GenMagazine'; id: string; editionYears: Array<string> }>;
};

export type MagazineEditionsQueryVariables = Exact<{
  magazineId?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type MagazineEditionsQuery = {
  __typename?: 'Query';
  magazines: Array<{
    __typename?: 'GenMagazine';
    id: string;
    editionYears: Array<string>;
    editions: Array<{
      __typename?: 'MagazineEdition';
      title: string;
      shortTitle: string;
      pdfEditionId?: string | null;
      articleNumberRange: string;
      id: string;
      number: string;
      date?: string | null;
    }>;
  }>;
};

export type MagazinesQueryVariables = Exact<{
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type MagazinesQuery = {
  __typename?: 'Query';
  magazines: Array<{
    __typename?: 'GenMagazine';
    id: string;
    title: string;
    commonTitle: string;
    lastPublished?: string | null;
  }>;
};

type PnNavigationDrawerItemDocumentTocEntryFragment = {
  __typename?: 'DocumentTocEntry';
  hasChildren: boolean;
  depth: number;
  id: string;
  label: string;
  to: string;
};

type PnNavigationDrawerItemGenericTocEntryFragment = {
  __typename?: 'GenericTocEntry';
  hasChildren: boolean;
  depth: number;
  id: string;
  label: string;
  to: string;
};

export type PnNavigationDrawerItemFragment =
  | PnNavigationDrawerItemDocumentTocEntryFragment
  | PnNavigationDrawerItemGenericTocEntryFragment;

export type PnNavigationDrawerQueryVariables = Exact<{
  documentKey: Scalars['String']['input'];
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  subId?: InputMaybe<Scalars['String']['input']>;
  docId?: InputMaybe<Scalars['String']['input']>;
  fetchAll?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type PnNavigationDrawerQuery = {
  __typename?: 'Query';
  navigationDrawer?:
    | { __typename?: 'DocumentTocEntry'; depth: number; label: string }
    | {
        __typename?: 'GenericTocEntry';
        depth: number;
        label: string;
        children: Array<
          | {
              __typename?: 'DocumentTocEntry';
              hasChildren: boolean;
              depth: number;
              id: string;
              label: string;
              to: string;
            }
          | {
              __typename?: 'GenericTocEntry';
              hasChildren: boolean;
              depth: number;
              id: string;
              label: string;
              to: string;
              children?: Array<
                | {
                    __typename?: 'DocumentTocEntry';
                    hasChildren: boolean;
                    depth: number;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericTocEntry';
                    hasChildren: boolean;
                    depth: number;
                    id: string;
                    label: string;
                    to: string;
                    children: Array<
                      | {
                          __typename?: 'DocumentTocEntry';
                          hasChildren: boolean;
                          depth: number;
                          id: string;
                          label: string;
                          to: string;
                        }
                      | {
                          __typename?: 'GenericTocEntry';
                          hasChildren: boolean;
                          depth: number;
                          id: string;
                          label: string;
                          to: string;
                          children: Array<
                            | {
                                __typename?: 'DocumentTocEntry';
                                id: string;
                                hasChildren: boolean;
                                depth: number;
                                label: string;
                                to: string;
                              }
                            | {
                                __typename?: 'GenericTocEntry';
                                id: string;
                                label: string;
                                to: string;
                              }
                          >;
                        }
                    >;
                  }
              >;
            }
        >;
      }
    | {
        __typename?: 'TopLevelTocEntry';
        depth: number;
        label: string;
        children: Array<
          | {
              __typename?: 'DocumentTocEntry';
              hasChildren: boolean;
              depth: number;
              id: string;
              label: string;
              to: string;
            }
          | {
              __typename?: 'GenericTocEntry';
              hasChildren: boolean;
              depth: number;
              id: string;
              label: string;
              to: string;
              children?: Array<
                | {
                    __typename?: 'DocumentTocEntry';
                    hasChildren: boolean;
                    depth: number;
                    id: string;
                    label: string;
                    to: string;
                  }
                | {
                    __typename?: 'GenericTocEntry';
                    hasChildren: boolean;
                    depth: number;
                    id: string;
                    label: string;
                    to: string;
                    children: Array<
                      | {
                          __typename?: 'DocumentTocEntry';
                          hasChildren: boolean;
                          depth: number;
                          id: string;
                          label: string;
                          to: string;
                        }
                      | {
                          __typename?: 'GenericTocEntry';
                          hasChildren: boolean;
                          depth: number;
                          id: string;
                          label: string;
                          to: string;
                          children: Array<
                            | {
                                __typename?: 'DocumentTocEntry';
                                id: string;
                                hasChildren: boolean;
                                depth: number;
                                label: string;
                                to: string;
                              }
                            | {
                                __typename?: 'GenericTocEntry';
                                id: string;
                                label: string;
                                to: string;
                              }
                          >;
                        }
                    >;
                  }
              >;
            }
        >;
      }
    | null;
};

export type RecentMagazineArticlesFeedQueryVariables = Exact<{
  magazineId: Scalars['String']['input'];
  from?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
}>;

export type RecentMagazineArticlesFeedQuery = {
  __typename?: 'Query';
  magazines: Array<{
    __typename?: 'GenMagazine';
    id: string;
    editionYears: Array<string>;
    articles: {
      __typename?: 'AllArticles';
      articles: Array<{
        __typename?: 'MagazineArticle';
        id: string;
        swsId: string;
        number: string;
        articleNumber?: string | null;
        title: string;
        abstract?: string | null;
        teaser: string;
        edition: string;
        publicationDate?: string | null;
        publicationIdentifier: string;
        publicationCommonTitle?: string | null;
        type: Array<string>;
        link?: {
          __typename?: 'ContentLink';
          primaryLink: { __typename?: 'Link'; isExternal: boolean; url: string };
          secondaryLink?: { __typename?: 'Link'; isExternal: boolean; url: string } | null;
        } | null;
        authors: Array<{ __typename?: 'Author'; name: string; function: string }>;
        annotators: Array<{ __typename?: 'Author'; name: string }>;
        eclis: Array<{
          __typename?: 'EcliMetadata';
          identifier?: string | null;
          name?: string | null;
          caseDate?: string | null;
          publicationDate?: string | null;
          link?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
        }>;
      }>;
    };
  }>;
};

export type RecentMagazinesArticlesFeedQueryVariables = Exact<{
  from?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
}>;

export type RecentMagazinesArticlesFeedQuery = {
  __typename?: 'Query';
  allArticles: {
    __typename?: 'AllArticles';
    articles: Array<{
      __typename?: 'MagazineArticle';
      id: string;
      swsId: string;
      number: string;
      articleNumber?: string | null;
      title: string;
      abstract?: string | null;
      teaser: string;
      edition: string;
      publicationDate?: string | null;
      publicationIdentifier: string;
      publicationCommonTitle?: string | null;
      type: Array<string>;
      link?: {
        __typename?: 'ContentLink';
        primaryLink: { __typename?: 'Link'; isExternal: boolean; url: string };
        secondaryLink?: { __typename?: 'Link'; isExternal: boolean; url: string } | null;
      } | null;
      authors: Array<{ __typename?: 'Author'; name: string; function: string }>;
      annotators: Array<{ __typename?: 'Author'; name: string }>;
      eclis: Array<{
        __typename?: 'EcliMetadata';
        identifier?: string | null;
        name?: string | null;
        caseDate?: string | null;
        publicationDate?: string | null;
        link?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
      }>;
    }>;
  };
};

export type RecentNewsArticlesFeedQueryVariables = Exact<{
  from?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  newsId?: InputMaybe<Scalars['String']['input']>;
  sections?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type RecentNewsArticlesFeedQuery = {
  __typename?: 'Query';
  allNews: Array<{
    __typename?: 'NewsFeedItem';
    identifier: string;
    title: string;
    publicationDate?: string | null;
    publicationIdentifier: string;
    publicationTitle: string;
    conciseCitation?: string | null;
    teaser: string;
  }>;
};

export type MagazineEditionsSidebarBlockQueryVariables = Exact<{
  size?: InputMaybe<Scalars['Int']['input']>;
  magazineId: Scalars['String']['input'];
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type MagazineEditionsSidebarBlockQuery = {
  __typename?: 'Query';
  magazines: Array<{
    __typename?: 'GenMagazine';
    editions: Array<{
      __typename?: 'MagazineEdition';
      magazineId: string;
      commonTitle: string;
      id: string;
      number: string;
      date?: string | null;
    }>;
  }>;
};

export type MagazinesSidebarBlockQueryVariables = Exact<{
  size?: InputMaybe<Scalars['Int']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type MagazinesSidebarBlockQuery = {
  __typename?: 'Query';
  magazines: Array<{
    __typename?: 'GenMagazine';
    id: string;
    title: string;
    commonTitle: string;
    lastPublished?: string | null;
  }>;
};

export type NewsSourcesQueryVariables = Exact<{
  size?: InputMaybe<Scalars['Int']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  newsId?: InputMaybe<Scalars['String']['input']>;
}>;

export type NewsSourcesQuery = {
  __typename?: 'Query';
  newsPublishers: Array<{
    __typename?: 'NewsPublisher';
    identifier: string;
    title: string;
    lastUpdated: string;
    sections: Array<string>;
  }>;
};

export type GenericTocEntryFragment = {
  __typename?: 'TocEntry';
  id: string;
  anchor: string;
  parentId?: string | null;
  hasChildren: boolean;
  label: string;
};

export type PracticeNoteTocEntryFragment = {
  __typename?: 'PracticeNoteTocEntry';
  id: string;
  anchor: string;
  parentId?: string | null;
  hasChildren: boolean;
  label: string;
};

export type CaseLawTocEntryFragment = {
  __typename?: 'CaseLawTocEntry';
  id: string;
  anchor: string;
  parentId?: string | null;
  hasChildren: boolean;
  label: string;
};

type LawTocEntryArticleLawTocEntryFragment = {
  __typename?: 'ArticleLawTocEntry';
  id: string;
  anchor: string;
  hasChildren: boolean;
  parentId?: string | null;
  label: string;
};

type LawTocEntryEntireLawTocEntryFragment = {
  __typename?: 'EntireLawTocEntry';
  parentId?: string | null;
  label: string;
};

type LawTocEntryGenericLawTocEntryFragment = {
  __typename?: 'GenericLawTocEntry';
  id: string;
  anchor: string;
  hasChildren: boolean;
  parentId?: string | null;
  suffix: string;
  label: string;
};

type LawTocEntryOpschriftLawTocEntryFragment = {
  __typename?: 'OpschriftLawTocEntry';
  id: string;
  anchor: string;
  hasChildren: boolean;
  parentId?: string | null;
  label: string;
};

type LawTocEntryTopLevelLawTocEntryFragment = {
  __typename?: 'TopLevelLawTocEntry';
  parentId?: string | null;
  label: string;
};

export type LawTocEntryFragment =
  | LawTocEntryArticleLawTocEntryFragment
  | LawTocEntryEntireLawTocEntryFragment
  | LawTocEntryGenericLawTocEntryFragment
  | LawTocEntryOpschriftLawTocEntryFragment
  | LawTocEntryTopLevelLawTocEntryFragment;

export type BookTocEntryFragment = {
  __typename?: 'BookTocEntry';
  id: string;
  anchor: string;
  parentId?: string | null;
  hasChildren: boolean;
  label: string;
};

export type ArticleTocEntryFragment = {
  __typename?: 'ArticleTocEntry';
  id: string;
  anchor: string;
  parentId?: string | null;
  hasChildren: boolean;
  label: string;
};

export type TocEntriesQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type TocEntriesQuery = {
  __typename?: 'Query';
  content?:
    | {
        __typename?: 'Article';
        id: string;
        toc?: {
          __typename?: 'ArticleTocEntry';
          children: Array<{
            __typename?: 'ArticleTocEntry';
            id: string;
            anchor: string;
            parentId?: string | null;
            hasChildren: boolean;
            label: string;
          }>;
        } | null;
      }
    | {
        __typename?: 'Book';
        id: string;
        toc?: {
          __typename?: 'BookTocEntry';
          children: Array<{
            __typename?: 'BookTocEntry';
            id: string;
            anchor: string;
            parentId?: string | null;
            hasChildren: boolean;
            label: string;
          }>;
        } | null;
      }
    | {
        __typename?: 'CaseLaw';
        id: string;
        toc?: {
          __typename?: 'CaseLawTocEntry';
          children: Array<{
            __typename?: 'CaseLawTocEntry';
            id: string;
            anchor: string;
            parentId?: string | null;
            hasChildren: boolean;
            label: string;
          }>;
        } | null;
      }
    | {
        __typename?: 'Commentary';
        id: string;
        toc?: {
          __typename?: 'TocEntry';
          children: Array<{
            __typename?: 'TocEntry';
            id: string;
            anchor: string;
            parentId?: string | null;
            hasChildren: boolean;
            label: string;
          }>;
        } | null;
      }
    | {
        __typename?: 'Eurlex';
        id: string;
        toc?:
          | {
              __typename?: 'ArticleLawTocEntry';
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'EntireLawTocEntry'; parentId?: string | null; label: string }
                | {
                    __typename?: 'GenericLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    suffix: string;
                    label: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; parentId?: string | null; label: string }
              >;
            }
          | {
              __typename?: 'EntireLawTocEntry';
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'EntireLawTocEntry'; parentId?: string | null; label: string }
                | {
                    __typename?: 'GenericLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    suffix: string;
                    label: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; parentId?: string | null; label: string }
              >;
            }
          | {
              __typename?: 'GenericLawTocEntry';
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'EntireLawTocEntry'; parentId?: string | null; label: string }
                | {
                    __typename?: 'GenericLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    suffix: string;
                    label: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; parentId?: string | null; label: string }
              >;
            }
          | {
              __typename?: 'OpschriftLawTocEntry';
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'EntireLawTocEntry'; parentId?: string | null; label: string }
                | {
                    __typename?: 'GenericLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    suffix: string;
                    label: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; parentId?: string | null; label: string }
              >;
            }
          | {
              __typename?: 'TopLevelLawTocEntry';
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'EntireLawTocEntry'; parentId?: string | null; label: string }
                | {
                    __typename?: 'GenericLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    suffix: string;
                    label: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; parentId?: string | null; label: string }
              >;
            }
          | null;
      }
    | { __typename?: 'ExternalSource'; id: string }
    | {
        __typename?: 'Law';
        id: string;
        toc?:
          | {
              __typename?: 'ArticleLawTocEntry';
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'EntireLawTocEntry'; parentId?: string | null; label: string }
                | {
                    __typename?: 'GenericLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    suffix: string;
                    label: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; parentId?: string | null; label: string }
              >;
            }
          | {
              __typename?: 'EntireLawTocEntry';
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'EntireLawTocEntry'; parentId?: string | null; label: string }
                | {
                    __typename?: 'GenericLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    suffix: string;
                    label: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; parentId?: string | null; label: string }
              >;
            }
          | {
              __typename?: 'GenericLawTocEntry';
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'EntireLawTocEntry'; parentId?: string | null; label: string }
                | {
                    __typename?: 'GenericLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    suffix: string;
                    label: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; parentId?: string | null; label: string }
              >;
            }
          | {
              __typename?: 'OpschriftLawTocEntry';
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'EntireLawTocEntry'; parentId?: string | null; label: string }
                | {
                    __typename?: 'GenericLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    suffix: string;
                    label: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; parentId?: string | null; label: string }
              >;
            }
          | {
              __typename?: 'TopLevelLawTocEntry';
              children: Array<
                | {
                    __typename?: 'ArticleLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'EntireLawTocEntry'; parentId?: string | null; label: string }
                | {
                    __typename?: 'GenericLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    suffix: string;
                    label: string;
                  }
                | {
                    __typename?: 'OpschriftLawTocEntry';
                    id: string;
                    anchor: string;
                    hasChildren: boolean;
                    parentId?: string | null;
                    label: string;
                  }
                | { __typename?: 'TopLevelLawTocEntry'; parentId?: string | null; label: string }
              >;
            }
          | null;
      }
    | { __typename?: 'MagazineArticle'; id: string }
    | { __typename?: 'News'; id: string }
    | { __typename?: 'OP'; id: string }
    | { __typename?: 'Overview'; id: string }
    | {
        __typename?: 'PracticeNote';
        id: string;
        toc?: {
          __typename?: 'PracticeNoteTocEntry';
          children: Array<{
            __typename?: 'PracticeNoteTocEntry';
            id: string;
            anchor: string;
            parentId?: string | null;
            hasChildren: boolean;
            label: string;
          }>;
        } | null;
      }
    | { __typename?: 'Ruling'; id: string }
    | { __typename?: 'Tool'; id: string }
    | null;
};

export type ContentQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  codeBaseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  contentParts?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  subId?: InputMaybe<Scalars['String']['input']>;
}>;

export type ContentQuery = {
  __typename?: 'Query';
  content?:
    | {
        __typename?: 'Article';
        hasToc: boolean;
        id: string;
        title: string;
        originalSourceUrl: string;
        footNotes?: string | null;
        subtitle: string;
        isMagazineArticle: boolean;
        pdfAttachment?: string | null;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          documentDate?: string | null;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
        };
        articleAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
        otherArticles?: Array<{
          __typename?: 'BasicMagazineArticle';
          id: string;
          title: string;
          teaser: string;
          linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        }> | null;
        prevNextArticle: {
          __typename?: 'PrevNextLinks';
          previous?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
          next?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
        };
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'BookContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CaseLawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CommentaryContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'EurlexContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'LawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'NewsContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OPContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OverviewContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'RulingContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'ToolContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
        >;
      }
    | {
        __typename?: 'Book';
        hasToc: boolean;
        id: string;
        title: string;
        originalSourceUrl: string;
        footNotes?: string | null;
        publicationDate?: string | null;
        editionNumber?: string | null;
        isbn?: string | null;
        pdfOnly: boolean;
        pdfAttachment?: string | null;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
        };
        previousVersions: Array<{
          __typename?: 'BookOlderVersion';
          identifier: string;
          title: string;
          publicationDate: string;
          publicationIdentifier: string;
          identifierLink?: {
            __typename?: 'contentForAboutId';
            link: string;
            linkStatus: string;
          } | null;
        }>;
        bookAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'BookContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CaseLawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CommentaryContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'EurlexContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'LawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'NewsContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OPContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OverviewContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'RulingContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'ToolContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
        >;
      }
    | {
        __typename?: 'CaseLaw';
        hasToc: boolean;
        id: string;
        title: string;
        originalSourceUrl: string;
        footNotes?: string | null;
        articleNumber?: string | null;
        caseDate: string;
        publicationDate?: string | null;
        taxYear?: string | null;
        pdfAttachment?: string | null;
        hasMoonlit: boolean;
        cassationCaseNumber?: string | null;
        isMagazineArticle: boolean;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{
            __typename?: 'CreatorItem';
            identifier?: string | null;
            role: string;
            name: string;
            function?: string | null;
          }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
          about: Array<{
            __typename?: 'AboutItem';
            altKey: Array<string>;
            caseNumber: Array<string>;
            identifier?: string | null;
            identifierLink?: {
              __typename?: 'contentForAboutId';
              link: string;
              linkStatus: string;
            } | null;
            creator: Array<{ __typename?: 'CreatorItem'; name: string }>;
          }>;
          relation: Array<{
            __typename?: 'RelationItem';
            identifier?: string | null;
            type: Array<string>;
            displayTitle?: string | null;
          }>;
          references: Array<{
            __typename?: 'ReferencesItem';
            displayTitle?: string | null;
            identifier?: string | null;
            identifierLink?: {
              __typename?: 'contentForAboutId';
              link: string;
              linkStatus: string;
            } | null;
          }>;
        };
        oldEcliPdf?: {
          __typename?: 'ReferenceItem';
          title: string;
          linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        } | null;
        otherArticles?: Array<{
          __typename?: 'BasicMagazineArticle';
          id: string;
          title: string;
          teaser: string;
          linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        }> | null;
        prevNextArticle: {
          __typename?: 'PrevNextLinks';
          previous?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
          next?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
        };
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'BookContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CaseLawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CommentaryContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'EurlexContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'LawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'NewsContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OPContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OverviewContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'RulingContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'ToolContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
        >;
      }
    | {
        __typename?: 'Commentary';
        hasToc: boolean;
        id: string;
        title: string;
        originalSourceUrl: string;
        footNotes?: string | null;
        subtitle: string;
        authors: Array<string>;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          documentDate?: string | null;
          creator: Array<{
            __typename?: 'CreatorItem';
            identifier?: string | null;
            name: string;
            shortInitials?: string | null;
            function?: string | null;
          }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
          about: Array<{
            __typename?: 'AboutItem';
            identifier?: string | null;
            title?: string | null;
            part: Array<{ __typename?: 'PartItem'; article?: string | null }>;
          }>;
        };
        thematicDrawer?: {
          __typename?: 'ThematicDrawer';
          id: string;
          publicationIdentifier: string;
        } | null;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'BookContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CaseLawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CommentaryContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'EurlexContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'LawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'NewsContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OPContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OverviewContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'RulingContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'ToolContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
        >;
      }
    | {
        __typename?: 'Eurlex';
        hasToc: boolean;
        id: string;
        title: string;
        originalSourceUrl: string;
        footNotes?: string | null;
        pdfAttachment?: string | null;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          instantie?: string | null;
          ecli: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
          about: Array<{ __typename?: 'AboutItem'; caseDate: Array<string> }>;
        };
        translations: Array<{
          __typename?: 'Translation';
          isCurrent: boolean;
          language: string;
          linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        }>;
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'BookContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CaseLawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CommentaryContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'EurlexContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'LawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'NewsContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OPContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OverviewContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'RulingContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'ToolContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
        >;
      }
    | {
        __typename?: 'ExternalSource';
        hasToc: boolean;
        id: string;
        title: string;
        originalSourceUrl: string;
        html: string;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
        };
      }
    | {
        __typename?: 'Law';
        hasToc: boolean;
        id: string;
        title: string;
        originalSourceUrl: string;
        footNotes?: string | null;
        isExpired: boolean;
        latestVersionOfLaw?: string | null;
        currentBesluiten: string;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
        };
        validityStatus?: {
          __typename?: 'ValidityStatus';
          start?: string | null;
          end?: string | null;
          status?: string | null;
        } | null;
        previousBesluiten: Array<{
          __typename?: 'BesluitenVersion';
          kenmerk: string;
          besluitenLink: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        } | null>;
        nextBesluiten: Array<{
          __typename?: 'BesluitenVersion';
          kenmerk: string;
          besluitenLink: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        } | null>;
        previousVersions: Array<{
          __typename?: 'LawArticleVersion';
          date: string;
          textInPublication: string;
          bwbCanonicalLink?: {
            __typename?: 'contentForAboutId';
            link: string;
            linkStatus: string;
          } | null;
          publications: Array<{
            __typename?: 'PublicationsInOp';
            text: string;
            opLink?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
          }>;
          lines: Array<{
            __typename?: 'MultipleLawVersion';
            publications: Array<{
              __typename?: 'PublicationsInOp';
              text: string;
              opLink?: {
                __typename?: 'contentForAboutId';
                link: string;
                linkStatus: string;
              } | null;
            }>;
          }>;
        }>;
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'BookContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CaseLawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CommentaryContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'EurlexContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'LawContentPart';
              id: string;
              article?: string | null;
              isExpired: boolean;
              htmlBlob: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              tabs: Array<
                | { __typename?: 'Besluiten'; label: string }
                | { __typename?: 'CodeNews'; label: string }
                | { __typename?: 'CodeRechtspraak'; label: string }
                | { __typename?: 'Commentaar'; label: string }
                | { __typename?: 'Explanations'; label: string }
                | { __typename?: 'Jurisprudentie'; label: string }
                | { __typename?: 'Kern'; label: string }
                | { __typename?: 'LawJurisprudentie'; label: string }
                | { __typename?: 'LawNews'; label: string }
                | { __typename?: 'LawPracticeNotes'; label: string }
                | { __typename?: 'Literatuur'; label: string }
                | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string }
                | { __typename?: 'PNJurisprudentie'; label: string }
                | { __typename?: 'PNLiteratuur'; label: string }
                | { __typename?: 'PNNews'; label: string }
                | { __typename?: 'PNTools'; label: string }
                | { __typename?: 'PNVerdieping'; label: string }
                | { __typename?: 'Parlementair'; label: string }
                | { __typename?: 'Tools'; label: string }
                | { __typename?: 'ToolsJurisprudentie'; label: string }
                | { __typename?: 'ToolsLaw'; label: string }
                | { __typename?: 'ToolsNews'; label: string }
                | { __typename?: 'ToolsRelatedTopics'; label: string }
                | { __typename?: 'Versies'; label: string }
                | { __typename?: 'Wenr'; label: string }
                | { __typename?: 'WetsTab'; label: string }
              >;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'NewsContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OPContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OverviewContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'RulingContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'ToolContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
        >;
      }
    | {
        __typename?: 'MagazineArticle';
        hasToc: boolean;
        id: string;
        title: string;
        originalSourceUrl: string;
        footNotes?: string | null;
        year: string;
        number: string;
        publicationCommonTitle?: string | null;
        publicationIdentifier: string;
        edition: string;
        isOldPdf: boolean;
        isPdfEdition: boolean;
        abstract?: string | null;
        pdfOnly: boolean;
        pdfAttachment?: string | null;
        hasPdfRefs: boolean;
        isNews: boolean;
        keywords: Array<string>;
        swsId: string;
        articleNumber?: string | null;
        editionPageTitle: string;
        type: Array<string>;
        publicationDate?: string | null;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
        };
        references: Array<{
          __typename?: 'ReferenceItem';
          title: string;
          linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        }>;
        otherArticles?: Array<{
          __typename?: 'BasicMagazineArticle';
          id: string;
          title: string;
          teaser: string;
          linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        }> | null;
        prevNextArticle: {
          __typename?: 'PrevNextLinks';
          previous?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
          next?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
        };
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'BookContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CaseLawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CommentaryContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'EurlexContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'LawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'NewsContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OPContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OverviewContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'RulingContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'ToolContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
        >;
        link?: {
          __typename?: 'ContentLink';
          primaryLink: { __typename?: 'Link'; isExternal: boolean; url: string };
          secondaryLink?: { __typename?: 'Link'; isExternal: boolean; url: string } | null;
        } | null;
        magazineAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
        annotators: Array<{ __typename?: 'Author'; name: string }>;
        eclis: Array<{
          __typename?: 'EcliMetadata';
          identifier?: string | null;
          name?: string | null;
          caseDate?: string | null;
          publicationDate?: string | null;
          link?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
        }>;
      }
    | {
        __typename?: 'News';
        hasToc: boolean;
        id: string;
        title: string;
        originalSourceUrl: string;
        footNotes?: string | null;
        year: string;
        publicationDate?: string | null;
        publicationIdentifier: string;
        publicationNumber?: string | null;
        publicationTitle?: string | null;
        section?: string | null;
        keywords: Array<string>;
        pdfAttachment?: string | null;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationNumber?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
          about: Array<{
            __typename?: 'AboutItem';
            altKey: Array<string>;
            type: Array<string>;
            identifier?: string | null;
            identifierLink?: {
              __typename?: 'contentForAboutId';
              link: string;
              linkStatus: string;
            } | null;
          }>;
        };
        newsAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
        references: Array<{
          __typename?: 'ReferenceItem';
          title: string;
          linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        }>;
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'BookContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CaseLawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CommentaryContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'EurlexContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'LawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'NewsContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OPContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OverviewContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'RulingContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'ToolContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
        >;
      }
    | {
        __typename?: 'OP';
        hasToc: boolean;
        id: string;
        title: string;
        originalSourceUrl: string;
        caseNumber?: string | null;
        meetingYear?: string | null;
        subtitle: string;
        pdfAttachment?: string | null;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
          resource: Array<{ __typename?: 'ResourceItem'; identifier: string; format: string }>;
        };
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'BookContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CaseLawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CommentaryContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'EurlexContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'LawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'NewsContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OPContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OverviewContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'RulingContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'ToolContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
        >;
      }
    | {
        __typename?: 'Overview';
        hasToc: boolean;
        id: string;
        title: string;
        originalSourceUrl: string;
        footNotes?: string | null;
        isMagazineArticle: boolean;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          documentDate?: string | null;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
        };
        overviewAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'BookContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CaseLawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CommentaryContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'EurlexContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'LawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'NewsContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OPContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OverviewContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'RulingContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'ToolContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
        >;
      }
    | {
        __typename?: 'PracticeNote';
        hasToc: boolean;
        id: string;
        title: string;
        originalSourceUrl: string;
        footNotes?: string | null;
        isLinkList: boolean;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
          date: Array<{ __typename?: 'DateItem'; type: string; value: string }>;
        };
        practiceNoteAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
        pnNavigationDrawerInfo?: {
          __typename?: 'PNNavigationDrawerInfo';
          id: string;
          subjectId: string;
          subjectName: string;
          tocId: string;
          title: string;
        } | null;
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'BookContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CaseLawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CommentaryContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'EurlexContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'LawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'NewsContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OPContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OverviewContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'RulingContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'ToolContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
        >;
      }
    | {
        __typename?: 'Ruling';
        hasToc: boolean;
        id: string;
        title: string;
        originalSourceUrl: string;
        footNotes?: string | null;
        kenmerk: string;
        publicationDate?: string | null;
        status: string;
        isRulingPdf: boolean;
        pdfAttachment?: string | null;
        publicationTitle?: string | null;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          abstract?: string | null;
          documentDate?: string | null;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
        };
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'BookContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CaseLawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'CommentaryContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'EurlexContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'LawContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'NewsContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OPContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'OverviewContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'RulingContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
          | {
              __typename?: 'ToolContentPart';
              htmlBlob: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
            }
        >;
      }
    | {
        __typename?: 'Tool';
        hasToc: boolean;
        id: string;
        title: string;
        originalSourceUrl: string;
        footNotes?: string | null;
        abstract?: string | null;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          documentDate?: string | null;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
        };
        toolAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
        attachments: Array<{
          __typename?: 'Attachment';
          title: string;
          mimeType: string;
          blob: string;
        }>;
        references: Array<{
          __typename?: 'ToolReference';
          title: string;
          href: string;
          toolType: string;
          toolID: string;
        }>;
        toolsNavigationDrawerInfo?: {
          __typename?: 'ToolsNavigationDrawerInfo';
          id: string;
          subjectId: string;
          subjectName: string;
          title: string;
          tocId: string;
        } | null;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
      }
    | null;
};

export type ContentPrintQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  contentParts?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  subId?: InputMaybe<Scalars['String']['input']>;
}>;

export type ContentPrintQuery = {
  __typename?: 'Query';
  content?:
    | {
        __typename?: 'Article';
        id: string;
        title: string;
        originalSourceUrl: string;
        subtitle: string;
        isMagazineArticle: boolean;
        pdfAttachment?: string | null;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          documentDate?: string | null;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
        };
        articleAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
        otherArticles?: Array<{
          __typename?: 'BasicMagazineArticle';
          id: string;
          title: string;
          teaser: string;
          linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        }> | null;
        prevNextArticle: {
          __typename?: 'PrevNextLinks';
          previous?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
          next?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
        };
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'BookContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CaseLawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CommentaryContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'EurlexContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'LawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'NewsContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OPContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OverviewContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'RulingContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'ToolContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
        >;
      }
    | {
        __typename?: 'Book';
        id: string;
        title: string;
        originalSourceUrl: string;
        publicationDate?: string | null;
        editionNumber?: string | null;
        isbn?: string | null;
        pdfOnly: boolean;
        pdfAttachment?: string | null;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
        };
        previousVersions: Array<{
          __typename?: 'BookOlderVersion';
          identifier: string;
          title: string;
          publicationDate: string;
          publicationIdentifier: string;
          identifierLink?: {
            __typename?: 'contentForAboutId';
            link: string;
            linkStatus: string;
          } | null;
        }>;
        bookAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'BookContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CaseLawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CommentaryContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'EurlexContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'LawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'NewsContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OPContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OverviewContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'RulingContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'ToolContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
        >;
      }
    | {
        __typename?: 'CaseLaw';
        id: string;
        title: string;
        originalSourceUrl: string;
        articleNumber?: string | null;
        caseDate: string;
        publicationDate?: string | null;
        taxYear?: string | null;
        pdfAttachment?: string | null;
        hasMoonlit: boolean;
        cassationCaseNumber?: string | null;
        isMagazineArticle: boolean;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{
            __typename?: 'CreatorItem';
            identifier?: string | null;
            role: string;
            name: string;
            function?: string | null;
          }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
          about: Array<{
            __typename?: 'AboutItem';
            altKey: Array<string>;
            caseNumber: Array<string>;
            identifier?: string | null;
            identifierLink?: {
              __typename?: 'contentForAboutId';
              link: string;
              linkStatus: string;
            } | null;
            creator: Array<{ __typename?: 'CreatorItem'; name: string }>;
          }>;
          relation: Array<{
            __typename?: 'RelationItem';
            identifier?: string | null;
            type: Array<string>;
            displayTitle?: string | null;
          }>;
          references: Array<{
            __typename?: 'ReferencesItem';
            displayTitle?: string | null;
            identifier?: string | null;
            identifierLink?: {
              __typename?: 'contentForAboutId';
              link: string;
              linkStatus: string;
            } | null;
          }>;
        };
        oldEcliPdf?: {
          __typename?: 'ReferenceItem';
          title: string;
          linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        } | null;
        otherArticles?: Array<{
          __typename?: 'BasicMagazineArticle';
          id: string;
          title: string;
          teaser: string;
          linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        }> | null;
        prevNextArticle: {
          __typename?: 'PrevNextLinks';
          previous?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
          next?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
        };
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'BookContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CaseLawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CommentaryContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'EurlexContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'LawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'NewsContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OPContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OverviewContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'RulingContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'ToolContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
        >;
      }
    | {
        __typename?: 'Commentary';
        id: string;
        title: string;
        originalSourceUrl: string;
        subtitle: string;
        authors: Array<string>;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          documentDate?: string | null;
          creator: Array<{
            __typename?: 'CreatorItem';
            identifier?: string | null;
            name: string;
            shortInitials?: string | null;
            function?: string | null;
          }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
          about: Array<{
            __typename?: 'AboutItem';
            identifier?: string | null;
            title?: string | null;
            part: Array<{ __typename?: 'PartItem'; article?: string | null }>;
          }>;
        };
        thematicDrawer?: {
          __typename?: 'ThematicDrawer';
          id: string;
          publicationIdentifier: string;
        } | null;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'BookContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CaseLawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CommentaryContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'EurlexContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'LawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'NewsContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OPContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OverviewContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'RulingContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'ToolContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
        >;
      }
    | {
        __typename?: 'Eurlex';
        id: string;
        title: string;
        originalSourceUrl: string;
        pdfAttachment?: string | null;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          instantie?: string | null;
          ecli: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
          about: Array<{ __typename?: 'AboutItem'; caseDate: Array<string> }>;
        };
        translations: Array<{
          __typename?: 'Translation';
          isCurrent: boolean;
          language: string;
          linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        }>;
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'BookContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CaseLawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CommentaryContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'EurlexContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'LawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'NewsContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OPContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OverviewContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'RulingContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'ToolContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
        >;
      }
    | {
        __typename?: 'ExternalSource';
        id: string;
        title: string;
        originalSourceUrl: string;
        html: string;
        hasToc: boolean;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
        };
      }
    | {
        __typename?: 'Law';
        id: string;
        title: string;
        originalSourceUrl: string;
        isExpired: boolean;
        latestVersionOfLaw?: string | null;
        currentBesluiten: string;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
        };
        validityStatus?: {
          __typename?: 'ValidityStatus';
          start?: string | null;
          end?: string | null;
          status?: string | null;
        } | null;
        previousBesluiten: Array<{
          __typename?: 'BesluitenVersion';
          kenmerk: string;
          besluitenLink: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        } | null>;
        nextBesluiten: Array<{
          __typename?: 'BesluitenVersion';
          kenmerk: string;
          besluitenLink: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        } | null>;
        previousVersions: Array<{
          __typename?: 'LawArticleVersion';
          date: string;
          textInPublication: string;
          bwbCanonicalLink?: {
            __typename?: 'contentForAboutId';
            link: string;
            linkStatus: string;
          } | null;
          publications: Array<{
            __typename?: 'PublicationsInOp';
            text: string;
            opLink?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
          }>;
          lines: Array<{
            __typename?: 'MultipleLawVersion';
            publications: Array<{
              __typename?: 'PublicationsInOp';
              text: string;
              opLink?: {
                __typename?: 'contentForAboutId';
                link: string;
                linkStatus: string;
              } | null;
            }>;
          }>;
        }>;
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'BookContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CaseLawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CommentaryContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'EurlexContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'LawContentPart';
              id: string;
              article?: string | null;
              isExpired: boolean;
              html: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'NewsContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OPContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OverviewContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'RulingContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'ToolContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
        >;
      }
    | {
        __typename?: 'MagazineArticle';
        id: string;
        title: string;
        originalSourceUrl: string;
        year: string;
        number: string;
        publicationCommonTitle?: string | null;
        publicationIdentifier: string;
        edition: string;
        isOldPdf: boolean;
        isPdfEdition: boolean;
        abstract?: string | null;
        pdfOnly: boolean;
        pdfAttachment?: string | null;
        hasPdfRefs: boolean;
        isNews: boolean;
        keywords: Array<string>;
        swsId: string;
        articleNumber?: string | null;
        editionPageTitle: string;
        type: Array<string>;
        publicationDate?: string | null;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
        };
        references: Array<{
          __typename?: 'ReferenceItem';
          title: string;
          linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        }>;
        otherArticles?: Array<{
          __typename?: 'BasicMagazineArticle';
          id: string;
          title: string;
          teaser: string;
          linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        }> | null;
        prevNextArticle: {
          __typename?: 'PrevNextLinks';
          previous?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
          next?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
        };
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'BookContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CaseLawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CommentaryContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'EurlexContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'LawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'NewsContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OPContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OverviewContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'RulingContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'ToolContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
        >;
        link?: {
          __typename?: 'ContentLink';
          primaryLink: { __typename?: 'Link'; isExternal: boolean; url: string };
          secondaryLink?: { __typename?: 'Link'; isExternal: boolean; url: string } | null;
        } | null;
        magazineAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
        annotators: Array<{ __typename?: 'Author'; name: string }>;
        eclis: Array<{
          __typename?: 'EcliMetadata';
          identifier?: string | null;
          name?: string | null;
          caseDate?: string | null;
          publicationDate?: string | null;
          link?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
        }>;
      }
    | {
        __typename?: 'News';
        id: string;
        title: string;
        originalSourceUrl: string;
        year: string;
        publicationDate?: string | null;
        publicationIdentifier: string;
        publicationNumber?: string | null;
        publicationTitle?: string | null;
        section?: string | null;
        keywords: Array<string>;
        pdfAttachment?: string | null;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationNumber?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
          about: Array<{
            __typename?: 'AboutItem';
            altKey: Array<string>;
            type: Array<string>;
            identifier?: string | null;
            identifierLink?: {
              __typename?: 'contentForAboutId';
              link: string;
              linkStatus: string;
            } | null;
          }>;
        };
        newsAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
        references: Array<{
          __typename?: 'ReferenceItem';
          title: string;
          linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
        }>;
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'BookContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CaseLawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CommentaryContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'EurlexContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'LawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'NewsContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OPContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OverviewContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'RulingContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'ToolContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
        >;
      }
    | {
        __typename?: 'OP';
        id: string;
        title: string;
        originalSourceUrl: string;
        caseNumber?: string | null;
        meetingYear?: string | null;
        subtitle: string;
        pdfAttachment?: string | null;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
          resource: Array<{ __typename?: 'ResourceItem'; identifier: string; format: string }>;
        };
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'BookContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CaseLawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CommentaryContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'EurlexContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'LawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'NewsContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OPContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OverviewContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'RulingContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'ToolContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
        >;
      }
    | {
        __typename?: 'Overview';
        id: string;
        title: string;
        originalSourceUrl: string;
        isMagazineArticle: boolean;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          documentDate?: string | null;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
        };
        overviewAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'BookContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CaseLawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CommentaryContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'EurlexContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'LawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'NewsContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OPContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OverviewContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'RulingContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'ToolContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
        >;
      }
    | {
        __typename?: 'PracticeNote';
        id: string;
        title: string;
        originalSourceUrl: string;
        isLinkList: boolean;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
          date: Array<{ __typename?: 'DateItem'; type: string; value: string }>;
        };
        practiceNoteAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
        pnNavigationDrawerInfo?: {
          __typename?: 'PNNavigationDrawerInfo';
          id: string;
          subjectId: string;
          subjectName: string;
          tocId: string;
          title: string;
        } | null;
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'BookContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CaseLawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CommentaryContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'EurlexContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'LawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'NewsContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OPContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OverviewContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'RulingContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'ToolContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
        >;
      }
    | {
        __typename?: 'Ruling';
        id: string;
        title: string;
        originalSourceUrl: string;
        kenmerk: string;
        publicationDate?: string | null;
        status: string;
        isRulingPdf: boolean;
        pdfAttachment?: string | null;
        publicationTitle?: string | null;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          abstract?: string | null;
          documentDate?: string | null;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
        };
        contentParts: Array<
          | {
              __typename?: 'ArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'BookContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CaseLawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'CommentaryContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'EurlexContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'LawContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'MagazineArticleContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'NewsContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OPContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'OverviewContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'PracticeNoteContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'RulingContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
          | {
              __typename?: 'ToolContentPart';
              html: string;
              id: string;
              title: string;
              anchor: string;
              anchors: Array<string>;
              depth: number;
              isContentEmpty: boolean;
              footNotes: Array<{
                __typename?: 'FootNote';
                id: string;
                anchor: string;
                html: string;
              }>;
            }
        >;
      }
    | {
        __typename?: 'Tool';
        id: string;
        title: string;
        originalSourceUrl: string;
        metadata: {
          __typename?: 'SwsCompactMetadata';
          teaser?: string | null;
          altKey: Array<string>;
          type: Array<string>;
          audience: Array<string>;
          creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
          legalArea: Array<{
            __typename?: 'LegalAreaItem';
            title?: string | null;
            identifier: string;
          }>;
          published: Array<{
            __typename?: 'PublishedItem';
            publicationYear?: string | null;
            number?: string | null;
            conciseCitation?: string | null;
            publicationName?: {
              __typename?: 'PublicationNameModel';
              identifier: string;
              title?: string | null;
            } | null;
          }>;
        };
      }
    | null;
};

export type ArticleFragment = {
  __typename?: 'Article';
  footNotes?: string | null;
  id: string;
  title: string;
  subtitle: string;
  isMagazineArticle: boolean;
  pdfAttachment?: string | null;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    documentDate?: string | null;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      publicationName?: { __typename?: 'PublicationNameModel'; identifier: string } | null;
    }>;
  };
  articleAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  otherArticles?: Array<{
    __typename?: 'BasicMagazineArticle';
    id: string;
    title: string;
    teaser: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }> | null;
  prevNextArticle: {
    __typename?: 'PrevNextLinks';
    previous?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
    next?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  };
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type ArticleBaseFragment = {
  __typename?: 'Article';
  id: string;
  title: string;
  subtitle: string;
  isMagazineArticle: boolean;
  pdfAttachment?: string | null;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    documentDate?: string | null;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      publicationName?: { __typename?: 'PublicationNameModel'; identifier: string } | null;
    }>;
  };
  articleAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  otherArticles?: Array<{
    __typename?: 'BasicMagazineArticle';
    id: string;
    title: string;
    teaser: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }> | null;
  prevNextArticle: {
    __typename?: 'PrevNextLinks';
    previous?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
    next?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  };
};

export type ArticleContentPartsFragment = {
  __typename?: 'Article';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type ArticleContentPartsPrintFragment = {
  __typename?: 'Article';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type ArticlePrintFragment = {
  __typename?: 'Article';
  id: string;
  title: string;
  subtitle: string;
  isMagazineArticle: boolean;
  pdfAttachment?: string | null;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    documentDate?: string | null;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      publicationName?: { __typename?: 'PublicationNameModel'; identifier: string } | null;
    }>;
  };
  articleAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  otherArticles?: Array<{
    __typename?: 'BasicMagazineArticle';
    id: string;
    title: string;
    teaser: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }> | null;
  prevNextArticle: {
    __typename?: 'PrevNextLinks';
    previous?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
    next?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  };
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type BookFragment = {
  __typename?: 'Book';
  footNotes?: string | null;
  id: string;
  title: string;
  publicationDate?: string | null;
  editionNumber?: string | null;
  isbn?: string | null;
  pdfOnly: boolean;
  pdfAttachment?: string | null;
  previousVersions: Array<{
    __typename?: 'BookOlderVersion';
    identifier: string;
    title: string;
    publicationDate: string;
    publicationIdentifier: string;
    identifierLink?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  }>;
  bookAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type BookBaseFragment = {
  __typename?: 'Book';
  id: string;
  title: string;
  publicationDate?: string | null;
  editionNumber?: string | null;
  isbn?: string | null;
  pdfOnly: boolean;
  pdfAttachment?: string | null;
  previousVersions: Array<{
    __typename?: 'BookOlderVersion';
    identifier: string;
    title: string;
    publicationDate: string;
    publicationIdentifier: string;
    identifierLink?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  }>;
  bookAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
};

export type BookContentPartsFragment = {
  __typename?: 'Book';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type BookContentPartsPrintFragment = {
  __typename?: 'Book';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type BookPrintFragment = {
  __typename?: 'Book';
  id: string;
  title: string;
  publicationDate?: string | null;
  editionNumber?: string | null;
  isbn?: string | null;
  pdfOnly: boolean;
  pdfAttachment?: string | null;
  previousVersions: Array<{
    __typename?: 'BookOlderVersion';
    identifier: string;
    title: string;
    publicationDate: string;
    publicationIdentifier: string;
    identifierLink?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  }>;
  bookAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type CaseLawFragment = {
  __typename?: 'CaseLaw';
  footNotes?: string | null;
  id: string;
  title: string;
  articleNumber?: string | null;
  caseDate: string;
  publicationDate?: string | null;
  taxYear?: string | null;
  pdfAttachment?: string | null;
  hasMoonlit: boolean;
  cassationCaseNumber?: string | null;
  isMagazineArticle: boolean;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    creator: Array<{
      __typename?: 'CreatorItem';
      role: string;
      name: string;
      function?: string | null;
    }>;
    about: Array<{
      __typename?: 'AboutItem';
      altKey: Array<string>;
      caseNumber: Array<string>;
      identifier?: string | null;
      identifierLink?: {
        __typename?: 'contentForAboutId';
        link: string;
        linkStatus: string;
      } | null;
      creator: Array<{ __typename?: 'CreatorItem'; name: string }>;
    }>;
    relation: Array<{
      __typename?: 'RelationItem';
      identifier?: string | null;
      type: Array<string>;
      displayTitle?: string | null;
    }>;
    references: Array<{
      __typename?: 'ReferencesItem';
      displayTitle?: string | null;
      identifier?: string | null;
      identifierLink?: {
        __typename?: 'contentForAboutId';
        link: string;
        linkStatus: string;
      } | null;
    }>;
  };
  oldEcliPdf?: {
    __typename?: 'ReferenceItem';
    title: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  } | null;
  otherArticles?: Array<{
    __typename?: 'BasicMagazineArticle';
    id: string;
    title: string;
    teaser: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }> | null;
  prevNextArticle: {
    __typename?: 'PrevNextLinks';
    previous?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
    next?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  };
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type CaseLawBaseFragment = {
  __typename?: 'CaseLaw';
  id: string;
  title: string;
  articleNumber?: string | null;
  caseDate: string;
  publicationDate?: string | null;
  taxYear?: string | null;
  pdfAttachment?: string | null;
  hasMoonlit: boolean;
  cassationCaseNumber?: string | null;
  isMagazineArticle: boolean;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    creator: Array<{
      __typename?: 'CreatorItem';
      role: string;
      name: string;
      function?: string | null;
    }>;
    about: Array<{
      __typename?: 'AboutItem';
      altKey: Array<string>;
      caseNumber: Array<string>;
      identifier?: string | null;
      identifierLink?: {
        __typename?: 'contentForAboutId';
        link: string;
        linkStatus: string;
      } | null;
      creator: Array<{ __typename?: 'CreatorItem'; name: string }>;
    }>;
    relation: Array<{
      __typename?: 'RelationItem';
      identifier?: string | null;
      type: Array<string>;
      displayTitle?: string | null;
    }>;
    references: Array<{
      __typename?: 'ReferencesItem';
      displayTitle?: string | null;
      identifier?: string | null;
      identifierLink?: {
        __typename?: 'contentForAboutId';
        link: string;
        linkStatus: string;
      } | null;
    }>;
  };
  oldEcliPdf?: {
    __typename?: 'ReferenceItem';
    title: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  } | null;
  otherArticles?: Array<{
    __typename?: 'BasicMagazineArticle';
    id: string;
    title: string;
    teaser: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }> | null;
  prevNextArticle: {
    __typename?: 'PrevNextLinks';
    previous?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
    next?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  };
};

export type CaseLawContentPartsFragment = {
  __typename?: 'CaseLaw';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type CaseLawContentPartsPrintFragment = {
  __typename?: 'CaseLaw';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type CaseLawPrintFragment = {
  __typename?: 'CaseLaw';
  id: string;
  title: string;
  articleNumber?: string | null;
  caseDate: string;
  publicationDate?: string | null;
  taxYear?: string | null;
  pdfAttachment?: string | null;
  hasMoonlit: boolean;
  cassationCaseNumber?: string | null;
  isMagazineArticle: boolean;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    creator: Array<{
      __typename?: 'CreatorItem';
      role: string;
      name: string;
      function?: string | null;
    }>;
    about: Array<{
      __typename?: 'AboutItem';
      altKey: Array<string>;
      caseNumber: Array<string>;
      identifier?: string | null;
      identifierLink?: {
        __typename?: 'contentForAboutId';
        link: string;
        linkStatus: string;
      } | null;
      creator: Array<{ __typename?: 'CreatorItem'; name: string }>;
    }>;
    relation: Array<{
      __typename?: 'RelationItem';
      identifier?: string | null;
      type: Array<string>;
      displayTitle?: string | null;
    }>;
    references: Array<{
      __typename?: 'ReferencesItem';
      displayTitle?: string | null;
      identifier?: string | null;
      identifierLink?: {
        __typename?: 'contentForAboutId';
        link: string;
        linkStatus: string;
      } | null;
    }>;
  };
  oldEcliPdf?: {
    __typename?: 'ReferenceItem';
    title: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  } | null;
  otherArticles?: Array<{
    __typename?: 'BasicMagazineArticle';
    id: string;
    title: string;
    teaser: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }> | null;
  prevNextArticle: {
    __typename?: 'PrevNextLinks';
    previous?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
    next?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  };
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type CommentaryFragment = {
  __typename?: 'Commentary';
  footNotes?: string | null;
  id: string;
  title: string;
  subtitle: string;
  authors: Array<string>;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    documentDate?: string | null;
    about: Array<{
      __typename?: 'AboutItem';
      identifier?: string | null;
      title?: string | null;
      part: Array<{ __typename?: 'PartItem'; article?: string | null }>;
    }>;
    creator: Array<{
      __typename?: 'CreatorItem';
      name: string;
      shortInitials?: string | null;
      function?: string | null;
    }>;
  };
  thematicDrawer?: {
    __typename?: 'ThematicDrawer';
    id: string;
    publicationIdentifier: string;
  } | null;
  tabs: Array<
    | { __typename?: 'Besluiten'; label: string; icon: string }
    | { __typename?: 'CodeNews'; label: string; icon: string }
    | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
    | { __typename?: 'Commentaar'; label: string; icon: string }
    | { __typename?: 'Explanations'; label: string; icon: string }
    | { __typename?: 'Jurisprudentie'; label: string; icon: string }
    | { __typename?: 'Kern'; label: string; icon: string }
    | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
    | { __typename?: 'LawNews'; label: string; icon: string }
    | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
    | { __typename?: 'Literatuur'; label: string; icon: string }
    | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
    | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
    | { __typename?: 'PNLiteratuur'; label: string; icon: string }
    | { __typename?: 'PNNews'; label: string; icon: string }
    | { __typename?: 'PNTools'; label: string; icon: string }
    | { __typename?: 'PNVerdieping'; label: string; icon: string }
    | { __typename?: 'Parlementair'; label: string; icon: string }
    | { __typename?: 'Tools'; label: string; icon: string }
    | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
    | { __typename?: 'ToolsLaw'; label: string; icon: string }
    | { __typename?: 'ToolsNews'; label: string; icon: string }
    | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
    | { __typename?: 'Versies'; label: string; icon: string }
    | { __typename?: 'Wenr'; label: string; icon: string }
    | { __typename?: 'WetsTab'; label: string; icon: string }
  >;
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type CommentaryBaseFragment = {
  __typename?: 'Commentary';
  id: string;
  title: string;
  subtitle: string;
  authors: Array<string>;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    documentDate?: string | null;
    about: Array<{
      __typename?: 'AboutItem';
      identifier?: string | null;
      title?: string | null;
      part: Array<{ __typename?: 'PartItem'; article?: string | null }>;
    }>;
    creator: Array<{
      __typename?: 'CreatorItem';
      name: string;
      shortInitials?: string | null;
      function?: string | null;
    }>;
  };
  thematicDrawer?: {
    __typename?: 'ThematicDrawer';
    id: string;
    publicationIdentifier: string;
  } | null;
  tabs: Array<
    | { __typename?: 'Besluiten'; label: string; icon: string }
    | { __typename?: 'CodeNews'; label: string; icon: string }
    | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
    | { __typename?: 'Commentaar'; label: string; icon: string }
    | { __typename?: 'Explanations'; label: string; icon: string }
    | { __typename?: 'Jurisprudentie'; label: string; icon: string }
    | { __typename?: 'Kern'; label: string; icon: string }
    | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
    | { __typename?: 'LawNews'; label: string; icon: string }
    | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
    | { __typename?: 'Literatuur'; label: string; icon: string }
    | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
    | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
    | { __typename?: 'PNLiteratuur'; label: string; icon: string }
    | { __typename?: 'PNNews'; label: string; icon: string }
    | { __typename?: 'PNTools'; label: string; icon: string }
    | { __typename?: 'PNVerdieping'; label: string; icon: string }
    | { __typename?: 'Parlementair'; label: string; icon: string }
    | { __typename?: 'Tools'; label: string; icon: string }
    | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
    | { __typename?: 'ToolsLaw'; label: string; icon: string }
    | { __typename?: 'ToolsNews'; label: string; icon: string }
    | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
    | { __typename?: 'Versies'; label: string; icon: string }
    | { __typename?: 'Wenr'; label: string; icon: string }
    | { __typename?: 'WetsTab'; label: string; icon: string }
  >;
};

export type CommentaryContentPartsFragment = {
  __typename?: 'Commentary';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type CommentaryContentPartsPrintFragment = {
  __typename?: 'Commentary';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type CommentaryPrintFragment = {
  __typename?: 'Commentary';
  id: string;
  title: string;
  subtitle: string;
  authors: Array<string>;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    documentDate?: string | null;
    about: Array<{
      __typename?: 'AboutItem';
      identifier?: string | null;
      title?: string | null;
      part: Array<{ __typename?: 'PartItem'; article?: string | null }>;
    }>;
    creator: Array<{
      __typename?: 'CreatorItem';
      name: string;
      shortInitials?: string | null;
      function?: string | null;
    }>;
  };
  thematicDrawer?: {
    __typename?: 'ThematicDrawer';
    id: string;
    publicationIdentifier: string;
  } | null;
  tabs: Array<
    | { __typename?: 'Besluiten'; label: string; icon: string }
    | { __typename?: 'CodeNews'; label: string; icon: string }
    | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
    | { __typename?: 'Commentaar'; label: string; icon: string }
    | { __typename?: 'Explanations'; label: string; icon: string }
    | { __typename?: 'Jurisprudentie'; label: string; icon: string }
    | { __typename?: 'Kern'; label: string; icon: string }
    | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
    | { __typename?: 'LawNews'; label: string; icon: string }
    | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
    | { __typename?: 'Literatuur'; label: string; icon: string }
    | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
    | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
    | { __typename?: 'PNLiteratuur'; label: string; icon: string }
    | { __typename?: 'PNNews'; label: string; icon: string }
    | { __typename?: 'PNTools'; label: string; icon: string }
    | { __typename?: 'PNVerdieping'; label: string; icon: string }
    | { __typename?: 'Parlementair'; label: string; icon: string }
    | { __typename?: 'Tools'; label: string; icon: string }
    | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
    | { __typename?: 'ToolsLaw'; label: string; icon: string }
    | { __typename?: 'ToolsNews'; label: string; icon: string }
    | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
    | { __typename?: 'Versies'; label: string; icon: string }
    | { __typename?: 'Wenr'; label: string; icon: string }
    | { __typename?: 'WetsTab'; label: string; icon: string }
  >;
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

type ContentBaseArticleFragment = {
  __typename?: 'Article';
  id: string;
  title: string;
  originalSourceUrl: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    teaser?: string | null;
    altKey: Array<string>;
    type: Array<string>;
    audience: Array<string>;
    creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
    legalArea: Array<{ __typename?: 'LegalAreaItem'; title?: string | null; identifier: string }>;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      conciseCitation?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
};

type ContentBaseBookFragment = {
  __typename?: 'Book';
  id: string;
  title: string;
  originalSourceUrl: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    teaser?: string | null;
    altKey: Array<string>;
    type: Array<string>;
    audience: Array<string>;
    creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
    legalArea: Array<{ __typename?: 'LegalAreaItem'; title?: string | null; identifier: string }>;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      conciseCitation?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
};

type ContentBaseCaseLawFragment = {
  __typename?: 'CaseLaw';
  id: string;
  title: string;
  originalSourceUrl: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    teaser?: string | null;
    altKey: Array<string>;
    type: Array<string>;
    audience: Array<string>;
    creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
    legalArea: Array<{ __typename?: 'LegalAreaItem'; title?: string | null; identifier: string }>;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      conciseCitation?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
};

type ContentBaseCommentaryFragment = {
  __typename?: 'Commentary';
  id: string;
  title: string;
  originalSourceUrl: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    teaser?: string | null;
    altKey: Array<string>;
    type: Array<string>;
    audience: Array<string>;
    creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
    legalArea: Array<{ __typename?: 'LegalAreaItem'; title?: string | null; identifier: string }>;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      conciseCitation?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
};

type ContentBaseEurlexFragment = {
  __typename?: 'Eurlex';
  id: string;
  title: string;
  originalSourceUrl: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    teaser?: string | null;
    altKey: Array<string>;
    type: Array<string>;
    audience: Array<string>;
    creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
    legalArea: Array<{ __typename?: 'LegalAreaItem'; title?: string | null; identifier: string }>;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      conciseCitation?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
};

type ContentBaseExternalSourceFragment = {
  __typename?: 'ExternalSource';
  id: string;
  title: string;
  originalSourceUrl: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    teaser?: string | null;
    altKey: Array<string>;
    type: Array<string>;
    audience: Array<string>;
    creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
    legalArea: Array<{ __typename?: 'LegalAreaItem'; title?: string | null; identifier: string }>;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      conciseCitation?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
};

type ContentBaseLawFragment = {
  __typename?: 'Law';
  id: string;
  title: string;
  originalSourceUrl: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    teaser?: string | null;
    altKey: Array<string>;
    type: Array<string>;
    audience: Array<string>;
    creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
    legalArea: Array<{ __typename?: 'LegalAreaItem'; title?: string | null; identifier: string }>;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      conciseCitation?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
};

type ContentBaseMagazineArticleFragment = {
  __typename?: 'MagazineArticle';
  id: string;
  title: string;
  originalSourceUrl: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    teaser?: string | null;
    altKey: Array<string>;
    type: Array<string>;
    audience: Array<string>;
    creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
    legalArea: Array<{ __typename?: 'LegalAreaItem'; title?: string | null; identifier: string }>;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      conciseCitation?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
};

type ContentBaseNewsFragment = {
  __typename?: 'News';
  id: string;
  title: string;
  originalSourceUrl: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    teaser?: string | null;
    altKey: Array<string>;
    type: Array<string>;
    audience: Array<string>;
    creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
    legalArea: Array<{ __typename?: 'LegalAreaItem'; title?: string | null; identifier: string }>;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      conciseCitation?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
};

type ContentBaseOpFragment = {
  __typename?: 'OP';
  id: string;
  title: string;
  originalSourceUrl: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    teaser?: string | null;
    altKey: Array<string>;
    type: Array<string>;
    audience: Array<string>;
    creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
    legalArea: Array<{ __typename?: 'LegalAreaItem'; title?: string | null; identifier: string }>;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      conciseCitation?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
};

type ContentBaseOverviewFragment = {
  __typename?: 'Overview';
  id: string;
  title: string;
  originalSourceUrl: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    teaser?: string | null;
    altKey: Array<string>;
    type: Array<string>;
    audience: Array<string>;
    creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
    legalArea: Array<{ __typename?: 'LegalAreaItem'; title?: string | null; identifier: string }>;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      conciseCitation?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
};

type ContentBasePracticeNoteFragment = {
  __typename?: 'PracticeNote';
  id: string;
  title: string;
  originalSourceUrl: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    teaser?: string | null;
    altKey: Array<string>;
    type: Array<string>;
    audience: Array<string>;
    creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
    legalArea: Array<{ __typename?: 'LegalAreaItem'; title?: string | null; identifier: string }>;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      conciseCitation?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
};

type ContentBaseRulingFragment = {
  __typename?: 'Ruling';
  id: string;
  title: string;
  originalSourceUrl: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    teaser?: string | null;
    altKey: Array<string>;
    type: Array<string>;
    audience: Array<string>;
    creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
    legalArea: Array<{ __typename?: 'LegalAreaItem'; title?: string | null; identifier: string }>;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      conciseCitation?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
};

type ContentBaseToolFragment = {
  __typename?: 'Tool';
  id: string;
  title: string;
  originalSourceUrl: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    teaser?: string | null;
    altKey: Array<string>;
    type: Array<string>;
    audience: Array<string>;
    creator: Array<{ __typename?: 'CreatorItem'; identifier?: string | null }>;
    legalArea: Array<{ __typename?: 'LegalAreaItem'; title?: string | null; identifier: string }>;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      number?: string | null;
      conciseCitation?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
};

export type ContentBaseFragment =
  | ContentBaseArticleFragment
  | ContentBaseBookFragment
  | ContentBaseCaseLawFragment
  | ContentBaseCommentaryFragment
  | ContentBaseEurlexFragment
  | ContentBaseExternalSourceFragment
  | ContentBaseLawFragment
  | ContentBaseMagazineArticleFragment
  | ContentBaseNewsFragment
  | ContentBaseOpFragment
  | ContentBaseOverviewFragment
  | ContentBasePracticeNoteFragment
  | ContentBaseRulingFragment
  | ContentBaseToolFragment;

type ContentPartArticleContentPartFragment = {
  __typename?: 'ArticleContentPart';
  id: string;
  title: string;
  anchor: string;
  anchors: Array<string>;
  depth: number;
  isContentEmpty: boolean;
};

type ContentPartBookContentPartFragment = {
  __typename?: 'BookContentPart';
  id: string;
  title: string;
  anchor: string;
  anchors: Array<string>;
  depth: number;
  isContentEmpty: boolean;
};

type ContentPartCaseLawContentPartFragment = {
  __typename?: 'CaseLawContentPart';
  id: string;
  title: string;
  anchor: string;
  anchors: Array<string>;
  depth: number;
  isContentEmpty: boolean;
};

type ContentPartCommentaryContentPartFragment = {
  __typename?: 'CommentaryContentPart';
  id: string;
  title: string;
  anchor: string;
  anchors: Array<string>;
  depth: number;
  isContentEmpty: boolean;
};

type ContentPartEurlexContentPartFragment = {
  __typename?: 'EurlexContentPart';
  id: string;
  title: string;
  anchor: string;
  anchors: Array<string>;
  depth: number;
  isContentEmpty: boolean;
};

type ContentPartLawContentPartFragment = {
  __typename?: 'LawContentPart';
  id: string;
  title: string;
  anchor: string;
  anchors: Array<string>;
  depth: number;
  isContentEmpty: boolean;
};

type ContentPartMagazineArticleContentPartFragment = {
  __typename?: 'MagazineArticleContentPart';
  id: string;
  title: string;
  anchor: string;
  anchors: Array<string>;
  depth: number;
  isContentEmpty: boolean;
};

type ContentPartNewsContentPartFragment = {
  __typename?: 'NewsContentPart';
  id: string;
  title: string;
  anchor: string;
  anchors: Array<string>;
  depth: number;
  isContentEmpty: boolean;
};

type ContentPartOpContentPartFragment = {
  __typename?: 'OPContentPart';
  id: string;
  title: string;
  anchor: string;
  anchors: Array<string>;
  depth: number;
  isContentEmpty: boolean;
};

type ContentPartOverviewContentPartFragment = {
  __typename?: 'OverviewContentPart';
  id: string;
  title: string;
  anchor: string;
  anchors: Array<string>;
  depth: number;
  isContentEmpty: boolean;
};

type ContentPartPracticeNoteContentPartFragment = {
  __typename?: 'PracticeNoteContentPart';
  id: string;
  title: string;
  anchor: string;
  anchors: Array<string>;
  depth: number;
  isContentEmpty: boolean;
};

type ContentPartRulingContentPartFragment = {
  __typename?: 'RulingContentPart';
  id: string;
  title: string;
  anchor: string;
  anchors: Array<string>;
  depth: number;
  isContentEmpty: boolean;
};

type ContentPartToolContentPartFragment = {
  __typename?: 'ToolContentPart';
  id: string;
  title: string;
  anchor: string;
  anchors: Array<string>;
  depth: number;
  isContentEmpty: boolean;
};

export type ContentPartFragment =
  | ContentPartArticleContentPartFragment
  | ContentPartBookContentPartFragment
  | ContentPartCaseLawContentPartFragment
  | ContentPartCommentaryContentPartFragment
  | ContentPartEurlexContentPartFragment
  | ContentPartLawContentPartFragment
  | ContentPartMagazineArticleContentPartFragment
  | ContentPartNewsContentPartFragment
  | ContentPartOpContentPartFragment
  | ContentPartOverviewContentPartFragment
  | ContentPartPracticeNoteContentPartFragment
  | ContentPartRulingContentPartFragment
  | ContentPartToolContentPartFragment;

export type DownloadFragment = {
  __typename: 'Download';
  id: string;
  name: string;
  excerpt: string;
  href: string;
  documentDate: string;
};

export type EurlexFragment = {
  __typename?: 'Eurlex';
  footNotes?: string | null;
  id: string;
  title: string;
  pdfAttachment?: string | null;
  translations: Array<{
    __typename?: 'Translation';
    isCurrent: boolean;
    language: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }>;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    instantie?: string | null;
    ecli: Array<string>;
    about: Array<{ __typename?: 'AboutItem'; caseDate: Array<string> }>;
  };
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type EurlexBaseFragment = {
  __typename?: 'Eurlex';
  id: string;
  title: string;
  pdfAttachment?: string | null;
  translations: Array<{
    __typename?: 'Translation';
    isCurrent: boolean;
    language: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }>;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    instantie?: string | null;
    ecli: Array<string>;
    about: Array<{ __typename?: 'AboutItem'; caseDate: Array<string> }>;
  };
};

export type EurlexContentPartsFragment = {
  __typename?: 'Eurlex';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type EurlexContentPartsPrintFragment = {
  __typename?: 'Eurlex';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type EurlexPrintFragment = {
  __typename?: 'Eurlex';
  id: string;
  title: string;
  pdfAttachment?: string | null;
  translations: Array<{
    __typename?: 'Translation';
    isCurrent: boolean;
    language: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }>;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    instantie?: string | null;
    ecli: Array<string>;
    about: Array<{ __typename?: 'AboutItem'; caseDate: Array<string> }>;
  };
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type ExternalSourceFragment = {
  __typename?: 'ExternalSource';
  id: string;
  html: string;
  hasToc: boolean;
  title: string;
  originalSourceUrl: string;
};

export type ExternalSourceBaseFragment = {
  __typename?: 'ExternalSource';
  id: string;
  html: string;
  hasToc: boolean;
  title: string;
  originalSourceUrl: string;
};

export type ExternalSourcePrintFragment = {
  __typename?: 'ExternalSource';
  id: string;
  html: string;
  hasToc: boolean;
  title: string;
  originalSourceUrl: string;
};

export type FootNoteFragment = {
  __typename?: 'FootNote';
  id: string;
  anchor: string;
  html: string;
};

export type LawFragment = {
  __typename?: 'Law';
  footNotes?: string | null;
  id: string;
  isExpired: boolean;
  title: string;
  latestVersionOfLaw?: string | null;
  currentBesluiten: string;
  validityStatus?: {
    __typename?: 'ValidityStatus';
    start?: string | null;
    end?: string | null;
    status?: string | null;
  } | null;
  previousBesluiten: Array<{
    __typename?: 'BesluitenVersion';
    kenmerk: string;
    besluitenLink: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  } | null>;
  nextBesluiten: Array<{
    __typename?: 'BesluitenVersion';
    kenmerk: string;
    besluitenLink: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  } | null>;
  previousVersions: Array<{
    __typename?: 'LawArticleVersion';
    date: string;
    textInPublication: string;
    bwbCanonicalLink?: {
      __typename?: 'contentForAboutId';
      link: string;
      linkStatus: string;
    } | null;
    publications: Array<{
      __typename?: 'PublicationsInOp';
      text: string;
      opLink?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
    }>;
    lines: Array<{
      __typename?: 'MultipleLawVersion';
      publications: Array<{
        __typename?: 'PublicationsInOp';
        text: string;
        opLink?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
      }>;
    }>;
  }>;
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        id: string;
        article?: string | null;
        isExpired: boolean;
        htmlBlob: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string }
          | { __typename?: 'CodeNews'; label: string }
          | { __typename?: 'CodeRechtspraak'; label: string }
          | { __typename?: 'Commentaar'; label: string }
          | { __typename?: 'Explanations'; label: string }
          | { __typename?: 'Jurisprudentie'; label: string }
          | { __typename?: 'Kern'; label: string }
          | { __typename?: 'LawJurisprudentie'; label: string }
          | { __typename?: 'LawNews'; label: string }
          | { __typename?: 'LawPracticeNotes'; label: string }
          | { __typename?: 'Literatuur'; label: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string }
          | { __typename?: 'PNJurisprudentie'; label: string }
          | { __typename?: 'PNLiteratuur'; label: string }
          | { __typename?: 'PNNews'; label: string }
          | { __typename?: 'PNTools'; label: string }
          | { __typename?: 'PNVerdieping'; label: string }
          | { __typename?: 'Parlementair'; label: string }
          | { __typename?: 'Tools'; label: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string }
          | { __typename?: 'ToolsLaw'; label: string }
          | { __typename?: 'ToolsNews'; label: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string }
          | { __typename?: 'Versies'; label: string }
          | { __typename?: 'Wenr'; label: string }
          | { __typename?: 'WetsTab'; label: string }
        >;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type LawBaseFragment = {
  __typename?: 'Law';
  id: string;
  isExpired: boolean;
  title: string;
  latestVersionOfLaw?: string | null;
  currentBesluiten: string;
  validityStatus?: {
    __typename?: 'ValidityStatus';
    start?: string | null;
    end?: string | null;
    status?: string | null;
  } | null;
  previousBesluiten: Array<{
    __typename?: 'BesluitenVersion';
    kenmerk: string;
    besluitenLink: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  } | null>;
  nextBesluiten: Array<{
    __typename?: 'BesluitenVersion';
    kenmerk: string;
    besluitenLink: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  } | null>;
  previousVersions: Array<{
    __typename?: 'LawArticleVersion';
    date: string;
    textInPublication: string;
    bwbCanonicalLink?: {
      __typename?: 'contentForAboutId';
      link: string;
      linkStatus: string;
    } | null;
    publications: Array<{
      __typename?: 'PublicationsInOp';
      text: string;
      opLink?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
    }>;
    lines: Array<{
      __typename?: 'MultipleLawVersion';
      publications: Array<{
        __typename?: 'PublicationsInOp';
        text: string;
        opLink?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
      }>;
    }>;
  }>;
};

export type LawContentPartsFragment = {
  __typename?: 'Law';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        id: string;
        article?: string | null;
        isExpired: boolean;
        htmlBlob: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string }
          | { __typename?: 'CodeNews'; label: string }
          | { __typename?: 'CodeRechtspraak'; label: string }
          | { __typename?: 'Commentaar'; label: string }
          | { __typename?: 'Explanations'; label: string }
          | { __typename?: 'Jurisprudentie'; label: string }
          | { __typename?: 'Kern'; label: string }
          | { __typename?: 'LawJurisprudentie'; label: string }
          | { __typename?: 'LawNews'; label: string }
          | { __typename?: 'LawPracticeNotes'; label: string }
          | { __typename?: 'Literatuur'; label: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string }
          | { __typename?: 'PNJurisprudentie'; label: string }
          | { __typename?: 'PNLiteratuur'; label: string }
          | { __typename?: 'PNNews'; label: string }
          | { __typename?: 'PNTools'; label: string }
          | { __typename?: 'PNVerdieping'; label: string }
          | { __typename?: 'Parlementair'; label: string }
          | { __typename?: 'Tools'; label: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string }
          | { __typename?: 'ToolsLaw'; label: string }
          | { __typename?: 'ToolsNews'; label: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string }
          | { __typename?: 'Versies'; label: string }
          | { __typename?: 'Wenr'; label: string }
          | { __typename?: 'WetsTab'; label: string }
        >;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type LawContentPartsPrintFragment = {
  __typename?: 'Law';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        id: string;
        article?: string | null;
        isExpired: boolean;
        html: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type LawPrintFragment = {
  __typename?: 'Law';
  id: string;
  isExpired: boolean;
  title: string;
  latestVersionOfLaw?: string | null;
  currentBesluiten: string;
  validityStatus?: {
    __typename?: 'ValidityStatus';
    start?: string | null;
    end?: string | null;
    status?: string | null;
  } | null;
  previousBesluiten: Array<{
    __typename?: 'BesluitenVersion';
    kenmerk: string;
    besluitenLink: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  } | null>;
  nextBesluiten: Array<{
    __typename?: 'BesluitenVersion';
    kenmerk: string;
    besluitenLink: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  } | null>;
  previousVersions: Array<{
    __typename?: 'LawArticleVersion';
    date: string;
    textInPublication: string;
    bwbCanonicalLink?: {
      __typename?: 'contentForAboutId';
      link: string;
      linkStatus: string;
    } | null;
    publications: Array<{
      __typename?: 'PublicationsInOp';
      text: string;
      opLink?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
    }>;
    lines: Array<{
      __typename?: 'MultipleLawVersion';
      publications: Array<{
        __typename?: 'PublicationsInOp';
        text: string;
        opLink?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
      }>;
    }>;
  }>;
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        id: string;
        article?: string | null;
        isExpired: boolean;
        html: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type ArticlesFeedItemFragment = {
  __typename?: 'MagazineArticle';
  id: string;
  swsId: string;
  number: string;
  articleNumber?: string | null;
  title: string;
  abstract?: string | null;
  teaser: string;
  edition: string;
  publicationDate?: string | null;
  publicationIdentifier: string;
  publicationCommonTitle?: string | null;
  type: Array<string>;
  link?: {
    __typename?: 'ContentLink';
    primaryLink: { __typename?: 'Link'; isExternal: boolean; url: string };
    secondaryLink?: { __typename?: 'Link'; isExternal: boolean; url: string } | null;
  } | null;
  authors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  annotators: Array<{ __typename?: 'Author'; name: string }>;
  eclis: Array<{
    __typename?: 'EcliMetadata';
    identifier?: string | null;
    name?: string | null;
    caseDate?: string | null;
    publicationDate?: string | null;
    link?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  }>;
};

export type MagazineBaseFragment = {
  __typename?: 'GenMagazine';
  id: string;
  title: string;
  commonTitle: string;
  lastPublished?: string | null;
};

export type MagazineArticleFragment = {
  __typename?: 'MagazineArticle';
  footNotes?: string | null;
  year: string;
  number: string;
  publicationCommonTitle?: string | null;
  publicationIdentifier: string;
  edition: string;
  isOldPdf: boolean;
  isPdfEdition: boolean;
  abstract?: string | null;
  pdfOnly: boolean;
  pdfAttachment?: string | null;
  hasPdfRefs: boolean;
  isNews: boolean;
  keywords: Array<string>;
  id: string;
  swsId: string;
  articleNumber?: string | null;
  title: string;
  editionPageTitle: string;
  type: Array<string>;
  publicationDate?: string | null;
  references: Array<{
    __typename?: 'ReferenceItem';
    title: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }>;
  otherArticles?: Array<{
    __typename?: 'BasicMagazineArticle';
    id: string;
    title: string;
    teaser: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }> | null;
  prevNextArticle: {
    __typename?: 'PrevNextLinks';
    previous?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
    next?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  };
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
  link?: {
    __typename?: 'ContentLink';
    primaryLink: { __typename?: 'Link'; isExternal: boolean; url: string };
    secondaryLink?: { __typename?: 'Link'; isExternal: boolean; url: string } | null;
  } | null;
  magazineAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  annotators: Array<{ __typename?: 'Author'; name: string }>;
  eclis: Array<{
    __typename?: 'EcliMetadata';
    identifier?: string | null;
    name?: string | null;
    caseDate?: string | null;
    publicationDate?: string | null;
    link?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  }>;
};

export type MagazineArticleBaseFragment = {
  __typename?: 'MagazineArticle';
  year: string;
  number: string;
  publicationCommonTitle?: string | null;
  publicationIdentifier: string;
  edition: string;
  isOldPdf: boolean;
  isPdfEdition: boolean;
  abstract?: string | null;
  pdfOnly: boolean;
  pdfAttachment?: string | null;
  hasPdfRefs: boolean;
  isNews: boolean;
  keywords: Array<string>;
  id: string;
  swsId: string;
  articleNumber?: string | null;
  title: string;
  editionPageTitle: string;
  type: Array<string>;
  publicationDate?: string | null;
  references: Array<{
    __typename?: 'ReferenceItem';
    title: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }>;
  otherArticles?: Array<{
    __typename?: 'BasicMagazineArticle';
    id: string;
    title: string;
    teaser: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }> | null;
  prevNextArticle: {
    __typename?: 'PrevNextLinks';
    previous?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
    next?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  };
  link?: {
    __typename?: 'ContentLink';
    primaryLink: { __typename?: 'Link'; isExternal: boolean; url: string };
    secondaryLink?: { __typename?: 'Link'; isExternal: boolean; url: string } | null;
  } | null;
  magazineAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  annotators: Array<{ __typename?: 'Author'; name: string }>;
  eclis: Array<{
    __typename?: 'EcliMetadata';
    identifier?: string | null;
    name?: string | null;
    caseDate?: string | null;
    publicationDate?: string | null;
    link?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  }>;
};

export type MagazineArticleContentPartsFragment = {
  __typename?: 'MagazineArticle';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type MagazineArticleContentPartsPrintFragment = {
  __typename?: 'MagazineArticle';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type MagazineArticlePrintFragment = {
  __typename?: 'MagazineArticle';
  year: string;
  number: string;
  publicationCommonTitle?: string | null;
  publicationIdentifier: string;
  edition: string;
  isOldPdf: boolean;
  isPdfEdition: boolean;
  abstract?: string | null;
  pdfOnly: boolean;
  pdfAttachment?: string | null;
  hasPdfRefs: boolean;
  isNews: boolean;
  keywords: Array<string>;
  id: string;
  swsId: string;
  articleNumber?: string | null;
  title: string;
  editionPageTitle: string;
  type: Array<string>;
  publicationDate?: string | null;
  references: Array<{
    __typename?: 'ReferenceItem';
    title: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }>;
  otherArticles?: Array<{
    __typename?: 'BasicMagazineArticle';
    id: string;
    title: string;
    teaser: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }> | null;
  prevNextArticle: {
    __typename?: 'PrevNextLinks';
    previous?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
    next?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  };
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
  link?: {
    __typename?: 'ContentLink';
    primaryLink: { __typename?: 'Link'; isExternal: boolean; url: string };
    secondaryLink?: { __typename?: 'Link'; isExternal: boolean; url: string } | null;
  } | null;
  magazineAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  annotators: Array<{ __typename?: 'Author'; name: string }>;
  eclis: Array<{
    __typename?: 'EcliMetadata';
    identifier?: string | null;
    name?: string | null;
    caseDate?: string | null;
    publicationDate?: string | null;
    link?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  }>;
};

export type MagazineEditionFragment = {
  __typename?: 'MagazineEdition';
  id: string;
  number: string;
  date?: string | null;
};

export type MagazineEditionArticleFragment = {
  __typename?: 'MagazineArticle';
  id: string;
  swsId: string;
  articleNumber?: string | null;
  title: string;
  editionPageTitle: string;
  type: Array<string>;
  publicationDate?: string | null;
  link?: {
    __typename?: 'ContentLink';
    primaryLink: { __typename?: 'Link'; isExternal: boolean; url: string };
    secondaryLink?: { __typename?: 'Link'; isExternal: boolean; url: string } | null;
  } | null;
  magazineAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  annotators: Array<{ __typename?: 'Author'; name: string }>;
  eclis: Array<{
    __typename?: 'EcliMetadata';
    identifier?: string | null;
    name?: string | null;
    caseDate?: string | null;
    publicationDate?: string | null;
    link?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
  }>;
};

export type NewsFragment = {
  __typename?: 'News';
  footNotes?: string | null;
  id: string;
  title: string;
  year: string;
  publicationDate?: string | null;
  publicationIdentifier: string;
  publicationNumber?: string | null;
  publicationTitle?: string | null;
  section?: string | null;
  keywords: Array<string>;
  pdfAttachment?: string | null;
  newsAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    published: Array<{ __typename?: 'PublishedItem'; publicationNumber?: string | null }>;
    about: Array<{
      __typename?: 'AboutItem';
      altKey: Array<string>;
      type: Array<string>;
      identifier?: string | null;
      identifierLink?: {
        __typename?: 'contentForAboutId';
        link: string;
        linkStatus: string;
      } | null;
    }>;
  };
  references: Array<{
    __typename?: 'ReferenceItem';
    title: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }>;
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type NewsBaseFragment = {
  __typename?: 'News';
  id: string;
  title: string;
  year: string;
  publicationDate?: string | null;
  publicationIdentifier: string;
  publicationNumber?: string | null;
  publicationTitle?: string | null;
  section?: string | null;
  keywords: Array<string>;
  pdfAttachment?: string | null;
  newsAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    published: Array<{ __typename?: 'PublishedItem'; publicationNumber?: string | null }>;
    about: Array<{
      __typename?: 'AboutItem';
      altKey: Array<string>;
      type: Array<string>;
      identifier?: string | null;
      identifierLink?: {
        __typename?: 'contentForAboutId';
        link: string;
        linkStatus: string;
      } | null;
    }>;
  };
  references: Array<{
    __typename?: 'ReferenceItem';
    title: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }>;
};

export type NewsContentPartsFragment = {
  __typename?: 'News';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type NewsContentPartsPrintFragment = {
  __typename?: 'News';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type NewsFeedItemFragment = {
  __typename?: 'NewsFeedItem';
  identifier: string;
  title: string;
  publicationDate?: string | null;
  publicationIdentifier: string;
  publicationTitle: string;
  conciseCitation?: string | null;
  teaser: string;
};

export type NewsPrintFragment = {
  __typename?: 'News';
  id: string;
  title: string;
  year: string;
  publicationDate?: string | null;
  publicationIdentifier: string;
  publicationNumber?: string | null;
  publicationTitle?: string | null;
  section?: string | null;
  keywords: Array<string>;
  pdfAttachment?: string | null;
  newsAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    published: Array<{ __typename?: 'PublishedItem'; publicationNumber?: string | null }>;
    about: Array<{
      __typename?: 'AboutItem';
      altKey: Array<string>;
      type: Array<string>;
      identifier?: string | null;
      identifierLink?: {
        __typename?: 'contentForAboutId';
        link: string;
        linkStatus: string;
      } | null;
    }>;
  };
  references: Array<{
    __typename?: 'ReferenceItem';
    title: string;
    linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
  }>;
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type NewsPublisherFragment = {
  __typename?: 'NewsPublisher';
  identifier: string;
  title: string;
  lastUpdated: string;
  sections: Array<string>;
};

export type OpFragment = {
  __typename?: 'OP';
  id: string;
  title: string;
  caseNumber?: string | null;
  meetingYear?: string | null;
  subtitle: string;
  pdfAttachment?: string | null;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    resource: Array<{ __typename?: 'ResourceItem'; identifier: string; format: string }>;
  };
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type OpBaseFragment = {
  __typename?: 'OP';
  id: string;
  title: string;
  caseNumber?: string | null;
  meetingYear?: string | null;
  subtitle: string;
  pdfAttachment?: string | null;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    resource: Array<{ __typename?: 'ResourceItem'; identifier: string; format: string }>;
  };
};

export type OpContentPartsFragment = {
  __typename?: 'OP';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type OpContentPartsPrintFragment = {
  __typename?: 'OP';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type OpPrintFragment = {
  __typename?: 'OP';
  id: string;
  title: string;
  caseNumber?: string | null;
  meetingYear?: string | null;
  subtitle: string;
  pdfAttachment?: string | null;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    resource: Array<{ __typename?: 'ResourceItem'; identifier: string; format: string }>;
  };
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type OverviewFragment = {
  __typename?: 'Overview';
  footNotes?: string | null;
  id: string;
  title: string;
  isMagazineArticle: boolean;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    documentDate?: string | null;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
  overviewAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type OverviewBaseFragment = {
  __typename?: 'Overview';
  id: string;
  title: string;
  isMagazineArticle: boolean;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    documentDate?: string | null;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
  overviewAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
};

export type OverviewContentPartsFragment = {
  __typename?: 'Overview';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type OverviewContentPartsPrintFragment = {
  __typename?: 'Overview';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type OverviewPrintFragment = {
  __typename?: 'Overview';
  id: string;
  title: string;
  isMagazineArticle: boolean;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    documentDate?: string | null;
    published: Array<{
      __typename?: 'PublishedItem';
      publicationYear?: string | null;
      publicationName?: {
        __typename?: 'PublicationNameModel';
        identifier: string;
        title?: string | null;
      } | null;
    }>;
  };
  overviewAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type PracticeNoteFragment = {
  __typename?: 'PracticeNote';
  footNotes?: string | null;
  id: string;
  title: string;
  isLinkList: boolean;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    date: Array<{ __typename?: 'DateItem'; type: string; value: string }>;
  };
  practiceNoteAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  tabs: Array<
    | { __typename?: 'Besluiten'; label: string; icon: string }
    | { __typename?: 'CodeNews'; label: string; icon: string }
    | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
    | { __typename?: 'Commentaar'; label: string; icon: string }
    | { __typename?: 'Explanations'; label: string; icon: string }
    | { __typename?: 'Jurisprudentie'; label: string; icon: string }
    | { __typename?: 'Kern'; label: string; icon: string }
    | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
    | { __typename?: 'LawNews'; label: string; icon: string }
    | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
    | { __typename?: 'Literatuur'; label: string; icon: string }
    | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
    | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
    | { __typename?: 'PNLiteratuur'; label: string; icon: string }
    | { __typename?: 'PNNews'; label: string; icon: string }
    | { __typename?: 'PNTools'; label: string; icon: string }
    | { __typename?: 'PNVerdieping'; label: string; icon: string }
    | { __typename?: 'Parlementair'; label: string; icon: string }
    | { __typename?: 'Tools'; label: string; icon: string }
    | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
    | { __typename?: 'ToolsLaw'; label: string; icon: string }
    | { __typename?: 'ToolsNews'; label: string; icon: string }
    | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
    | { __typename?: 'Versies'; label: string; icon: string }
    | { __typename?: 'Wenr'; label: string; icon: string }
    | { __typename?: 'WetsTab'; label: string; icon: string }
  >;
  pnNavigationDrawerInfo?: {
    __typename?: 'PNNavigationDrawerInfo';
    id: string;
    subjectId: string;
    subjectName: string;
    tocId: string;
    title: string;
  } | null;
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type PracticeNoteBaseFragment = {
  __typename?: 'PracticeNote';
  id: string;
  title: string;
  isLinkList: boolean;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    date: Array<{ __typename?: 'DateItem'; type: string; value: string }>;
  };
  practiceNoteAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  tabs: Array<
    | { __typename?: 'Besluiten'; label: string; icon: string }
    | { __typename?: 'CodeNews'; label: string; icon: string }
    | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
    | { __typename?: 'Commentaar'; label: string; icon: string }
    | { __typename?: 'Explanations'; label: string; icon: string }
    | { __typename?: 'Jurisprudentie'; label: string; icon: string }
    | { __typename?: 'Kern'; label: string; icon: string }
    | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
    | { __typename?: 'LawNews'; label: string; icon: string }
    | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
    | { __typename?: 'Literatuur'; label: string; icon: string }
    | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
    | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
    | { __typename?: 'PNLiteratuur'; label: string; icon: string }
    | { __typename?: 'PNNews'; label: string; icon: string }
    | { __typename?: 'PNTools'; label: string; icon: string }
    | { __typename?: 'PNVerdieping'; label: string; icon: string }
    | { __typename?: 'Parlementair'; label: string; icon: string }
    | { __typename?: 'Tools'; label: string; icon: string }
    | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
    | { __typename?: 'ToolsLaw'; label: string; icon: string }
    | { __typename?: 'ToolsNews'; label: string; icon: string }
    | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
    | { __typename?: 'Versies'; label: string; icon: string }
    | { __typename?: 'Wenr'; label: string; icon: string }
    | { __typename?: 'WetsTab'; label: string; icon: string }
  >;
  pnNavigationDrawerInfo?: {
    __typename?: 'PNNavigationDrawerInfo';
    id: string;
    subjectId: string;
    subjectName: string;
    tocId: string;
    title: string;
  } | null;
};

export type PracticeNoteContentPartsFragment = {
  __typename?: 'PracticeNote';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type PracticeNoteContentPartsPrintFragment = {
  __typename?: 'PracticeNote';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type PracticeNotePrintFragment = {
  __typename?: 'PracticeNote';
  id: string;
  title: string;
  isLinkList: boolean;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    date: Array<{ __typename?: 'DateItem'; type: string; value: string }>;
  };
  practiceNoteAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  tabs: Array<
    | { __typename?: 'Besluiten'; label: string; icon: string }
    | { __typename?: 'CodeNews'; label: string; icon: string }
    | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
    | { __typename?: 'Commentaar'; label: string; icon: string }
    | { __typename?: 'Explanations'; label: string; icon: string }
    | { __typename?: 'Jurisprudentie'; label: string; icon: string }
    | { __typename?: 'Kern'; label: string; icon: string }
    | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
    | { __typename?: 'LawNews'; label: string; icon: string }
    | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
    | { __typename?: 'Literatuur'; label: string; icon: string }
    | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
    | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
    | { __typename?: 'PNLiteratuur'; label: string; icon: string }
    | { __typename?: 'PNNews'; label: string; icon: string }
    | { __typename?: 'PNTools'; label: string; icon: string }
    | { __typename?: 'PNVerdieping'; label: string; icon: string }
    | { __typename?: 'Parlementair'; label: string; icon: string }
    | { __typename?: 'Tools'; label: string; icon: string }
    | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
    | { __typename?: 'ToolsLaw'; label: string; icon: string }
    | { __typename?: 'ToolsNews'; label: string; icon: string }
    | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
    | { __typename?: 'Versies'; label: string; icon: string }
    | { __typename?: 'Wenr'; label: string; icon: string }
    | { __typename?: 'WetsTab'; label: string; icon: string }
  >;
  pnNavigationDrawerInfo?: {
    __typename?: 'PNNavigationDrawerInfo';
    id: string;
    subjectId: string;
    subjectName: string;
    tocId: string;
    title: string;
  } | null;
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type RulingFragment = {
  __typename?: 'Ruling';
  footNotes?: string | null;
  kenmerk: string;
  publicationDate?: string | null;
  status: string;
  isRulingPdf: boolean;
  pdfAttachment?: string | null;
  publicationTitle?: string | null;
  id: string;
  title: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    abstract?: string | null;
    documentDate?: string | null;
  };
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type RulingBaseFragment = {
  __typename?: 'Ruling';
  kenmerk: string;
  publicationDate?: string | null;
  status: string;
  isRulingPdf: boolean;
  pdfAttachment?: string | null;
  publicationTitle?: string | null;
  id: string;
  title: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    abstract?: string | null;
    documentDate?: string | null;
  };
};

export type RulingContentPartsFragment = {
  __typename?: 'Ruling';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'BookContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CaseLawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'CommentaryContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'EurlexContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'LawContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'NewsContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OPContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'OverviewContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'RulingContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
    | {
        __typename?: 'ToolContentPart';
        htmlBlob: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
      }
  >;
};

export type RulingContentPartsPrintFragment = {
  __typename?: 'Ruling';
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

export type RulingPrintFragment = {
  __typename?: 'Ruling';
  kenmerk: string;
  publicationDate?: string | null;
  status: string;
  isRulingPdf: boolean;
  pdfAttachment?: string | null;
  publicationTitle?: string | null;
  id: string;
  title: string;
  metadata: {
    __typename?: 'SwsCompactMetadata';
    abstract?: string | null;
    documentDate?: string | null;
  };
  contentParts: Array<
    | {
        __typename?: 'ArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'BookContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CaseLawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'CommentaryContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'EurlexContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'LawContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'MagazineArticleContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'NewsContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OPContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'OverviewContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'PracticeNoteContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'RulingContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
    | {
        __typename?: 'ToolContentPart';
        html: string;
        id: string;
        title: string;
        anchor: string;
        anchors: Array<string>;
        depth: number;
        isContentEmpty: boolean;
        footNotes: Array<{ __typename?: 'FootNote'; id: string; anchor: string; html: string }>;
      }
  >;
};

type TabBesluitenFragment = { __typename?: 'Besluiten'; label: string; icon: string };

type TabCodeNewsFragment = { __typename?: 'CodeNews'; label: string; icon: string };

type TabCodeRechtspraakFragment = { __typename?: 'CodeRechtspraak'; label: string; icon: string };

type TabCommentaarFragment = { __typename?: 'Commentaar'; label: string; icon: string };

type TabExplanationsFragment = { __typename?: 'Explanations'; label: string; icon: string };

type TabJurisprudentieFragment = { __typename?: 'Jurisprudentie'; label: string; icon: string };

type TabKernFragment = { __typename?: 'Kern'; label: string; icon: string };

type TabLawJurisprudentieFragment = {
  __typename?: 'LawJurisprudentie';
  label: string;
  icon: string;
};

type TabLawNewsFragment = { __typename?: 'LawNews'; label: string; icon: string };

type TabLawPracticeNotesFragment = { __typename?: 'LawPracticeNotes'; label: string; icon: string };

type TabLiteratuurFragment = { __typename?: 'Literatuur'; label: string; icon: string };

type TabPnGerelateerdeOnderwerpenFragment = {
  __typename?: 'PNGerelateerdeOnderwerpen';
  label: string;
  icon: string;
};

type TabPnJurisprudentieFragment = { __typename?: 'PNJurisprudentie'; label: string; icon: string };

type TabPnLiteratuurFragment = { __typename?: 'PNLiteratuur'; label: string; icon: string };

type TabPnNewsFragment = { __typename?: 'PNNews'; label: string; icon: string };

type TabPnToolsFragment = { __typename?: 'PNTools'; label: string; icon: string };

type TabPnVerdiepingFragment = { __typename?: 'PNVerdieping'; label: string; icon: string };

type TabParlementairFragment = { __typename?: 'Parlementair'; label: string; icon: string };

type TabToolsFragment = { __typename?: 'Tools'; label: string; icon: string };

type TabToolsJurisprudentieFragment = {
  __typename?: 'ToolsJurisprudentie';
  label: string;
  icon: string;
};

type TabToolsLawFragment = { __typename?: 'ToolsLaw'; label: string; icon: string };

type TabToolsNewsFragment = { __typename?: 'ToolsNews'; label: string; icon: string };

type TabToolsRelatedTopicsFragment = {
  __typename?: 'ToolsRelatedTopics';
  label: string;
  icon: string;
};

type TabVersiesFragment = { __typename?: 'Versies'; label: string; icon: string };

type TabWenrFragment = { __typename?: 'Wenr'; label: string; icon: string };

type TabWetsTabFragment = { __typename?: 'WetsTab'; label: string; icon: string };

export type TabFragment =
  | TabBesluitenFragment
  | TabCodeNewsFragment
  | TabCodeRechtspraakFragment
  | TabCommentaarFragment
  | TabExplanationsFragment
  | TabJurisprudentieFragment
  | TabKernFragment
  | TabLawJurisprudentieFragment
  | TabLawNewsFragment
  | TabLawPracticeNotesFragment
  | TabLiteratuurFragment
  | TabPnGerelateerdeOnderwerpenFragment
  | TabPnJurisprudentieFragment
  | TabPnLiteratuurFragment
  | TabPnNewsFragment
  | TabPnToolsFragment
  | TabPnVerdiepingFragment
  | TabParlementairFragment
  | TabToolsFragment
  | TabToolsJurisprudentieFragment
  | TabToolsLawFragment
  | TabToolsNewsFragment
  | TabToolsRelatedTopicsFragment
  | TabVersiesFragment
  | TabWenrFragment
  | TabWetsTabFragment;

export type ToolFragment = {
  __typename?: 'Tool';
  footNotes?: string | null;
  id: string;
  title: string;
  abstract?: string | null;
  toolAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  attachments: Array<{ __typename?: 'Attachment'; title: string; mimeType: string; blob: string }>;
  references: Array<{
    __typename?: 'ToolReference';
    title: string;
    href: string;
    toolType: string;
    toolID: string;
  }>;
  metadata: { __typename?: 'SwsCompactMetadata'; documentDate?: string | null };
  toolsNavigationDrawerInfo?: {
    __typename?: 'ToolsNavigationDrawerInfo';
    id: string;
    subjectId: string;
    subjectName: string;
    title: string;
    tocId: string;
  } | null;
  tabs: Array<
    | { __typename?: 'Besluiten'; label: string; icon: string }
    | { __typename?: 'CodeNews'; label: string; icon: string }
    | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
    | { __typename?: 'Commentaar'; label: string; icon: string }
    | { __typename?: 'Explanations'; label: string; icon: string }
    | { __typename?: 'Jurisprudentie'; label: string; icon: string }
    | { __typename?: 'Kern'; label: string; icon: string }
    | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
    | { __typename?: 'LawNews'; label: string; icon: string }
    | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
    | { __typename?: 'Literatuur'; label: string; icon: string }
    | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
    | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
    | { __typename?: 'PNLiteratuur'; label: string; icon: string }
    | { __typename?: 'PNNews'; label: string; icon: string }
    | { __typename?: 'PNTools'; label: string; icon: string }
    | { __typename?: 'PNVerdieping'; label: string; icon: string }
    | { __typename?: 'Parlementair'; label: string; icon: string }
    | { __typename?: 'Tools'; label: string; icon: string }
    | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
    | { __typename?: 'ToolsLaw'; label: string; icon: string }
    | { __typename?: 'ToolsNews'; label: string; icon: string }
    | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
    | { __typename?: 'Versies'; label: string; icon: string }
    | { __typename?: 'Wenr'; label: string; icon: string }
    | { __typename?: 'WetsTab'; label: string; icon: string }
  >;
};

export type ToolBaseFragment = {
  __typename?: 'Tool';
  id: string;
  title: string;
  abstract?: string | null;
  toolAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
  attachments: Array<{ __typename?: 'Attachment'; title: string; mimeType: string; blob: string }>;
  references: Array<{
    __typename?: 'ToolReference';
    title: string;
    href: string;
    toolType: string;
    toolID: string;
  }>;
  metadata: { __typename?: 'SwsCompactMetadata'; documentDate?: string | null };
  toolsNavigationDrawerInfo?: {
    __typename?: 'ToolsNavigationDrawerInfo';
    id: string;
    subjectId: string;
    subjectName: string;
    title: string;
    tocId: string;
  } | null;
  tabs: Array<
    | { __typename?: 'Besluiten'; label: string; icon: string }
    | { __typename?: 'CodeNews'; label: string; icon: string }
    | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
    | { __typename?: 'Commentaar'; label: string; icon: string }
    | { __typename?: 'Explanations'; label: string; icon: string }
    | { __typename?: 'Jurisprudentie'; label: string; icon: string }
    | { __typename?: 'Kern'; label: string; icon: string }
    | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
    | { __typename?: 'LawNews'; label: string; icon: string }
    | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
    | { __typename?: 'Literatuur'; label: string; icon: string }
    | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
    | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
    | { __typename?: 'PNLiteratuur'; label: string; icon: string }
    | { __typename?: 'PNNews'; label: string; icon: string }
    | { __typename?: 'PNTools'; label: string; icon: string }
    | { __typename?: 'PNVerdieping'; label: string; icon: string }
    | { __typename?: 'Parlementair'; label: string; icon: string }
    | { __typename?: 'Tools'; label: string; icon: string }
    | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
    | { __typename?: 'ToolsLaw'; label: string; icon: string }
    | { __typename?: 'ToolsNews'; label: string; icon: string }
    | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
    | { __typename?: 'Versies'; label: string; icon: string }
    | { __typename?: 'Wenr'; label: string; icon: string }
    | { __typename?: 'WetsTab'; label: string; icon: string }
  >;
};

export type InPageSearchQueryVariables = Exact<{
  documentKey: Scalars['String']['input'];
  query: QueryParameters;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type InPageSearchQuery = {
  __typename?: 'Query';
  inPageSearch?: Array<{
    __typename?: 'ContentPartHit';
    partId: string;
    totalResults: number;
  }> | null;
};

export type HistoryQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type HistoryQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article'; id: string; title: string }
    | { __typename?: 'Book'; id: string; title: string }
    | { __typename?: 'CaseLaw'; id: string; title: string }
    | {
        __typename?: 'Commentary';
        id: string;
        title: string;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | {
              __typename?: 'Parlementair';
              label: string;
              icon: string;
              dossiers: Array<{
                __typename?: 'ParliamentaryHistoryReference';
                id?: string | null;
                title: string;
                references: Array<{
                  __typename?: 'OpReference';
                  id?: string | null;
                  number?: string | null;
                  title?: string | null;
                  publicationDate?: string | null;
                  canonicalLink?: string | null;
                  linkStatus?: string | null;
                  pdfAttachment?: string | null;
                }>;
              }>;
            }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
      }
    | { __typename?: 'Eurlex'; id: string; title: string }
    | { __typename?: 'ExternalSource'; id: string; title: string }
    | { __typename?: 'Law'; id: string; title: string }
    | { __typename?: 'MagazineArticle'; id: string; title: string }
    | { __typename?: 'News'; id: string; title: string }
    | { __typename?: 'OP'; id: string; title: string }
    | { __typename?: 'Overview'; id: string; title: string }
    | { __typename?: 'PracticeNote'; id: string; title: string }
    | { __typename?: 'Ruling'; id: string; title: string }
    | { __typename?: 'Tool'; id: string; title: string }
    | null;
};

export type JurisprudenceQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  fromYear?: InputMaybe<Scalars['Int']['input']>;
  toYear?: InputMaybe<Scalars['Int']['input']>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type JurisprudenceQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'Book'; id: string }
    | { __typename?: 'CaseLaw'; id: string }
    | {
        __typename?: 'Commentary';
        id: string;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | {
              __typename?: 'Jurisprudentie';
              htmlBlob?: string | null;
              label: string;
              icon: string;
              references: Array<{
                __typename?: 'caseLawReference';
                title: string;
                swsLinkID?: string | null;
                canonicalLink?: string | null;
                linkStatus: string;
                caseNumber: string;
                caseDate: string;
                contributor: string;
                creator: string;
                ecli?: string | null;
                ecliCanonicalLink?: string | null;
                ecliLinkStatus: string;
                publication?: string | null;
                isInCassation: boolean;
              }>;
            }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
      }
    | { __typename?: 'Eurlex'; id: string }
    | { __typename?: 'ExternalSource'; id: string }
    | { __typename?: 'Law'; id: string }
    | { __typename?: 'MagazineArticle'; id: string }
    | { __typename?: 'News'; id: string }
    | { __typename?: 'OP'; id: string }
    | { __typename?: 'Overview'; id: string }
    | { __typename?: 'PracticeNote'; id: string }
    | { __typename?: 'Ruling'; id: string }
    | { __typename?: 'Tool'; id: string }
    | null;
};

export type LawArticleQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type LawArticleQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'Book'; id: string }
    | { __typename?: 'CaseLaw'; id: string }
    | {
        __typename?: 'Commentary';
        id: string;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | {
              __typename?: 'WetsTab';
              label: string;
              icon: string;
              laws: Array<{
                __typename?: 'WetsArtikel';
                lawTitle: string;
                lawIdentifier: string;
                articleHtmlBlob?: string | null;
                articleAnchor?: string | null;
                isExpired?: boolean | null;
                isMultipleArticle: boolean;
                isWholeLaw: boolean;
                lawValidityStart?: string | null;
                articleData: Array<{
                  __typename?: 'ArticleData';
                  title: string;
                  articleLink: string;
                }>;
              } | null>;
            }
        >;
      }
    | { __typename?: 'Eurlex'; id: string }
    | { __typename?: 'ExternalSource'; id: string }
    | { __typename?: 'Law'; id: string }
    | { __typename?: 'MagazineArticle'; id: string }
    | { __typename?: 'News'; id: string }
    | { __typename?: 'OP'; id: string }
    | { __typename?: 'Overview'; id: string }
    | { __typename?: 'PracticeNote'; id: string }
    | { __typename?: 'Ruling'; id: string }
    | { __typename?: 'Tool'; id: string }
    | null;
};

export type LegislationQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type LegislationQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'Book'; id: string }
    | { __typename?: 'CaseLaw'; id: string }
    | {
        __typename?: 'Commentary';
        id: string;
        tabs: Array<
          | {
              __typename?: 'Besluiten';
              label: string;
              icon: string;
              references: Array<{
                __typename?: 'BesluitReference';
                signatureDate?: string | null;
                validityDate?: string | null;
                title?: string | null;
                swsLinkID?: string | null;
                canonicalLink?: string | null;
                linkStatus: string;
                source?: string | null;
                sourceCanonicalLink?: string | null;
                sourceLinkStatus: string;
                kenmerk?: string | null;
                status: string;
              }>;
              rulingReferences: Array<{
                __typename?: 'RulingReference';
                identifier: string;
                title: string;
                canonicalLink: string;
                linkStatus: string;
                publicationDate?: string | null;
                kenmerk: string;
                status: string;
              }>;
            }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
      }
    | { __typename?: 'Eurlex'; id: string }
    | { __typename?: 'ExternalSource'; id: string }
    | { __typename?: 'Law'; id: string }
    | { __typename?: 'MagazineArticle'; id: string }
    | { __typename?: 'News'; id: string }
    | { __typename?: 'OP'; id: string }
    | { __typename?: 'Overview'; id: string }
    | { __typename?: 'PracticeNote'; id: string }
    | { __typename?: 'Ruling'; id: string }
    | { __typename?: 'Tool'; id: string }
    | null;
};

export type LiteratureQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type LiteratureQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'Book'; id: string }
    | { __typename?: 'CaseLaw'; id: string }
    | {
        __typename?: 'Commentary';
        id: string;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | {
              __typename?: 'Literatuur';
              htmlBlob?: string | null;
              label: string;
              icon: string;
              references: Array<{
                __typename?: 'LiteratureReference';
                title: string;
                swsLinkID?: string | null;
                canonicalLink?: string | null;
                publicationName?: string | null;
                publicationYear?: string | null;
                publicationNumber?: string | null;
                number?: string | null;
                publicationDate?: string | null;
                author?: string | null;
                source?: string | null;
                linkType: string;
                sourceUrl?: string | null;
                linkStatus: string;
              }>;
            }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
      }
    | { __typename?: 'Eurlex'; id: string }
    | { __typename?: 'ExternalSource'; id: string }
    | { __typename?: 'Law'; id: string }
    | { __typename?: 'MagazineArticle'; id: string }
    | { __typename?: 'News'; id: string }
    | { __typename?: 'OP'; id: string }
    | { __typename?: 'Overview'; id: string }
    | { __typename?: 'PracticeNote'; id: string }
    | { __typename?: 'Ruling'; id: string }
    | { __typename?: 'Tool'; id: string }
    | null;
};

export type CommentaryReferencesQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  parts?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type CommentaryReferencesQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article' }
    | { __typename?: 'Book' }
    | { __typename?: 'CaseLaw' }
    | { __typename?: 'Commentary' }
    | { __typename?: 'Eurlex' }
    | { __typename?: 'ExternalSource' }
    | {
        __typename?: 'Law';
        contentParts: Array<
          | { __typename?: 'ArticleContentPart' }
          | { __typename?: 'BookContentPart' }
          | { __typename?: 'CaseLawContentPart' }
          | { __typename?: 'CommentaryContentPart' }
          | { __typename?: 'EurlexContentPart' }
          | {
              __typename?: 'LawContentPart';
              id: string;
              tabs: Array<
                | { __typename?: 'Besluiten' }
                | { __typename?: 'CodeNews' }
                | { __typename?: 'CodeRechtspraak' }
                | {
                    __typename?: 'Commentaar';
                    label: string;
                    icon: string;
                    references: Array<{
                      __typename?: 'CommentaryReference';
                      displayTitle: string;
                      identifier?: string | null;
                      linkStatus: string;
                      teaser?: string | null;
                      sources: Array<string>;
                    }>;
                  }
                | { __typename?: 'Explanations' }
                | { __typename?: 'Jurisprudentie' }
                | { __typename?: 'Kern' }
                | { __typename?: 'LawJurisprudentie' }
                | { __typename?: 'LawNews' }
                | { __typename?: 'LawPracticeNotes' }
                | { __typename?: 'Literatuur' }
                | { __typename?: 'PNGerelateerdeOnderwerpen' }
                | { __typename?: 'PNJurisprudentie' }
                | { __typename?: 'PNLiteratuur' }
                | { __typename?: 'PNNews' }
                | { __typename?: 'PNTools' }
                | { __typename?: 'PNVerdieping' }
                | { __typename?: 'Parlementair' }
                | { __typename?: 'Tools' }
                | { __typename?: 'ToolsJurisprudentie' }
                | { __typename?: 'ToolsLaw' }
                | { __typename?: 'ToolsNews' }
                | { __typename?: 'ToolsRelatedTopics' }
                | { __typename?: 'Versies' }
                | { __typename?: 'Wenr' }
                | { __typename?: 'WetsTab' }
              >;
            }
          | { __typename?: 'MagazineArticleContentPart' }
          | { __typename?: 'NewsContentPart' }
          | { __typename?: 'OPContentPart' }
          | { __typename?: 'OverviewContentPart' }
          | { __typename?: 'PracticeNoteContentPart' }
          | { __typename?: 'RulingContentPart' }
          | { __typename?: 'ToolContentPart' }
        >;
      }
    | { __typename?: 'MagazineArticle' }
    | { __typename?: 'News' }
    | { __typename?: 'OP' }
    | { __typename?: 'Overview' }
    | { __typename?: 'PracticeNote' }
    | { __typename?: 'Ruling' }
    | { __typename?: 'Tool' }
    | null;
};

export type LawJurisprudentieQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  parts?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  article: Scalars['String']['input'];
}>;

export type LawJurisprudentieQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article' }
    | { __typename?: 'Book' }
    | { __typename?: 'CaseLaw' }
    | { __typename?: 'Commentary' }
    | { __typename?: 'Eurlex' }
    | { __typename?: 'ExternalSource' }
    | {
        __typename?: 'Law';
        contentParts: Array<
          | { __typename?: 'ArticleContentPart' }
          | { __typename?: 'BookContentPart' }
          | { __typename?: 'CaseLawContentPart' }
          | { __typename?: 'CommentaryContentPart' }
          | { __typename?: 'EurlexContentPart' }
          | {
              __typename?: 'LawContentPart';
              id: string;
              tabs: Array<
                | { __typename?: 'Besluiten' }
                | { __typename?: 'CodeNews' }
                | { __typename?: 'CodeRechtspraak' }
                | { __typename?: 'Commentaar' }
                | { __typename?: 'Explanations' }
                | { __typename?: 'Jurisprudentie' }
                | { __typename?: 'Kern' }
                | {
                    __typename?: 'LawJurisprudentie';
                    label: string;
                    icon: string;
                    references: Array<{
                      __typename?: 'LawJurisprudentieReference';
                      identifier: string;
                      title: string;
                      documentDate: string;
                      caseDate: string;
                      caseNumber: string;
                      creator: string;
                      ecli?: string | null;
                      ecliCanonicalLink?: string | null;
                      ecliLinkStatus: string;
                      reference?: {
                        __typename?: 'ReferenceItem';
                        title: string;
                        linkItem: {
                          __typename?: 'contentForAboutId';
                          link: string;
                          linkStatus: string;
                        };
                      } | null;
                    }>;
                  }
                | { __typename?: 'LawNews' }
                | { __typename?: 'LawPracticeNotes' }
                | { __typename?: 'Literatuur' }
                | { __typename?: 'PNGerelateerdeOnderwerpen' }
                | { __typename?: 'PNJurisprudentie' }
                | { __typename?: 'PNLiteratuur' }
                | { __typename?: 'PNNews' }
                | { __typename?: 'PNTools' }
                | { __typename?: 'PNVerdieping' }
                | { __typename?: 'Parlementair' }
                | { __typename?: 'Tools' }
                | { __typename?: 'ToolsJurisprudentie' }
                | { __typename?: 'ToolsLaw' }
                | { __typename?: 'ToolsNews' }
                | { __typename?: 'ToolsRelatedTopics' }
                | { __typename?: 'Versies' }
                | { __typename?: 'Wenr' }
                | { __typename?: 'WetsTab' }
              >;
            }
          | { __typename?: 'MagazineArticleContentPart' }
          | { __typename?: 'NewsContentPart' }
          | { __typename?: 'OPContentPart' }
          | { __typename?: 'OverviewContentPart' }
          | { __typename?: 'PracticeNoteContentPart' }
          | { __typename?: 'RulingContentPart' }
          | { __typename?: 'ToolContentPart' }
        >;
      }
    | { __typename?: 'MagazineArticle' }
    | { __typename?: 'News' }
    | { __typename?: 'OP' }
    | { __typename?: 'Overview' }
    | { __typename?: 'PracticeNote' }
    | { __typename?: 'Ruling' }
    | { __typename?: 'Tool' }
    | null;
};

export type LawNewsQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  parts?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  article: Scalars['String']['input'];
}>;

export type LawNewsQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article' }
    | { __typename?: 'Book' }
    | { __typename?: 'CaseLaw' }
    | { __typename?: 'Commentary' }
    | { __typename?: 'Eurlex' }
    | { __typename?: 'ExternalSource' }
    | {
        __typename?: 'Law';
        contentParts: Array<
          | { __typename?: 'ArticleContentPart' }
          | { __typename?: 'BookContentPart' }
          | { __typename?: 'CaseLawContentPart' }
          | { __typename?: 'CommentaryContentPart' }
          | { __typename?: 'EurlexContentPart' }
          | {
              __typename?: 'LawContentPart';
              id: string;
              tabs: Array<
                | { __typename?: 'Besluiten' }
                | { __typename?: 'CodeNews' }
                | { __typename?: 'CodeRechtspraak' }
                | { __typename?: 'Commentaar' }
                | { __typename?: 'Explanations' }
                | { __typename?: 'Jurisprudentie' }
                | { __typename?: 'Kern' }
                | { __typename?: 'LawJurisprudentie' }
                | {
                    __typename?: 'LawNews';
                    label: string;
                    icon: string;
                    references: Array<{
                      __typename?: 'LawNewsReference';
                      identifier: string;
                      title: string;
                      newsType: string;
                      documentDate: string;
                      reference?: {
                        __typename?: 'ReferenceItem';
                        title: string;
                        linkItem: {
                          __typename?: 'contentForAboutId';
                          link: string;
                          linkStatus: string;
                        };
                      } | null;
                    }>;
                  }
                | { __typename?: 'LawPracticeNotes' }
                | { __typename?: 'Literatuur' }
                | { __typename?: 'PNGerelateerdeOnderwerpen' }
                | { __typename?: 'PNJurisprudentie' }
                | { __typename?: 'PNLiteratuur' }
                | { __typename?: 'PNNews' }
                | { __typename?: 'PNTools' }
                | { __typename?: 'PNVerdieping' }
                | { __typename?: 'Parlementair' }
                | { __typename?: 'Tools' }
                | { __typename?: 'ToolsJurisprudentie' }
                | { __typename?: 'ToolsLaw' }
                | { __typename?: 'ToolsNews' }
                | { __typename?: 'ToolsRelatedTopics' }
                | { __typename?: 'Versies' }
                | { __typename?: 'Wenr' }
                | { __typename?: 'WetsTab' }
              >;
            }
          | { __typename?: 'MagazineArticleContentPart' }
          | { __typename?: 'NewsContentPart' }
          | { __typename?: 'OPContentPart' }
          | { __typename?: 'OverviewContentPart' }
          | { __typename?: 'PracticeNoteContentPart' }
          | { __typename?: 'RulingContentPart' }
          | { __typename?: 'ToolContentPart' }
        >;
      }
    | { __typename?: 'MagazineArticle' }
    | { __typename?: 'News' }
    | { __typename?: 'OP' }
    | { __typename?: 'Overview' }
    | { __typename?: 'PracticeNote' }
    | { __typename?: 'Ruling' }
    | { __typename?: 'Tool' }
    | null;
};

export type LawPracticeNotesQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  parts?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  article: Scalars['String']['input'];
}>;

export type LawPracticeNotesQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article' }
    | { __typename?: 'Book' }
    | { __typename?: 'CaseLaw' }
    | { __typename?: 'Commentary' }
    | { __typename?: 'Eurlex' }
    | { __typename?: 'ExternalSource' }
    | {
        __typename?: 'Law';
        contentParts: Array<
          | { __typename?: 'ArticleContentPart' }
          | { __typename?: 'BookContentPart' }
          | { __typename?: 'CaseLawContentPart' }
          | { __typename?: 'CommentaryContentPart' }
          | { __typename?: 'EurlexContentPart' }
          | {
              __typename?: 'LawContentPart';
              id: string;
              tabs: Array<
                | { __typename?: 'Besluiten' }
                | { __typename?: 'CodeNews' }
                | { __typename?: 'CodeRechtspraak' }
                | { __typename?: 'Commentaar' }
                | { __typename?: 'Explanations' }
                | { __typename?: 'Jurisprudentie' }
                | { __typename?: 'Kern' }
                | { __typename?: 'LawJurisprudentie' }
                | { __typename?: 'LawNews' }
                | {
                    __typename?: 'LawPracticeNotes';
                    label: string;
                    icon: string;
                    references: Array<{
                      __typename?: 'LawPracticeNoteReference';
                      identifier: string;
                      title: string;
                      teaser?: string | null;
                      documentDate: string;
                      authors: Array<string>;
                      reference?: {
                        __typename?: 'ReferenceItem';
                        title: string;
                        linkItem: {
                          __typename?: 'contentForAboutId';
                          link: string;
                          linkStatus: string;
                        };
                      } | null;
                    }>;
                  }
                | { __typename?: 'Literatuur' }
                | { __typename?: 'PNGerelateerdeOnderwerpen' }
                | { __typename?: 'PNJurisprudentie' }
                | { __typename?: 'PNLiteratuur' }
                | { __typename?: 'PNNews' }
                | { __typename?: 'PNTools' }
                | { __typename?: 'PNVerdieping' }
                | { __typename?: 'Parlementair' }
                | { __typename?: 'Tools' }
                | { __typename?: 'ToolsJurisprudentie' }
                | { __typename?: 'ToolsLaw' }
                | { __typename?: 'ToolsNews' }
                | { __typename?: 'ToolsRelatedTopics' }
                | { __typename?: 'Versies' }
                | { __typename?: 'Wenr' }
                | { __typename?: 'WetsTab' }
              >;
            }
          | { __typename?: 'MagazineArticleContentPart' }
          | { __typename?: 'NewsContentPart' }
          | { __typename?: 'OPContentPart' }
          | { __typename?: 'OverviewContentPart' }
          | { __typename?: 'PracticeNoteContentPart' }
          | { __typename?: 'RulingContentPart' }
          | { __typename?: 'ToolContentPart' }
        >;
      }
    | { __typename?: 'MagazineArticle' }
    | { __typename?: 'News' }
    | { __typename?: 'OP' }
    | { __typename?: 'Overview' }
    | { __typename?: 'PracticeNote' }
    | { __typename?: 'Ruling' }
    | { __typename?: 'Tool' }
    | null;
};

export type LawVersionsQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  parts?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type LawVersionsQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article' }
    | { __typename?: 'Book' }
    | { __typename?: 'CaseLaw' }
    | { __typename?: 'Commentary' }
    | { __typename?: 'Eurlex' }
    | { __typename?: 'ExternalSource' }
    | {
        __typename?: 'Law';
        contentParts: Array<
          | { __typename?: 'ArticleContentPart' }
          | { __typename?: 'BookContentPart' }
          | { __typename?: 'CaseLawContentPart' }
          | { __typename?: 'CommentaryContentPart' }
          | { __typename?: 'EurlexContentPart' }
          | {
              __typename?: 'LawContentPart';
              id: string;
              tabs: Array<
                | { __typename?: 'Besluiten' }
                | { __typename?: 'CodeNews' }
                | { __typename?: 'CodeRechtspraak' }
                | { __typename?: 'Commentaar' }
                | { __typename?: 'Explanations' }
                | { __typename?: 'Jurisprudentie' }
                | { __typename?: 'Kern' }
                | { __typename?: 'LawJurisprudentie' }
                | { __typename?: 'LawNews' }
                | { __typename?: 'LawPracticeNotes' }
                | { __typename?: 'Literatuur' }
                | { __typename?: 'PNGerelateerdeOnderwerpen' }
                | { __typename?: 'PNJurisprudentie' }
                | { __typename?: 'PNLiteratuur' }
                | { __typename?: 'PNNews' }
                | { __typename?: 'PNTools' }
                | { __typename?: 'PNVerdieping' }
                | { __typename?: 'Parlementair' }
                | { __typename?: 'Tools' }
                | { __typename?: 'ToolsJurisprudentie' }
                | { __typename?: 'ToolsLaw' }
                | { __typename?: 'ToolsNews' }
                | { __typename?: 'ToolsRelatedTopics' }
                | {
                    __typename?: 'Versies';
                    label: string;
                    icon: string;
                    versions: Array<{
                      __typename?: 'LawArticleVersion';
                      date: string;
                      dateRetroactive: string;
                      dossierNumber: string;
                      publications: Array<{
                        __typename?: 'PublicationsInOp';
                        text: string;
                        opLink?: {
                          __typename?: 'contentForAboutId';
                          link: string;
                          linkStatus: string;
                        } | null;
                      }>;
                      bwbCanonicalLink?: {
                        __typename?: 'contentForAboutId';
                        link: string;
                        linkStatus: string;
                      } | null;
                    }>;
                  }
                | { __typename?: 'Wenr' }
                | { __typename?: 'WetsTab' }
              >;
            }
          | { __typename?: 'MagazineArticleContentPart' }
          | { __typename?: 'NewsContentPart' }
          | { __typename?: 'OPContentPart' }
          | { __typename?: 'OverviewContentPart' }
          | { __typename?: 'PracticeNoteContentPart' }
          | { __typename?: 'RulingContentPart' }
          | { __typename?: 'ToolContentPart' }
        >;
      }
    | { __typename?: 'MagazineArticle' }
    | { __typename?: 'News' }
    | { __typename?: 'OP' }
    | { __typename?: 'Overview' }
    | { __typename?: 'PracticeNote' }
    | { __typename?: 'Ruling' }
    | { __typename?: 'Tool' }
    | null;
};

export type MagazineQueryVariables = Exact<{
  magazineId: Scalars['String']['input'];
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  baseUrl: Scalars['String']['input'];
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type MagazineQuery = {
  __typename?: 'Query';
  magazines: Array<{
    __typename?: 'GenMagazine';
    id: string;
    title: string;
    commonTitle: string;
    lastPublished?: string | null;
    legislativeOverview?: {
      __typename?: 'LegislativeOverview';
      identifier: string;
      editorialDate: string;
      link: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
    } | null;
  }>;
};

export type MagazineEditionQueryVariables = Exact<{
  magazineId: Scalars['String']['input'];
  editionId: Scalars['String']['input'];
  magazineUrl?: InputMaybe<Scalars['String']['input']>;
  section?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  type?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  shouldFetchEditionPDF?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type MagazineEditionQuery = {
  __typename?: 'Query';
  magazines: Array<{
    __typename?: 'GenMagazine';
    id: string;
    title: string;
    commonTitle: string;
    lastPublished?: string | null;
    legislativeOverview?: {
      __typename?: 'LegislativeOverview';
      identifier: string;
      editorialDate: string;
      link: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
    } | null;
    editions: Array<{
      __typename?: 'MagazineEdition';
      title: string;
      sections?: Array<string> | null;
      types?: Array<string> | null;
      shortTitle: string;
      editionPDF?: string | null;
      id: string;
      number: string;
      date?: string | null;
      prevNextEdition: {
        __typename?: 'PrevNextLinks';
        next?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
        previous?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
      };
      articles?: Array<{
        __typename?: 'MagazineArticle';
        teaser: string;
        abstract?: string | null;
        id: string;
        swsId: string;
        articleNumber?: string | null;
        title: string;
        editionPageTitle: string;
        type: Array<string>;
        publicationDate?: string | null;
        link?: {
          __typename?: 'ContentLink';
          primaryLink: { __typename?: 'Link'; isExternal: boolean; url: string };
          secondaryLink?: { __typename?: 'Link'; isExternal: boolean; url: string } | null;
        } | null;
        magazineAuthors: Array<{ __typename?: 'Author'; name: string; function: string }>;
        annotators: Array<{ __typename?: 'Author'; name: string }>;
        eclis: Array<{
          __typename?: 'EcliMetadata';
          identifier?: string | null;
          name?: string | null;
          caseDate?: string | null;
          publicationDate?: string | null;
          link?: { __typename?: 'contentForAboutId'; link: string; linkStatus: string } | null;
        }>;
      }> | null;
    }>;
  }>;
};

export type PnVerdiepingenQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type PnVerdiepingenQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'Book'; id: string }
    | { __typename?: 'CaseLaw'; id: string }
    | { __typename?: 'Commentary'; id: string }
    | { __typename?: 'Eurlex'; id: string }
    | { __typename?: 'ExternalSource'; id: string }
    | { __typename?: 'Law'; id: string }
    | { __typename?: 'MagazineArticle'; id: string }
    | { __typename?: 'News'; id: string }
    | { __typename?: 'OP'; id: string }
    | { __typename?: 'Overview'; id: string }
    | {
        __typename?: 'PracticeNote';
        id: string;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | {
              __typename?: 'PNVerdieping';
              label: string;
              icon: string;
              references: Array<{
                __typename?: 'PNVerdiepingReference';
                documentDate: string;
                creators: Array<string>;
                reference?: {
                  __typename?: 'ReferenceItem';
                  title: string;
                  linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
                } | null;
              }>;
            }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
      }
    | { __typename?: 'Ruling'; id: string }
    | { __typename?: 'Tool'; id: string }
    | null;
};

export type PnJurisprudenceQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type PnJurisprudenceQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'Book'; id: string }
    | { __typename?: 'CaseLaw'; id: string }
    | { __typename?: 'Commentary'; id: string }
    | { __typename?: 'Eurlex'; id: string }
    | { __typename?: 'ExternalSource'; id: string }
    | { __typename?: 'Law'; id: string }
    | { __typename?: 'MagazineArticle'; id: string }
    | { __typename?: 'News'; id: string }
    | { __typename?: 'OP'; id: string }
    | { __typename?: 'Overview'; id: string }
    | {
        __typename?: 'PracticeNote';
        id: string;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | {
              __typename?: 'PNJurisprudentie';
              label: string;
              icon: string;
              references: Array<{
                __typename?: 'pnCaseLawReference';
                caseDate: string;
                creator: string;
                title: string;
                abstract: string;
                canonicalLink?: string | null;
                linkStatus: string;
                publication: string;
                ecliReferences: Array<{
                  __typename?: 'ReferenceItem';
                  title: string;
                  linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
                }>;
              }>;
            }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
      }
    | { __typename?: 'Ruling'; id: string }
    | { __typename?: 'Tool'; id: string }
    | null;
};

export type PnLawAndRegulationsQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type PnLawAndRegulationsQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'Book'; id: string }
    | { __typename?: 'CaseLaw'; id: string }
    | { __typename?: 'Commentary'; id: string }
    | { __typename?: 'Eurlex'; id: string }
    | { __typename?: 'ExternalSource'; id: string }
    | { __typename?: 'Law'; id: string }
    | { __typename?: 'MagazineArticle'; id: string }
    | { __typename?: 'News'; id: string }
    | { __typename?: 'OP'; id: string }
    | { __typename?: 'Overview'; id: string }
    | {
        __typename?: 'PracticeNote';
        id: string;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | {
              __typename?: 'Wenr';
              label: string;
              icon: string;
              references: Array<
                | {
                    __typename?: 'ExternalReference';
                    canonicalLink?: string | null;
                    title?: string | null;
                  }
                | {
                    __typename?: 'LawReference';
                    canonicalLink?: string | null;
                    linkStatus: string;
                    swsLinkID?: string | null;
                    title?: string | null;
                  }
              >;
              sectionReferences: Array<{
                __typename?: 'LawReferenceSection';
                title: string;
                references: Array<string>;
              }>;
            }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
      }
    | { __typename?: 'Ruling'; id: string }
    | { __typename?: 'Tool'; id: string }
    | null;
};

export type PnLiteratureQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type PnLiteratureQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'Book'; id: string }
    | { __typename?: 'CaseLaw'; id: string }
    | { __typename?: 'Commentary'; id: string }
    | { __typename?: 'Eurlex'; id: string }
    | { __typename?: 'ExternalSource'; id: string }
    | { __typename?: 'Law'; id: string }
    | { __typename?: 'MagazineArticle'; id: string }
    | { __typename?: 'News'; id: string }
    | { __typename?: 'OP'; id: string }
    | { __typename?: 'Overview'; id: string }
    | {
        __typename?: 'PracticeNote';
        id: string;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | {
              __typename?: 'PNLiteratuur';
              htmlBlob?: string | null;
              html: string;
              label: string;
              icon: string;
            }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
      }
    | { __typename?: 'Ruling'; id: string }
    | { __typename?: 'Tool'; id: string }
    | null;
};

export type PnNewsQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type PnNewsQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'Book'; id: string }
    | { __typename?: 'CaseLaw'; id: string }
    | { __typename?: 'Commentary'; id: string }
    | { __typename?: 'Eurlex'; id: string }
    | { __typename?: 'ExternalSource'; id: string }
    | { __typename?: 'Law'; id: string }
    | { __typename?: 'MagazineArticle'; id: string }
    | { __typename?: 'News'; id: string }
    | { __typename?: 'OP'; id: string }
    | { __typename?: 'Overview'; id: string }
    | {
        __typename?: 'PracticeNote';
        id: string;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | {
              __typename?: 'PNNews';
              htmlBlob?: string | null;
              html: string;
              label: string;
              icon: string;
              references: Array<{
                __typename?: 'pnNewsReference';
                title: string;
                documentDate: string;
                newsType: string;
                reference?: {
                  __typename?: 'ReferenceItem';
                  title: string;
                  linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
                } | null;
              }>;
            }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
      }
    | { __typename?: 'Ruling'; id: string }
    | { __typename?: 'Tool'; id: string }
    | null;
};

export type PnRelatedTopicsQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type PnRelatedTopicsQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'Book'; id: string }
    | { __typename?: 'CaseLaw'; id: string }
    | { __typename?: 'Commentary'; id: string }
    | { __typename?: 'Eurlex'; id: string }
    | { __typename?: 'ExternalSource'; id: string }
    | { __typename?: 'Law'; id: string }
    | { __typename?: 'MagazineArticle'; id: string }
    | { __typename?: 'News'; id: string }
    | { __typename?: 'OP'; id: string }
    | { __typename?: 'Overview'; id: string }
    | {
        __typename?: 'PracticeNote';
        id: string;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | {
              __typename?: 'PNGerelateerdeOnderwerpen';
              label: string;
              icon: string;
              references: Array<{
                __typename?: 'pnRelatedTopicReference';
                documentDate: string;
                creators: Array<string>;
                abstract: string;
                reference?: {
                  __typename?: 'ReferenceItem';
                  title: string;
                  linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
                } | null;
              }>;
            }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
      }
    | { __typename?: 'Ruling'; id: string }
    | { __typename?: 'Tool'; id: string }
    | null;
};

export type PnToolsQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type PnToolsQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'Book'; id: string }
    | { __typename?: 'CaseLaw'; id: string }
    | { __typename?: 'Commentary'; id: string }
    | { __typename?: 'Eurlex'; id: string }
    | { __typename?: 'ExternalSource'; id: string }
    | { __typename?: 'Law'; id: string }
    | { __typename?: 'MagazineArticle'; id: string }
    | { __typename?: 'News'; id: string }
    | { __typename?: 'OP'; id: string }
    | { __typename?: 'Overview'; id: string }
    | {
        __typename?: 'PracticeNote';
        id: string;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | {
              __typename?: 'PNTools';
              label: string;
              icon: string;
              references: Array<{
                __typename?: 'pnToolsReference';
                editorialDate: string;
                creator: string;
                reference?: {
                  __typename?: 'ReferenceItem';
                  title: string;
                  linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
                } | null;
              }>;
            }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
      }
    | { __typename?: 'Ruling'; id: string }
    | { __typename?: 'Tool'; id: string }
    | null;
};

export type ToolJurisprudenceQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type ToolJurisprudenceQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'Book'; id: string }
    | { __typename?: 'CaseLaw'; id: string }
    | { __typename?: 'Commentary'; id: string }
    | { __typename?: 'Eurlex'; id: string }
    | { __typename?: 'ExternalSource'; id: string }
    | { __typename?: 'Law'; id: string }
    | { __typename?: 'MagazineArticle'; id: string }
    | { __typename?: 'News'; id: string }
    | { __typename?: 'OP'; id: string }
    | { __typename?: 'Overview'; id: string }
    | { __typename?: 'PracticeNote'; id: string }
    | { __typename?: 'Ruling'; id: string }
    | {
        __typename?: 'Tool';
        id: string;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | {
              __typename?: 'ToolsJurisprudentie';
              htmlBlob?: string | null;
              html: string;
              label: string;
              icon: string;
            }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
      }
    | null;
};

export type ToolsLawQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type ToolsLawQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'Book'; id: string }
    | { __typename?: 'CaseLaw'; id: string }
    | { __typename?: 'Commentary'; id: string }
    | { __typename?: 'Eurlex'; id: string }
    | { __typename?: 'ExternalSource'; id: string }
    | { __typename?: 'Law'; id: string }
    | { __typename?: 'MagazineArticle'; id: string }
    | { __typename?: 'News'; id: string }
    | { __typename?: 'OP'; id: string }
    | { __typename?: 'Overview'; id: string }
    | { __typename?: 'PracticeNote'; id: string }
    | { __typename?: 'Ruling'; id: string }
    | {
        __typename?: 'Tool';
        id: string;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | {
              __typename?: 'ToolsLaw';
              htmlBlob?: string | null;
              html: string;
              label: string;
              icon: string;
            }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
      }
    | null;
};

export type ToolNewsQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type ToolNewsQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'Book'; id: string }
    | { __typename?: 'CaseLaw'; id: string }
    | { __typename?: 'Commentary'; id: string }
    | { __typename?: 'Eurlex'; id: string }
    | { __typename?: 'ExternalSource'; id: string }
    | { __typename?: 'Law'; id: string }
    | { __typename?: 'MagazineArticle'; id: string }
    | { __typename?: 'News'; id: string }
    | { __typename?: 'OP'; id: string }
    | { __typename?: 'Overview'; id: string }
    | { __typename?: 'PracticeNote'; id: string }
    | { __typename?: 'Ruling'; id: string }
    | {
        __typename?: 'Tool';
        id: string;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | {
              __typename?: 'ToolsNews';
              label: string;
              icon: string;
              references: Array<{
                __typename?: 'pnNewsReference';
                title: string;
                documentDate: string;
                newsType: string;
                reference?: {
                  __typename?: 'ReferenceItem';
                  title: string;
                  linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
                } | null;
              }>;
            }
          | { __typename?: 'ToolsRelatedTopics'; label: string; icon: string }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
      }
    | null;
};

export type ToolsRelatedTopicsQueryVariables = Exact<{
  swsId: Scalars['String']['input'];
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  applicationKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type ToolsRelatedTopicsQuery = {
  __typename?: 'Query';
  content?:
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'Book'; id: string }
    | { __typename?: 'CaseLaw'; id: string }
    | { __typename?: 'Commentary'; id: string }
    | { __typename?: 'Eurlex'; id: string }
    | { __typename?: 'ExternalSource'; id: string }
    | { __typename?: 'Law'; id: string }
    | { __typename?: 'MagazineArticle'; id: string }
    | { __typename?: 'News'; id: string }
    | { __typename?: 'OP'; id: string }
    | { __typename?: 'Overview'; id: string }
    | { __typename?: 'PracticeNote'; id: string }
    | { __typename?: 'Ruling'; id: string }
    | {
        __typename?: 'Tool';
        id: string;
        tabs: Array<
          | { __typename?: 'Besluiten'; label: string; icon: string }
          | { __typename?: 'CodeNews'; label: string; icon: string }
          | { __typename?: 'CodeRechtspraak'; label: string; icon: string }
          | { __typename?: 'Commentaar'; label: string; icon: string }
          | { __typename?: 'Explanations'; label: string; icon: string }
          | { __typename?: 'Jurisprudentie'; label: string; icon: string }
          | { __typename?: 'Kern'; label: string; icon: string }
          | { __typename?: 'LawJurisprudentie'; label: string; icon: string }
          | { __typename?: 'LawNews'; label: string; icon: string }
          | { __typename?: 'LawPracticeNotes'; label: string; icon: string }
          | { __typename?: 'Literatuur'; label: string; icon: string }
          | { __typename?: 'PNGerelateerdeOnderwerpen'; label: string; icon: string }
          | { __typename?: 'PNJurisprudentie'; label: string; icon: string }
          | { __typename?: 'PNLiteratuur'; label: string; icon: string }
          | { __typename?: 'PNNews'; label: string; icon: string }
          | { __typename?: 'PNTools'; label: string; icon: string }
          | { __typename?: 'PNVerdieping'; label: string; icon: string }
          | { __typename?: 'Parlementair'; label: string; icon: string }
          | { __typename?: 'Tools'; label: string; icon: string }
          | { __typename?: 'ToolsJurisprudentie'; label: string; icon: string }
          | { __typename?: 'ToolsLaw'; label: string; icon: string }
          | { __typename?: 'ToolsNews'; label: string; icon: string }
          | {
              __typename?: 'ToolsRelatedTopics';
              label: string;
              icon: string;
              references: Array<{
                __typename?: 'pnRelatedTopicReference';
                documentDate: string;
                creators: Array<string>;
                abstract: string;
                reference?: {
                  __typename?: 'ReferenceItem';
                  title: string;
                  linkItem: { __typename?: 'contentForAboutId'; link: string; linkStatus: string };
                } | null;
              }>;
            }
          | { __typename?: 'Versies'; label: string; icon: string }
          | { __typename?: 'Wenr'; label: string; icon: string }
          | { __typename?: 'WetsTab'; label: string; icon: string }
        >;
      }
    | null;
};

export const CommentaryLawTocEntryFragmentDoc = gql`
  fragment CommentaryLawTocEntry on LawTocEntry {
    name
    ... on GenericLawTocEntry {
      id: anchor
      label: title
      subtitle
      suffix: articles
      canonicalLink
    }
    ... on OpschriftLawTocEntry {
      id: anchor
      label: name
      lawCanonicalLink
    }
    ... on ArticleLawTocEntry {
      id: anchor
      label: name
      to: link
      subtitle
      canonicalLink
    }
    ... on EntireLawTocEntry {
      id: anchor
      label: name
      to: link
      subtitle
      linkID
    }
  }
`;
export const LawSwitcherItemFragmentDoc = gql`
  fragment LawSwitcherItem on SubLevelTocEntry {
    ... on GenericTocEntry {
      id
      label: name
      title
      hasChildren
      depth
      parentId
    }
    ... on DocumentTocEntry {
      lawId: id
      label: name
      hasChildren
      depth
      parentId
    }
  }
`;
export const PnNavigationDrawerItemFragmentDoc = gql`
  fragment PnNavigationDrawerItem on SubLevelTocEntry {
    id
    label: title
    to: link
    ... on GenericTocEntry {
      hasChildren
      depth
    }
    ... on DocumentTocEntry {
      hasChildren
      depth
    }
  }
`;
export const GenericTocEntryFragmentDoc = gql`
  fragment GenericTocEntry on TocEntry {
    id
    anchor
    label: name
    parentId
    hasChildren
  }
`;
export const PracticeNoteTocEntryFragmentDoc = gql`
  fragment PracticeNoteTocEntry on PracticeNoteTocEntry {
    id
    anchor
    label: name
    parentId
    hasChildren
  }
`;
export const CaseLawTocEntryFragmentDoc = gql`
  fragment CaseLawTocEntry on CaseLawTocEntry {
    id
    anchor
    label: name
    parentId
    hasChildren
  }
`;
export const LawTocEntryFragmentDoc = gql`
  fragment LawTocEntry on LawTocEntry {
    label: name
    parentId
    ... on GenericLawTocEntry {
      id
      anchor
      hasChildren
      suffix: articles
    }
    ... on OpschriftLawTocEntry {
      id
      anchor
      hasChildren
    }
    ... on ArticleLawTocEntry {
      id
      anchor
      hasChildren
    }
  }
`;
export const BookTocEntryFragmentDoc = gql`
  fragment BookTocEntry on BookTocEntry {
    id
    label: name
    anchor
    parentId
    hasChildren
  }
`;
export const ArticleTocEntryFragmentDoc = gql`
  fragment ArticleTocEntry on ArticleTocEntry {
    id
    label: name
    anchor
    parentId
    hasChildren
  }
`;
export const ArticleBaseFragmentDoc = gql`
  fragment ArticleBase on Article {
    id
    title
    subtitle
    metadata {
      published {
        publicationYear
        publicationName {
          identifier
        }
        number
      }
      documentDate
    }
    articleAuthors {
      name
      function
    }
    isMagazineArticle
    pdfAttachment
    otherArticles(baseUrl: $baseUrl) {
      id
      title
      teaser(truncate: 200, showEllipsis: true)
      linkItem {
        link
        linkStatus
      }
    }
    prevNextArticle(baseUrl: $baseUrl) {
      previous {
        link
        linkStatus
      }
      next {
        link
        linkStatus
      }
    }
  }
`;
export const ContentPartFragmentDoc = gql`
  fragment ContentPart on ContentPart {
    id
    title
    anchor
    anchors
    depth
    isContentEmpty
  }
`;
export const ArticleContentPartsFragmentDoc = gql`
  fragment ArticleContentParts on Article {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      htmlBlob
    }
  }
  ${ContentPartFragmentDoc}
`;
export const ArticleFragmentDoc = gql`
  fragment Article on Article {
    ...ArticleBase
    ...ArticleContentParts
    footNotes(baseUrl: $baseUrl)
  }
  ${ArticleBaseFragmentDoc}
  ${ArticleContentPartsFragmentDoc}
`;
export const FootNoteFragmentDoc = gql`
  fragment FootNote on FootNote {
    id
    anchor
    html(id: $swsId, baseUrl: $baseUrl)
  }
`;
export const ArticleContentPartsPrintFragmentDoc = gql`
  fragment ArticleContentPartsPrint on Article {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      html
      footNotes {
        ...FootNote
      }
    }
  }
  ${ContentPartFragmentDoc}
  ${FootNoteFragmentDoc}
`;
export const ArticlePrintFragmentDoc = gql`
  fragment ArticlePrint on Article {
    ...ArticleBase
    ...ArticleContentPartsPrint
  }
  ${ArticleBaseFragmentDoc}
  ${ArticleContentPartsPrintFragmentDoc}
`;
export const BookBaseFragmentDoc = gql`
  fragment BookBase on Book {
    id
    title
    publicationDate
    editionNumber
    isbn
    pdfOnly
    pdfAttachment
    previousVersions(baseUrl: $baseUrl) {
      identifier
      title
      publicationDate
      publicationIdentifier
      identifierLink {
        link
        linkStatus
      }
    }
    bookAuthors: authors {
      name
      function
    }
  }
`;
export const BookContentPartsFragmentDoc = gql`
  fragment BookContentParts on Book {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      htmlBlob
    }
  }
  ${ContentPartFragmentDoc}
`;
export const BookFragmentDoc = gql`
  fragment Book on Book {
    ...BookBase
    ...BookContentParts
    footNotes(baseUrl: $baseUrl)
  }
  ${BookBaseFragmentDoc}
  ${BookContentPartsFragmentDoc}
`;
export const BookContentPartsPrintFragmentDoc = gql`
  fragment BookContentPartsPrint on Book {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      html
      footNotes {
        ...FootNote
      }
    }
  }
  ${ContentPartFragmentDoc}
  ${FootNoteFragmentDoc}
`;
export const BookPrintFragmentDoc = gql`
  fragment BookPrint on Book {
    ...BookBase
    ...BookContentPartsPrint
  }
  ${BookBaseFragmentDoc}
  ${BookContentPartsPrintFragmentDoc}
`;
export const CaseLawBaseFragmentDoc = gql`
  fragment CaseLawBase on CaseLaw {
    id
    title
    articleNumber
    caseDate
    publicationDate
    taxYear
    pdfAttachment
    hasMoonlit
    cassationCaseNumber
    metadata {
      creator {
        role
        name
        function
      }
      about {
        altKey
        caseNumber
        identifier
        identifierLink(baseUrl: $baseUrl, id: $swsId) {
          link
          linkStatus
        }
        creator {
          name
        }
      }
      relation {
        identifier
        type
        displayTitle(baseUrl: $baseUrl, resolveLinks: true)
      }
      references {
        displayTitle
        identifier
        identifierLink(baseUrl: $baseUrl, id: $swsId) {
          link
          linkStatus
        }
      }
    }
    oldEcliPdf {
      title
      linkItem {
        link
        linkStatus
      }
    }
    isMagazineArticle
    otherArticles(baseUrl: $baseUrl) {
      id
      title
      teaser(truncate: 200, showEllipsis: true)
      linkItem {
        link
        linkStatus
      }
    }
    prevNextArticle(baseUrl: $baseUrl) {
      previous {
        link
        linkStatus
      }
      next {
        link
        linkStatus
      }
    }
  }
`;
export const CaseLawContentPartsFragmentDoc = gql`
  fragment CaseLawContentParts on CaseLaw {
    contentParts(baseUrl: $baseUrl, codeBaseUrl: $codeBaseUrl) {
      ...ContentPart
      htmlBlob
    }
  }
  ${ContentPartFragmentDoc}
`;
export const CaseLawFragmentDoc = gql`
  fragment CaseLaw on CaseLaw {
    ...CaseLawBase
    ...CaseLawContentParts
    footNotes(baseUrl: $baseUrl)
  }
  ${CaseLawBaseFragmentDoc}
  ${CaseLawContentPartsFragmentDoc}
`;
export const CaseLawContentPartsPrintFragmentDoc = gql`
  fragment CaseLawContentPartsPrint on CaseLaw {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      html
      footNotes {
        ...FootNote
      }
    }
  }
  ${ContentPartFragmentDoc}
  ${FootNoteFragmentDoc}
`;
export const CaseLawPrintFragmentDoc = gql`
  fragment CaseLawPrint on CaseLaw {
    ...CaseLawBase
    ...CaseLawContentPartsPrint
  }
  ${CaseLawBaseFragmentDoc}
  ${CaseLawContentPartsPrintFragmentDoc}
`;
export const TabFragmentDoc = gql`
  fragment Tab on Tab {
    label
    icon
  }
`;
export const CommentaryBaseFragmentDoc = gql`
  fragment CommentaryBase on Commentary {
    id
    title
    subtitle
    authors
    metadata {
      about {
        identifier
        title
        part {
          article
        }
      }
      documentDate
      creator {
        name
        shortInitials
        function
      }
    }
    thematicDrawer {
      id
      publicationIdentifier
    }
    tabs {
      ...Tab
    }
  }
  ${TabFragmentDoc}
`;
export const CommentaryContentPartsFragmentDoc = gql`
  fragment CommentaryContentParts on Commentary {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      htmlBlob
    }
  }
  ${ContentPartFragmentDoc}
`;
export const CommentaryFragmentDoc = gql`
  fragment Commentary on Commentary {
    ...CommentaryBase
    ...CommentaryContentParts
    footNotes(baseUrl: $baseUrl)
  }
  ${CommentaryBaseFragmentDoc}
  ${CommentaryContentPartsFragmentDoc}
`;
export const CommentaryContentPartsPrintFragmentDoc = gql`
  fragment CommentaryContentPartsPrint on Commentary {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      html
      footNotes {
        ...FootNote
      }
    }
  }
  ${ContentPartFragmentDoc}
  ${FootNoteFragmentDoc}
`;
export const CommentaryPrintFragmentDoc = gql`
  fragment CommentaryPrint on Commentary {
    ...CommentaryBase
    ...CommentaryContentPartsPrint
  }
  ${CommentaryBaseFragmentDoc}
  ${CommentaryContentPartsPrintFragmentDoc}
`;
export const ContentBaseFragmentDoc = gql`
  fragment ContentBase on Content {
    id
    title
    metadata {
      teaser
      altKey
      type
      audience
      creator {
        identifier
      }
      legalArea {
        title
        identifier
      }
      published {
        publicationYear
        publicationName {
          identifier
          title
        }
        number
        conciseCitation
      }
    }
    originalSourceUrl
  }
`;
export const DownloadFragmentDoc = gql`
  fragment Download on Download {
    __typename
    id
    name
    excerpt
    href
    documentDate
  }
`;
export const EurlexBaseFragmentDoc = gql`
  fragment EurlexBase on Eurlex {
    id
    title
    pdfAttachment
    translations {
      isCurrent
      language
      linkItem(baseUrl: $baseUrl) {
        link
        linkStatus
      }
    }
    metadata {
      instantie
      ecli
      about {
        caseDate
      }
    }
  }
`;
export const EurlexContentPartsFragmentDoc = gql`
  fragment EurlexContentParts on Eurlex {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      htmlBlob
    }
  }
  ${ContentPartFragmentDoc}
`;
export const EurlexFragmentDoc = gql`
  fragment Eurlex on Eurlex {
    ...EurlexBase
    ...EurlexContentParts
    footNotes(baseUrl: $baseUrl)
  }
  ${EurlexBaseFragmentDoc}
  ${EurlexContentPartsFragmentDoc}
`;
export const EurlexContentPartsPrintFragmentDoc = gql`
  fragment EurlexContentPartsPrint on Eurlex {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      html
      footNotes {
        ...FootNote
      }
    }
  }
  ${ContentPartFragmentDoc}
  ${FootNoteFragmentDoc}
`;
export const EurlexPrintFragmentDoc = gql`
  fragment EurlexPrint on Eurlex {
    ...EurlexBase
    ...EurlexContentPartsPrint
  }
  ${EurlexBaseFragmentDoc}
  ${EurlexContentPartsPrintFragmentDoc}
`;
export const ExternalSourceBaseFragmentDoc = gql`
  fragment ExternalSourceBase on ExternalSource {
    id
    html
    hasToc
    title
    originalSourceUrl
  }
`;
export const ExternalSourceFragmentDoc = gql`
  fragment ExternalSource on ExternalSource {
    ...ExternalSourceBase
  }
  ${ExternalSourceBaseFragmentDoc}
`;
export const ExternalSourcePrintFragmentDoc = gql`
  fragment ExternalSourcePrint on ExternalSource {
    ...ExternalSourceBase
  }
  ${ExternalSourceBaseFragmentDoc}
`;
export const LawBaseFragmentDoc = gql`
  fragment LawBase on Law {
    id
    isExpired
    title
    latestVersionOfLaw
    validityStatus {
      start
      end
      status
    }
    currentBesluiten
    previousBesluiten(documentKey: $swsId, baseUrl: $baseUrl) {
      kenmerk
      besluitenLink {
        link
        linkStatus
      }
    }
    nextBesluiten(documentKey: $swsId, baseUrl: $baseUrl) {
      kenmerk
      besluitenLink {
        link
        linkStatus
      }
    }
    previousVersions {
      date
      textInPublication
      bwbCanonicalLink(baseUrl: $baseUrl) {
        link
        linkStatus
      }
      publications {
        text
        opLink(baseUrl: $baseUrl) {
          link
          linkStatus
        }
      }
      lines {
        publications {
          text
          opLink(baseUrl: $baseUrl) {
            link
            linkStatus
          }
        }
      }
    }
  }
`;
export const LawContentPartsFragmentDoc = gql`
  fragment LawContentParts on Law {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      htmlBlob
      ... on LawContentPart {
        id
        article
        isExpired
        tabs {
          label
        }
      }
    }
  }
  ${ContentPartFragmentDoc}
`;
export const LawFragmentDoc = gql`
  fragment Law on Law {
    ...LawBase
    ...LawContentParts
    footNotes(baseUrl: $baseUrl)
  }
  ${LawBaseFragmentDoc}
  ${LawContentPartsFragmentDoc}
`;
export const LawContentPartsPrintFragmentDoc = gql`
  fragment LawContentPartsPrint on Law {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      footNotes {
        ...FootNote
      }
      html
      ... on LawContentPart {
        id
        article
        isExpired
      }
    }
  }
  ${ContentPartFragmentDoc}
  ${FootNoteFragmentDoc}
`;
export const LawPrintFragmentDoc = gql`
  fragment LawPrint on Law {
    ...LawBase
    ...LawContentPartsPrint
  }
  ${LawBaseFragmentDoc}
  ${LawContentPartsPrintFragmentDoc}
`;
export const ArticlesFeedItemFragmentDoc = gql`
  fragment ArticlesFeedItem on MagazineArticle {
    id
    swsId
    link {
      primaryLink {
        isExternal
        url
      }
      secondaryLink {
        isExternal
        url
      }
    }
    number
    articleNumber
    title
    abstract
    teaser
    edition
    publicationDate
    publicationIdentifier
    publicationCommonTitle
    type
    authors {
      name
      function
    }
    annotators {
      name
    }
    eclis {
      identifier
      name
      caseDate
      publicationDate
      link(baseUrl: $baseUrl) {
        link
        linkStatus
      }
    }
  }
`;
export const MagazineBaseFragmentDoc = gql`
  fragment MagazineBase on Magazine {
    id
    title
    commonTitle
    lastPublished
  }
`;
export const MagazineEditionArticleFragmentDoc = gql`
  fragment MagazineEditionArticle on MagazineArticle {
    id
    swsId
    link(baseUrl: $baseUrl) {
      primaryLink {
        isExternal
        url
      }
      secondaryLink {
        isExternal
        url
      }
    }
    articleNumber
    title
    editionPageTitle
    type
    publicationDate
    magazineAuthors: authors {
      name
      function
    }
    annotators {
      name
    }
    eclis {
      identifier
      name
      caseDate
      publicationDate
      link(baseUrl: $baseUrl) {
        link
        linkStatus
      }
    }
  }
`;
export const MagazineArticleBaseFragmentDoc = gql`
  fragment MagazineArticleBase on MagazineArticle {
    ...MagazineEditionArticle
    year
    number
    publicationCommonTitle
    publicationIdentifier
    edition
    isOldPdf
    isPdfEdition
    abstract
    pdfOnly
    pdfAttachment
    hasPdfRefs
    isNews
    keywords
    references(baseUrl: $baseUrl) {
      title
      linkItem {
        link
        linkStatus
      }
    }
    otherArticles(baseUrl: $baseUrl) {
      id
      title
      teaser(truncate: 200, showEllipsis: true)
      linkItem {
        link
        linkStatus
      }
    }
    prevNextArticle(baseUrl: $baseUrl) {
      previous {
        link
        linkStatus
      }
      next {
        link
        linkStatus
      }
    }
  }
  ${MagazineEditionArticleFragmentDoc}
`;
export const MagazineArticleContentPartsFragmentDoc = gql`
  fragment MagazineArticleContentParts on MagazineArticle {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      htmlBlob
    }
  }
  ${ContentPartFragmentDoc}
`;
export const MagazineArticleFragmentDoc = gql`
  fragment MagazineArticle on MagazineArticle {
    ...MagazineArticleBase
    ...MagazineArticleContentParts
    footNotes(baseUrl: $baseUrl)
  }
  ${MagazineArticleBaseFragmentDoc}
  ${MagazineArticleContentPartsFragmentDoc}
`;
export const MagazineArticleContentPartsPrintFragmentDoc = gql`
  fragment MagazineArticleContentPartsPrint on MagazineArticle {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      html
      footNotes {
        ...FootNote
      }
    }
  }
  ${ContentPartFragmentDoc}
  ${FootNoteFragmentDoc}
`;
export const MagazineArticlePrintFragmentDoc = gql`
  fragment MagazineArticlePrint on MagazineArticle {
    ...MagazineArticleBase
    ...MagazineArticleContentPartsPrint
  }
  ${MagazineArticleBaseFragmentDoc}
  ${MagazineArticleContentPartsPrintFragmentDoc}
`;
export const MagazineEditionFragmentDoc = gql`
  fragment MagazineEdition on Edition {
    id
    number
    date
  }
`;
export const NewsBaseFragmentDoc = gql`
  fragment NewsBase on News {
    id
    title
    year
    publicationDate
    publicationIdentifier
    publicationNumber
    publicationTitle
    section
    keywords
    newsAuthors: authors {
      name
      function
    }
    pdfAttachment
    metadata {
      published {
        publicationNumber
      }
      about {
        altKey
        type
        identifier
        identifierLink(baseUrl: $baseUrl, id: $swsId) {
          link
          linkStatus
        }
      }
    }
    references(baseUrl: $baseUrl) {
      title
      linkItem {
        link
        linkStatus
      }
    }
  }
`;
export const NewsContentPartsFragmentDoc = gql`
  fragment NewsContentParts on News {
    contentParts(baseUrl: $baseUrl, codeBaseUrl: $codeBaseUrl) {
      ...ContentPart
      htmlBlob
    }
  }
  ${ContentPartFragmentDoc}
`;
export const NewsFragmentDoc = gql`
  fragment News on News {
    ...NewsBase
    ...NewsContentParts
    footNotes(baseUrl: $baseUrl)
  }
  ${NewsBaseFragmentDoc}
  ${NewsContentPartsFragmentDoc}
`;
export const NewsFeedItemFragmentDoc = gql`
  fragment NewsFeedItem on NewsFeedItem {
    identifier
    title
    publicationDate
    publicationIdentifier
    publicationTitle
    conciseCitation
    teaser(truncate: 200, showEllipsis: true)
  }
`;
export const NewsContentPartsPrintFragmentDoc = gql`
  fragment NewsContentPartsPrint on News {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      html
      footNotes {
        ...FootNote
      }
    }
  }
  ${ContentPartFragmentDoc}
  ${FootNoteFragmentDoc}
`;
export const NewsPrintFragmentDoc = gql`
  fragment NewsPrint on News {
    ...NewsBase
    ...NewsContentPartsPrint
  }
  ${NewsBaseFragmentDoc}
  ${NewsContentPartsPrintFragmentDoc}
`;
export const NewsPublisherFragmentDoc = gql`
  fragment NewsPublisher on NewsPublisher {
    identifier
    title
    lastUpdated
    sections
  }
`;
export const OpBaseFragmentDoc = gql`
  fragment OPBase on OP {
    id
    title
    caseNumber
    meetingYear
    subtitle
    pdfAttachment
    metadata {
      resource {
        identifier
        format
      }
    }
  }
`;
export const OpContentPartsFragmentDoc = gql`
  fragment OPContentParts on OP {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      htmlBlob
    }
  }
  ${ContentPartFragmentDoc}
`;
export const OpFragmentDoc = gql`
  fragment OP on OP {
    ...OPBase
    ...OPContentParts
  }
  ${OpBaseFragmentDoc}
  ${OpContentPartsFragmentDoc}
`;
export const OpContentPartsPrintFragmentDoc = gql`
  fragment OPContentPartsPrint on OP {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      html
      footNotes {
        ...FootNote
      }
    }
  }
  ${ContentPartFragmentDoc}
  ${FootNoteFragmentDoc}
`;
export const OpPrintFragmentDoc = gql`
  fragment OPPrint on OP {
    ...OPBase
    ...OPContentPartsPrint
  }
  ${OpBaseFragmentDoc}
  ${OpContentPartsPrintFragmentDoc}
`;
export const OverviewBaseFragmentDoc = gql`
  fragment OverviewBase on Overview {
    id
    title
    metadata {
      published {
        publicationYear
        publicationName {
          identifier
          title
        }
      }
      documentDate
    }
    overviewAuthors {
      name
      function
    }
    isMagazineArticle
  }
`;
export const OverviewContentPartsFragmentDoc = gql`
  fragment OverviewContentParts on Overview {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      htmlBlob
    }
  }
  ${ContentPartFragmentDoc}
`;
export const OverviewFragmentDoc = gql`
  fragment Overview on Overview {
    ...OverviewBase
    ...OverviewContentParts
    footNotes(baseUrl: $baseUrl)
  }
  ${OverviewBaseFragmentDoc}
  ${OverviewContentPartsFragmentDoc}
`;
export const OverviewContentPartsPrintFragmentDoc = gql`
  fragment OverviewContentPartsPrint on Overview {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      html
      footNotes {
        ...FootNote
      }
    }
  }
  ${ContentPartFragmentDoc}
  ${FootNoteFragmentDoc}
`;
export const OverviewPrintFragmentDoc = gql`
  fragment OverviewPrint on Overview {
    ...OverviewBase
    ...OverviewContentPartsPrint
  }
  ${OverviewBaseFragmentDoc}
  ${OverviewContentPartsPrintFragmentDoc}
`;
export const PracticeNoteBaseFragmentDoc = gql`
  fragment PracticeNoteBase on PracticeNote {
    id
    title
    isLinkList
    metadata {
      date {
        type
        value
      }
    }
    practiceNoteAuthors: authors {
      name
      function
    }
    tabs {
      ...Tab
    }
    pnNavigationDrawerInfo(baseUrl: $baseUrl, id: $subId) {
      id
      subjectId
      subjectName
      tocId
      title
    }
  }
  ${TabFragmentDoc}
`;
export const PracticeNoteContentPartsFragmentDoc = gql`
  fragment PracticeNoteContentParts on PracticeNote {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      htmlBlob
    }
  }
  ${ContentPartFragmentDoc}
`;
export const PracticeNoteFragmentDoc = gql`
  fragment PracticeNote on PracticeNote {
    ...PracticeNoteBase
    ...PracticeNoteContentParts
    footNotes(baseUrl: $baseUrl)
  }
  ${PracticeNoteBaseFragmentDoc}
  ${PracticeNoteContentPartsFragmentDoc}
`;
export const PracticeNoteContentPartsPrintFragmentDoc = gql`
  fragment PracticeNoteContentPartsPrint on PracticeNote {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      html
      footNotes {
        ...FootNote
      }
    }
  }
  ${ContentPartFragmentDoc}
  ${FootNoteFragmentDoc}
`;
export const PracticeNotePrintFragmentDoc = gql`
  fragment PracticeNotePrint on PracticeNote {
    ...PracticeNoteBase
    ...PracticeNoteContentPartsPrint
  }
  ${PracticeNoteBaseFragmentDoc}
  ${PracticeNoteContentPartsPrintFragmentDoc}
`;
export const RulingBaseFragmentDoc = gql`
  fragment RulingBase on Ruling {
    id
    title
    metadata {
      abstract
      documentDate
    }
    ... on Ruling {
      kenmerk
      publicationDate
      status
      isRulingPdf
      pdfAttachment
      publicationTitle
    }
  }
`;
export const RulingContentPartsFragmentDoc = gql`
  fragment RulingContentParts on Ruling {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      htmlBlob
    }
  }
  ${ContentPartFragmentDoc}
`;
export const RulingFragmentDoc = gql`
  fragment Ruling on Ruling {
    ...RulingBase
    ...RulingContentParts
    footNotes(baseUrl: $baseUrl)
  }
  ${RulingBaseFragmentDoc}
  ${RulingContentPartsFragmentDoc}
`;
export const RulingContentPartsPrintFragmentDoc = gql`
  fragment RulingContentPartsPrint on Ruling {
    contentParts(baseUrl: $baseUrl) {
      ...ContentPart
      html
      footNotes {
        ...FootNote
      }
    }
  }
  ${ContentPartFragmentDoc}
  ${FootNoteFragmentDoc}
`;
export const RulingPrintFragmentDoc = gql`
  fragment RulingPrint on Ruling {
    ...RulingBase
    ...RulingContentPartsPrint
  }
  ${RulingBaseFragmentDoc}
  ${RulingContentPartsPrintFragmentDoc}
`;
export const ToolBaseFragmentDoc = gql`
  fragment ToolBase on Tool {
    id
    title
    abstract
    metadata {
      documentDate
    }
    toolsNavigationDrawerInfo(baseUrl: $baseUrl) {
      id
      subjectId
      subjectName
      title
      tocId
    }
    tabs {
      ...Tab
    }
    ... on Tool {
      toolAuthors: authors {
        name
        function
      }
      attachments {
        title
        mimeType
        blob
      }
      references {
        title
        href(baseUrl: $baseUrl)
        toolType
        toolID
      }
    }
  }
  ${TabFragmentDoc}
`;
export const ToolFragmentDoc = gql`
  fragment Tool on Tool {
    ...ToolBase
    footNotes(baseUrl: $baseUrl)
  }
  ${ToolBaseFragmentDoc}
`;
export const CommentaryLawTocDocument = gql`
  query CommentaryLawToc(
    $swsId: String!
    $baseUrl: String
    $labels: [String!]
    $key: [String!]
    $applicationKey: String
    $publicationIdentifiers: [String!]
  ) {
    lawCommentaries(
      documentKey: $swsId
      baseUrl: $baseUrl
      labels: $labels
      key: $key
      applicationKey: $applicationKey
      publicationIdentifiers: $publicationIdentifiers
    ) {
      name
      ... on TopLevelLawTocEntry {
        publications {
          id
          name
        }
      }
      children: entries {
        ...CommentaryLawTocEntry
        children: entries {
          ...CommentaryLawTocEntry
        }
      }
    }
  }
  ${CommentaryLawTocEntryFragmentDoc}
`;

/**
 * __useCommentaryLawTocQuery__
 *
 * To run a query within a React component, call `useCommentaryLawTocQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentaryLawTocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentaryLawTocQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      key: // value for 'key'
 *      applicationKey: // value for 'applicationKey'
 *      publicationIdentifiers: // value for 'publicationIdentifiers'
 *   },
 * });
 */
export function useCommentaryLawTocQuery(
  baseOptions: Apollo.QueryHookOptions<CommentaryLawTocQuery, CommentaryLawTocQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommentaryLawTocQuery, CommentaryLawTocQueryVariables>(
    CommentaryLawTocDocument,
    options,
  );
}
export function useCommentaryLawTocLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CommentaryLawTocQuery, CommentaryLawTocQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CommentaryLawTocQuery, CommentaryLawTocQueryVariables>(
    CommentaryLawTocDocument,
    options,
  );
}
export type CommentaryLawTocQueryHookResult = ReturnType<typeof useCommentaryLawTocQuery>;
export type CommentaryLawTocLazyQueryHookResult = ReturnType<typeof useCommentaryLawTocLazyQuery>;
export type CommentaryLawTocQueryResult = Apollo.QueryResult<
  CommentaryLawTocQuery,
  CommentaryLawTocQueryVariables
>;
export const LawSwitcherDocument = gql`
  query LawSwitcher($applicationKey: String, $publicationIdentifiers: [String!]) {
    lawSwitchDrawer(
      applicationKey: $applicationKey
      publicationIdentifiers: $publicationIdentifiers
    ) {
      ... on TopLevelTocEntry {
        label: name
        depth
        hasChildren
        children: entries {
          ... on GenericTocEntry {
            ...LawSwitcherItem
            children: entries {
              ... on DocumentTocEntry {
                ...LawSwitcherItem
              }
            }
          }
        }
      }
    }
  }
  ${LawSwitcherItemFragmentDoc}
`;

/**
 * __useLawSwitcherQuery__
 *
 * To run a query within a React component, call `useLawSwitcherQuery` and pass it any options that fit your needs.
 * When your component renders, `useLawSwitcherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLawSwitcherQuery({
 *   variables: {
 *      applicationKey: // value for 'applicationKey'
 *      publicationIdentifiers: // value for 'publicationIdentifiers'
 *   },
 * });
 */
export function useLawSwitcherQuery(
  baseOptions?: Apollo.QueryHookOptions<LawSwitcherQuery, LawSwitcherQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LawSwitcherQuery, LawSwitcherQueryVariables>(LawSwitcherDocument, options);
}
export function useLawSwitcherLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LawSwitcherQuery, LawSwitcherQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LawSwitcherQuery, LawSwitcherQueryVariables>(
    LawSwitcherDocument,
    options,
  );
}
export type LawSwitcherQueryHookResult = ReturnType<typeof useLawSwitcherQuery>;
export type LawSwitcherLazyQueryHookResult = ReturnType<typeof useLawSwitcherLazyQuery>;
export type LawSwitcherQueryResult = Apollo.QueryResult<
  LawSwitcherQuery,
  LawSwitcherQueryVariables
>;
export const DownloadsDocument = gql`
  query Downloads($applicationKey: String) {
    downloads(applicationKey: $applicationKey) {
      items {
        ...Download
        __typename
      }
      total
      __typename
    }
  }
  ${DownloadFragmentDoc}
`;

/**
 * __useDownloadsQuery__
 *
 * To run a query within a React component, call `useDownloadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadsQuery({
 *   variables: {
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useDownloadsQuery(
  baseOptions?: Apollo.QueryHookOptions<DownloadsQuery, DownloadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DownloadsQuery, DownloadsQueryVariables>(DownloadsDocument, options);
}
export function useDownloadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DownloadsQuery, DownloadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DownloadsQuery, DownloadsQueryVariables>(DownloadsDocument, options);
}
export type DownloadsQueryHookResult = ReturnType<typeof useDownloadsQuery>;
export type DownloadsLazyQueryHookResult = ReturnType<typeof useDownloadsLazyQuery>;
export type DownloadsQueryResult = Apollo.QueryResult<DownloadsQuery, DownloadsQueryVariables>;
export const EditionsDocument = gql`
  query Editions($days: Int, $size: Int, $labels: [String!], $applicationKey: String) {
    latestEditions(applicationKey: $applicationKey, labels: $labels, days: $days) {
      editions(size: $size) {
        ...MagazineEdition
        magazineId
        commonTitle
      }
    }
  }
  ${MagazineEditionFragmentDoc}
`;

/**
 * __useEditionsQuery__
 *
 * To run a query within a React component, call `useEditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditionsQuery({
 *   variables: {
 *      days: // value for 'days'
 *      size: // value for 'size'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useEditionsQuery(
  baseOptions?: Apollo.QueryHookOptions<EditionsQuery, EditionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EditionsQuery, EditionsQueryVariables>(EditionsDocument, options);
}
export function useEditionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EditionsQuery, EditionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EditionsQuery, EditionsQueryVariables>(EditionsDocument, options);
}
export type EditionsQueryHookResult = ReturnType<typeof useEditionsQuery>;
export type EditionsLazyQueryHookResult = ReturnType<typeof useEditionsLazyQuery>;
export type EditionsQueryResult = Apollo.QueryResult<EditionsQuery, EditionsQueryVariables>;
export const LatestNewsDocument = gql`
  query LatestNews($labels: [String!], $applicationKey: String) {
    news(labels: $labels, applicationKey: $applicationKey) {
      id
      title
      teaser
      publicationDate
    }
  }
`;

/**
 * __useLatestNewsQuery__
 *
 * To run a query within a React component, call `useLatestNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestNewsQuery({
 *   variables: {
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useLatestNewsQuery(
  baseOptions?: Apollo.QueryHookOptions<LatestNewsQuery, LatestNewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LatestNewsQuery, LatestNewsQueryVariables>(LatestNewsDocument, options);
}
export function useLatestNewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LatestNewsQuery, LatestNewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LatestNewsQuery, LatestNewsQueryVariables>(
    LatestNewsDocument,
    options,
  );
}
export type LatestNewsQueryHookResult = ReturnType<typeof useLatestNewsQuery>;
export type LatestNewsLazyQueryHookResult = ReturnType<typeof useLatestNewsLazyQuery>;
export type LatestNewsQueryResult = Apollo.QueryResult<LatestNewsQuery, LatestNewsQueryVariables>;
export const LawHistoryDocument = gql`
  query LawHistory($applicationKey: String, $documentKey: String!, $baseUrl: String) {
    fullLawHistory(applicationKey: $applicationKey, documentKey: $documentKey) {
      date
      versionType
      dossierNumber
      dateRetroactive
      bwbCanonicalLink(baseUrl: $baseUrl) {
        link
        linkStatus
      }
      textInPubEnactment
      isTemporalTreatyVersion
      publicationsEnactment {
        text
        opLink(baseUrl: $baseUrl) {
          link
          linkStatus
        }
      }
      lines {
        versionType
        dateRetroactive
        dossierNumber
        textInPubEnactment
        publicationsEnactment {
          text
          opLink(baseUrl: $baseUrl) {
            link
            linkStatus
          }
        }
      }
    }
  }
`;

/**
 * __useLawHistoryQuery__
 *
 * To run a query within a React component, call `useLawHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLawHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLawHistoryQuery({
 *   variables: {
 *      applicationKey: // value for 'applicationKey'
 *      documentKey: // value for 'documentKey'
 *      baseUrl: // value for 'baseUrl'
 *   },
 * });
 */
export function useLawHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<LawHistoryQuery, LawHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LawHistoryQuery, LawHistoryQueryVariables>(LawHistoryDocument, options);
}
export function useLawHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LawHistoryQuery, LawHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LawHistoryQuery, LawHistoryQueryVariables>(
    LawHistoryDocument,
    options,
  );
}
export type LawHistoryQueryHookResult = ReturnType<typeof useLawHistoryQuery>;
export type LawHistoryLazyQueryHookResult = ReturnType<typeof useLawHistoryLazyQuery>;
export type LawHistoryQueryResult = Apollo.QueryResult<LawHistoryQuery, LawHistoryQueryVariables>;
export const MagazineEditionYearsDocument = gql`
  query MagazineEditionYears($magazineId: String, $labels: [String!], $applicationKey: String) {
    magazines(id: $magazineId, labels: $labels, applicationKey: $applicationKey) {
      id
      editionYears
    }
  }
`;

/**
 * __useMagazineEditionYearsQuery__
 *
 * To run a query within a React component, call `useMagazineEditionYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMagazineEditionYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMagazineEditionYearsQuery({
 *   variables: {
 *      magazineId: // value for 'magazineId'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useMagazineEditionYearsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MagazineEditionYearsQuery,
    MagazineEditionYearsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MagazineEditionYearsQuery, MagazineEditionYearsQueryVariables>(
    MagazineEditionYearsDocument,
    options,
  );
}
export function useMagazineEditionYearsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MagazineEditionYearsQuery,
    MagazineEditionYearsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MagazineEditionYearsQuery, MagazineEditionYearsQueryVariables>(
    MagazineEditionYearsDocument,
    options,
  );
}
export type MagazineEditionYearsQueryHookResult = ReturnType<typeof useMagazineEditionYearsQuery>;
export type MagazineEditionYearsLazyQueryHookResult = ReturnType<
  typeof useMagazineEditionYearsLazyQuery
>;
export type MagazineEditionYearsQueryResult = Apollo.QueryResult<
  MagazineEditionYearsQuery,
  MagazineEditionYearsQueryVariables
>;
export const MagazineEditionsDocument = gql`
  query MagazineEditions(
    $magazineId: String
    $from: Int
    $size: Int
    $year: Int
    $labels: [String!]
    $applicationKey: String
  ) {
    magazines(id: $magazineId, labels: $labels, applicationKey: $applicationKey) {
      id
      editionYears
      editions(year: $year, from: $from, size: $size) {
        ...MagazineEdition
        title
        shortTitle
        pdfEditionId
        articleNumberRange
      }
    }
  }
  ${MagazineEditionFragmentDoc}
`;

/**
 * __useMagazineEditionsQuery__
 *
 * To run a query within a React component, call `useMagazineEditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMagazineEditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMagazineEditionsQuery({
 *   variables: {
 *      magazineId: // value for 'magazineId'
 *      from: // value for 'from'
 *      size: // value for 'size'
 *      year: // value for 'year'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useMagazineEditionsQuery(
  baseOptions?: Apollo.QueryHookOptions<MagazineEditionsQuery, MagazineEditionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MagazineEditionsQuery, MagazineEditionsQueryVariables>(
    MagazineEditionsDocument,
    options,
  );
}
export function useMagazineEditionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MagazineEditionsQuery, MagazineEditionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MagazineEditionsQuery, MagazineEditionsQueryVariables>(
    MagazineEditionsDocument,
    options,
  );
}
export type MagazineEditionsQueryHookResult = ReturnType<typeof useMagazineEditionsQuery>;
export type MagazineEditionsLazyQueryHookResult = ReturnType<typeof useMagazineEditionsLazyQuery>;
export type MagazineEditionsQueryResult = Apollo.QueryResult<
  MagazineEditionsQuery,
  MagazineEditionsQueryVariables
>;
export const MagazinesDocument = gql`
  query Magazines($labels: [String!], $applicationKey: String) {
    magazines(labels: $labels, applicationKey: $applicationKey) {
      ...MagazineBase
    }
  }
  ${MagazineBaseFragmentDoc}
`;

/**
 * __useMagazinesQuery__
 *
 * To run a query within a React component, call `useMagazinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMagazinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMagazinesQuery({
 *   variables: {
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useMagazinesQuery(
  baseOptions?: Apollo.QueryHookOptions<MagazinesQuery, MagazinesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MagazinesQuery, MagazinesQueryVariables>(MagazinesDocument, options);
}
export function useMagazinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MagazinesQuery, MagazinesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MagazinesQuery, MagazinesQueryVariables>(MagazinesDocument, options);
}
export type MagazinesQueryHookResult = ReturnType<typeof useMagazinesQuery>;
export type MagazinesLazyQueryHookResult = ReturnType<typeof useMagazinesLazyQuery>;
export type MagazinesQueryResult = Apollo.QueryResult<MagazinesQuery, MagazinesQueryVariables>;
export const PnNavigationDrawerDocument = gql`
  query PnNavigationDrawer(
    $documentKey: String!
    $applicationKey: String
    $baseUrl: String
    $subId: String
    $docId: String
    $fetchAll: Boolean = false
  ) {
    navigationDrawer(
      documentKey: $documentKey
      applicationKey: $applicationKey
      baseUrl: $baseUrl
      id: $subId
      docId: $docId
    ) {
      label: name
      depth
      ... on TopLevelTocEntry {
        children: entries {
          ...PnNavigationDrawerItem
          ... on GenericTocEntry {
            children: entries @include(if: $fetchAll) {
              ...PnNavigationDrawerItem
              ... on GenericTocEntry {
                children: entries {
                  ...PnNavigationDrawerItem
                  ... on GenericTocEntry {
                    children: entries {
                      ... on SubLevelTocEntry {
                        id
                        label: title
                        to: link
                        ... on DocumentTocEntry {
                          hasChildren
                          depth
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      ... on GenericTocEntry {
        children: entries {
          ...PnNavigationDrawerItem
          ... on GenericTocEntry {
            children: entries @include(if: $fetchAll) {
              ...PnNavigationDrawerItem
              ... on GenericTocEntry {
                children: entries {
                  ...PnNavigationDrawerItem
                  ... on GenericTocEntry {
                    children: entries {
                      ... on SubLevelTocEntry {
                        id
                        label: title
                        to: link
                        ... on DocumentTocEntry {
                          hasChildren
                          depth
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${PnNavigationDrawerItemFragmentDoc}
`;

/**
 * __usePnNavigationDrawerQuery__
 *
 * To run a query within a React component, call `usePnNavigationDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePnNavigationDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePnNavigationDrawerQuery({
 *   variables: {
 *      documentKey: // value for 'documentKey'
 *      applicationKey: // value for 'applicationKey'
 *      baseUrl: // value for 'baseUrl'
 *      subId: // value for 'subId'
 *      docId: // value for 'docId'
 *      fetchAll: // value for 'fetchAll'
 *   },
 * });
 */
export function usePnNavigationDrawerQuery(
  baseOptions: Apollo.QueryHookOptions<PnNavigationDrawerQuery, PnNavigationDrawerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PnNavigationDrawerQuery, PnNavigationDrawerQueryVariables>(
    PnNavigationDrawerDocument,
    options,
  );
}
export function usePnNavigationDrawerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PnNavigationDrawerQuery,
    PnNavigationDrawerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PnNavigationDrawerQuery, PnNavigationDrawerQueryVariables>(
    PnNavigationDrawerDocument,
    options,
  );
}
export type PnNavigationDrawerQueryHookResult = ReturnType<typeof usePnNavigationDrawerQuery>;
export type PnNavigationDrawerLazyQueryHookResult = ReturnType<
  typeof usePnNavigationDrawerLazyQuery
>;
export type PnNavigationDrawerQueryResult = Apollo.QueryResult<
  PnNavigationDrawerQuery,
  PnNavigationDrawerQueryVariables
>;
export const RecentMagazineArticlesFeedDocument = gql`
  query RecentMagazineArticlesFeed(
    $magazineId: String!
    $from: Int
    $size: Int
    $labels: [String!]
    $applicationKey: String
    $baseUrl: String
  ) {
    magazines(
      id: $magazineId
      labels: $labels
      applicationKey: $applicationKey
      baseUrl: $baseUrl
    ) {
      id
      editionYears
      articles(id: $magazineId, from: $from, size: $size, baseUrl: $baseUrl) {
        articles {
          ...ArticlesFeedItem
        }
      }
    }
  }
  ${ArticlesFeedItemFragmentDoc}
`;

/**
 * __useRecentMagazineArticlesFeedQuery__
 *
 * To run a query within a React component, call `useRecentMagazineArticlesFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentMagazineArticlesFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentMagazineArticlesFeedQuery({
 *   variables: {
 *      magazineId: // value for 'magazineId'
 *      from: // value for 'from'
 *      size: // value for 'size'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *      baseUrl: // value for 'baseUrl'
 *   },
 * });
 */
export function useRecentMagazineArticlesFeedQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecentMagazineArticlesFeedQuery,
    RecentMagazineArticlesFeedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecentMagazineArticlesFeedQuery, RecentMagazineArticlesFeedQueryVariables>(
    RecentMagazineArticlesFeedDocument,
    options,
  );
}
export function useRecentMagazineArticlesFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecentMagazineArticlesFeedQuery,
    RecentMagazineArticlesFeedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecentMagazineArticlesFeedQuery,
    RecentMagazineArticlesFeedQueryVariables
  >(RecentMagazineArticlesFeedDocument, options);
}
export type RecentMagazineArticlesFeedQueryHookResult = ReturnType<
  typeof useRecentMagazineArticlesFeedQuery
>;
export type RecentMagazineArticlesFeedLazyQueryHookResult = ReturnType<
  typeof useRecentMagazineArticlesFeedLazyQuery
>;
export type RecentMagazineArticlesFeedQueryResult = Apollo.QueryResult<
  RecentMagazineArticlesFeedQuery,
  RecentMagazineArticlesFeedQueryVariables
>;
export const RecentMagazinesArticlesFeedDocument = gql`
  query RecentMagazinesArticlesFeed(
    $from: Int
    $size: Int
    $labels: [String!]
    $applicationKey: String
    $baseUrl: String
  ) {
    allArticles(
      from: $from
      size: $size
      labels: $labels
      applicationKey: $applicationKey
      baseUrl: $baseUrl
    ) {
      articles {
        ...ArticlesFeedItem
      }
    }
  }
  ${ArticlesFeedItemFragmentDoc}
`;

/**
 * __useRecentMagazinesArticlesFeedQuery__
 *
 * To run a query within a React component, call `useRecentMagazinesArticlesFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentMagazinesArticlesFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentMagazinesArticlesFeedQuery({
 *   variables: {
 *      from: // value for 'from'
 *      size: // value for 'size'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *      baseUrl: // value for 'baseUrl'
 *   },
 * });
 */
export function useRecentMagazinesArticlesFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RecentMagazinesArticlesFeedQuery,
    RecentMagazinesArticlesFeedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecentMagazinesArticlesFeedQuery,
    RecentMagazinesArticlesFeedQueryVariables
  >(RecentMagazinesArticlesFeedDocument, options);
}
export function useRecentMagazinesArticlesFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecentMagazinesArticlesFeedQuery,
    RecentMagazinesArticlesFeedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecentMagazinesArticlesFeedQuery,
    RecentMagazinesArticlesFeedQueryVariables
  >(RecentMagazinesArticlesFeedDocument, options);
}
export type RecentMagazinesArticlesFeedQueryHookResult = ReturnType<
  typeof useRecentMagazinesArticlesFeedQuery
>;
export type RecentMagazinesArticlesFeedLazyQueryHookResult = ReturnType<
  typeof useRecentMagazinesArticlesFeedLazyQuery
>;
export type RecentMagazinesArticlesFeedQueryResult = Apollo.QueryResult<
  RecentMagazinesArticlesFeedQuery,
  RecentMagazinesArticlesFeedQueryVariables
>;
export const RecentNewsArticlesFeedDocument = gql`
  query RecentNewsArticlesFeed(
    $from: Int
    $size: Int
    $applicationKey: String
    $newsId: String
    $sections: [String!]
  ) {
    allNews(
      from: $from
      size: $size
      applicationKey: $applicationKey
      id: $newsId
      sections: $sections
    ) {
      ...NewsFeedItem
    }
  }
  ${NewsFeedItemFragmentDoc}
`;

/**
 * __useRecentNewsArticlesFeedQuery__
 *
 * To run a query within a React component, call `useRecentNewsArticlesFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentNewsArticlesFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentNewsArticlesFeedQuery({
 *   variables: {
 *      from: // value for 'from'
 *      size: // value for 'size'
 *      applicationKey: // value for 'applicationKey'
 *      newsId: // value for 'newsId'
 *      sections: // value for 'sections'
 *   },
 * });
 */
export function useRecentNewsArticlesFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RecentNewsArticlesFeedQuery,
    RecentNewsArticlesFeedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecentNewsArticlesFeedQuery, RecentNewsArticlesFeedQueryVariables>(
    RecentNewsArticlesFeedDocument,
    options,
  );
}
export function useRecentNewsArticlesFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecentNewsArticlesFeedQuery,
    RecentNewsArticlesFeedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecentNewsArticlesFeedQuery, RecentNewsArticlesFeedQueryVariables>(
    RecentNewsArticlesFeedDocument,
    options,
  );
}
export type RecentNewsArticlesFeedQueryHookResult = ReturnType<
  typeof useRecentNewsArticlesFeedQuery
>;
export type RecentNewsArticlesFeedLazyQueryHookResult = ReturnType<
  typeof useRecentNewsArticlesFeedLazyQuery
>;
export type RecentNewsArticlesFeedQueryResult = Apollo.QueryResult<
  RecentNewsArticlesFeedQuery,
  RecentNewsArticlesFeedQueryVariables
>;
export const MagazineEditionsSidebarBlockDocument = gql`
  query MagazineEditionsSidebarBlock(
    $size: Int
    $magazineId: String!
    $labels: [String!]
    $applicationKey: String
  ) {
    magazines(id: $magazineId, labels: $labels, applicationKey: $applicationKey) {
      editions(size: $size) {
        ...MagazineEdition
        magazineId
        commonTitle
      }
    }
  }
  ${MagazineEditionFragmentDoc}
`;

/**
 * __useMagazineEditionsSidebarBlockQuery__
 *
 * To run a query within a React component, call `useMagazineEditionsSidebarBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useMagazineEditionsSidebarBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMagazineEditionsSidebarBlockQuery({
 *   variables: {
 *      size: // value for 'size'
 *      magazineId: // value for 'magazineId'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useMagazineEditionsSidebarBlockQuery(
  baseOptions: Apollo.QueryHookOptions<
    MagazineEditionsSidebarBlockQuery,
    MagazineEditionsSidebarBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MagazineEditionsSidebarBlockQuery,
    MagazineEditionsSidebarBlockQueryVariables
  >(MagazineEditionsSidebarBlockDocument, options);
}
export function useMagazineEditionsSidebarBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MagazineEditionsSidebarBlockQuery,
    MagazineEditionsSidebarBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MagazineEditionsSidebarBlockQuery,
    MagazineEditionsSidebarBlockQueryVariables
  >(MagazineEditionsSidebarBlockDocument, options);
}
export type MagazineEditionsSidebarBlockQueryHookResult = ReturnType<
  typeof useMagazineEditionsSidebarBlockQuery
>;
export type MagazineEditionsSidebarBlockLazyQueryHookResult = ReturnType<
  typeof useMagazineEditionsSidebarBlockLazyQuery
>;
export type MagazineEditionsSidebarBlockQueryResult = Apollo.QueryResult<
  MagazineEditionsSidebarBlockQuery,
  MagazineEditionsSidebarBlockQueryVariables
>;
export const MagazinesSidebarBlockDocument = gql`
  query MagazinesSidebarBlock($size: Int, $labels: [String!], $applicationKey: String) {
    magazines(size: $size, labels: $labels, applicationKey: $applicationKey) {
      ...MagazineBase
    }
  }
  ${MagazineBaseFragmentDoc}
`;

/**
 * __useMagazinesSidebarBlockQuery__
 *
 * To run a query within a React component, call `useMagazinesSidebarBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useMagazinesSidebarBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMagazinesSidebarBlockQuery({
 *   variables: {
 *      size: // value for 'size'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useMagazinesSidebarBlockQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MagazinesSidebarBlockQuery,
    MagazinesSidebarBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MagazinesSidebarBlockQuery, MagazinesSidebarBlockQueryVariables>(
    MagazinesSidebarBlockDocument,
    options,
  );
}
export function useMagazinesSidebarBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MagazinesSidebarBlockQuery,
    MagazinesSidebarBlockQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MagazinesSidebarBlockQuery, MagazinesSidebarBlockQueryVariables>(
    MagazinesSidebarBlockDocument,
    options,
  );
}
export type MagazinesSidebarBlockQueryHookResult = ReturnType<typeof useMagazinesSidebarBlockQuery>;
export type MagazinesSidebarBlockLazyQueryHookResult = ReturnType<
  typeof useMagazinesSidebarBlockLazyQuery
>;
export type MagazinesSidebarBlockQueryResult = Apollo.QueryResult<
  MagazinesSidebarBlockQuery,
  MagazinesSidebarBlockQueryVariables
>;
export const NewsSourcesDocument = gql`
  query NewsSources($size: Int, $applicationKey: String, $newsId: String) {
    newsPublishers(size: $size, applicationKey: $applicationKey, id: $newsId) {
      ...NewsPublisher
    }
  }
  ${NewsPublisherFragmentDoc}
`;

/**
 * __useNewsSourcesQuery__
 *
 * To run a query within a React component, call `useNewsSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsSourcesQuery({
 *   variables: {
 *      size: // value for 'size'
 *      applicationKey: // value for 'applicationKey'
 *      newsId: // value for 'newsId'
 *   },
 * });
 */
export function useNewsSourcesQuery(
  baseOptions?: Apollo.QueryHookOptions<NewsSourcesQuery, NewsSourcesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewsSourcesQuery, NewsSourcesQueryVariables>(NewsSourcesDocument, options);
}
export function useNewsSourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NewsSourcesQuery, NewsSourcesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewsSourcesQuery, NewsSourcesQueryVariables>(
    NewsSourcesDocument,
    options,
  );
}
export type NewsSourcesQueryHookResult = ReturnType<typeof useNewsSourcesQuery>;
export type NewsSourcesLazyQueryHookResult = ReturnType<typeof useNewsSourcesLazyQuery>;
export type NewsSourcesQueryResult = Apollo.QueryResult<
  NewsSourcesQuery,
  NewsSourcesQueryVariables
>;
export const TocEntriesDocument = gql`
  query TocEntries($swsId: String!, $labels: [String!], $applicationKey: String) {
    content(documentKey: $swsId, labels: $labels, applicationKey: $applicationKey) {
      id
      ... on Commentary {
        toc {
          children: entries {
            ...GenericTocEntry
          }
        }
      }
      ... on PracticeNote {
        toc {
          children: entries {
            ...PracticeNoteTocEntry
          }
        }
      }
      ... on CaseLaw {
        toc {
          children: entries {
            ...CaseLawTocEntry
          }
        }
      }
      ... on Law {
        toc {
          children: entries {
            ...LawTocEntry
          }
        }
      }
      ... on Eurlex {
        toc {
          children: entries {
            ...LawTocEntry
          }
        }
      }
      ... on Book {
        toc {
          children: entries {
            ...BookTocEntry
          }
        }
      }
      ... on Article {
        toc {
          children: entries {
            ...ArticleTocEntry
          }
        }
      }
    }
  }
  ${GenericTocEntryFragmentDoc}
  ${PracticeNoteTocEntryFragmentDoc}
  ${CaseLawTocEntryFragmentDoc}
  ${LawTocEntryFragmentDoc}
  ${BookTocEntryFragmentDoc}
  ${ArticleTocEntryFragmentDoc}
`;

/**
 * __useTocEntriesQuery__
 *
 * To run a query within a React component, call `useTocEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTocEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTocEntriesQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useTocEntriesQuery(
  baseOptions: Apollo.QueryHookOptions<TocEntriesQuery, TocEntriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TocEntriesQuery, TocEntriesQueryVariables>(TocEntriesDocument, options);
}
export function useTocEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TocEntriesQuery, TocEntriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TocEntriesQuery, TocEntriesQueryVariables>(
    TocEntriesDocument,
    options,
  );
}
export type TocEntriesQueryHookResult = ReturnType<typeof useTocEntriesQuery>;
export type TocEntriesLazyQueryHookResult = ReturnType<typeof useTocEntriesLazyQuery>;
export type TocEntriesQueryResult = Apollo.QueryResult<TocEntriesQuery, TocEntriesQueryVariables>;
export const ContentDocument = gql`
  query Content(
    $swsId: String!
    $baseUrl: String
    $codeBaseUrl: String
    $labels: [String!]
    $contentParts: [String!]
    $applicationKey: String
    $subId: String
  ) {
    content(
      documentKey: $swsId
      labels: $labels
      parts: $contentParts
      applicationKey: $applicationKey
      baseUrl: $baseUrl
    ) {
      ...ContentBase
      ...Article
      ...Book
      ...CaseLaw
      ...Commentary
      ...Eurlex
      ...ExternalSource
      ...Law
      ...MagazineArticle
      ...News
      ...OP
      ...PracticeNote
      ...Ruling
      ...Tool
      ...Overview
      hasToc
    }
  }
  ${ContentBaseFragmentDoc}
  ${ArticleFragmentDoc}
  ${BookFragmentDoc}
  ${CaseLawFragmentDoc}
  ${CommentaryFragmentDoc}
  ${EurlexFragmentDoc}
  ${ExternalSourceFragmentDoc}
  ${LawFragmentDoc}
  ${MagazineArticleFragmentDoc}
  ${NewsFragmentDoc}
  ${OpFragmentDoc}
  ${PracticeNoteFragmentDoc}
  ${RulingFragmentDoc}
  ${ToolFragmentDoc}
  ${OverviewFragmentDoc}
`;

/**
 * __useContentQuery__
 *
 * To run a query within a React component, call `useContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      codeBaseUrl: // value for 'codeBaseUrl'
 *      labels: // value for 'labels'
 *      contentParts: // value for 'contentParts'
 *      applicationKey: // value for 'applicationKey'
 *      subId: // value for 'subId'
 *   },
 * });
 */
export function useContentQuery(
  baseOptions: Apollo.QueryHookOptions<ContentQuery, ContentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContentQuery, ContentQueryVariables>(ContentDocument, options);
}
export function useContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContentQuery, ContentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContentQuery, ContentQueryVariables>(ContentDocument, options);
}
export type ContentQueryHookResult = ReturnType<typeof useContentQuery>;
export type ContentLazyQueryHookResult = ReturnType<typeof useContentLazyQuery>;
export type ContentQueryResult = Apollo.QueryResult<ContentQuery, ContentQueryVariables>;
export const ContentPrintDocument = gql`
  query ContentPrint(
    $swsId: String!
    $baseUrl: String
    $labels: [String!]
    $contentParts: [String!]
    $applicationKey: String
    $subId: String
  ) {
    content(
      documentKey: $swsId
      labels: $labels
      parts: $contentParts
      applicationKey: $applicationKey
    ) {
      ...ContentBase
      ...CommentaryPrint
      ...EurlexPrint
      ...LawPrint
      ...CaseLawPrint
      ...OPPrint
      ...MagazineArticlePrint
      ...BookPrint
      ...ArticlePrint
      ...NewsPrint
      ...PracticeNotePrint
      ...RulingPrint
      ...ExternalSourcePrint
      ...OverviewPrint
    }
  }
  ${ContentBaseFragmentDoc}
  ${CommentaryPrintFragmentDoc}
  ${EurlexPrintFragmentDoc}
  ${LawPrintFragmentDoc}
  ${CaseLawPrintFragmentDoc}
  ${OpPrintFragmentDoc}
  ${MagazineArticlePrintFragmentDoc}
  ${BookPrintFragmentDoc}
  ${ArticlePrintFragmentDoc}
  ${NewsPrintFragmentDoc}
  ${PracticeNotePrintFragmentDoc}
  ${RulingPrintFragmentDoc}
  ${ExternalSourcePrintFragmentDoc}
  ${OverviewPrintFragmentDoc}
`;

/**
 * __useContentPrintQuery__
 *
 * To run a query within a React component, call `useContentPrintQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentPrintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentPrintQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      contentParts: // value for 'contentParts'
 *      applicationKey: // value for 'applicationKey'
 *      subId: // value for 'subId'
 *   },
 * });
 */
export function useContentPrintQuery(
  baseOptions: Apollo.QueryHookOptions<ContentPrintQuery, ContentPrintQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContentPrintQuery, ContentPrintQueryVariables>(
    ContentPrintDocument,
    options,
  );
}
export function useContentPrintLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContentPrintQuery, ContentPrintQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContentPrintQuery, ContentPrintQueryVariables>(
    ContentPrintDocument,
    options,
  );
}
export type ContentPrintQueryHookResult = ReturnType<typeof useContentPrintQuery>;
export type ContentPrintLazyQueryHookResult = ReturnType<typeof useContentPrintLazyQuery>;
export type ContentPrintQueryResult = Apollo.QueryResult<
  ContentPrintQuery,
  ContentPrintQueryVariables
>;
export const InPageSearchDocument = gql`
  query InPageSearch($documentKey: String!, $query: QueryParameters!, $applicationKey: String) {
    inPageSearch(documentKey: $documentKey, query: $query, applicationKey: $applicationKey) {
      partId
      totalResults
    }
  }
`;

/**
 * __useInPageSearchQuery__
 *
 * To run a query within a React component, call `useInPageSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useInPageSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInPageSearchQuery({
 *   variables: {
 *      documentKey: // value for 'documentKey'
 *      query: // value for 'query'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useInPageSearchQuery(
  baseOptions: Apollo.QueryHookOptions<InPageSearchQuery, InPageSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InPageSearchQuery, InPageSearchQueryVariables>(
    InPageSearchDocument,
    options,
  );
}
export function useInPageSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InPageSearchQuery, InPageSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InPageSearchQuery, InPageSearchQueryVariables>(
    InPageSearchDocument,
    options,
  );
}
export type InPageSearchQueryHookResult = ReturnType<typeof useInPageSearchQuery>;
export type InPageSearchLazyQueryHookResult = ReturnType<typeof useInPageSearchLazyQuery>;
export type InPageSearchQueryResult = Apollo.QueryResult<
  InPageSearchQuery,
  InPageSearchQueryVariables
>;
export const HistoryDocument = gql`
  query History($swsId: String!, $baseUrl: String, $labels: [String!], $applicationKey: String) {
    content(documentKey: $swsId, labels: $labels, applicationKey: $applicationKey) {
      id
      title
      ... on Commentary {
        tabs {
          ...Tab
          ... on Parlementair {
            label
            icon
            dossiers {
              id
              title
              references(baseUrl: $baseUrl) {
                id
                number
                title
                publicationDate
                canonicalLink
                linkStatus
                pdfAttachment
              }
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __useHistoryQuery__
 *
 * To run a query within a React component, call `useHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<HistoryQuery, HistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HistoryQuery, HistoryQueryVariables>(HistoryDocument, options);
}
export function useHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HistoryQuery, HistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HistoryQuery, HistoryQueryVariables>(HistoryDocument, options);
}
export type HistoryQueryHookResult = ReturnType<typeof useHistoryQuery>;
export type HistoryLazyQueryHookResult = ReturnType<typeof useHistoryLazyQuery>;
export type HistoryQueryResult = Apollo.QueryResult<HistoryQuery, HistoryQueryVariables>;
export const JurisprudenceDocument = gql`
  query Jurisprudence(
    $swsId: String!
    $fromYear: Int
    $toYear: Int
    $baseUrl: String
    $labels: [String!]
    $applicationKey: String
  ) {
    content(documentKey: $swsId, labels: $labels, applicationKey: $applicationKey) {
      id
      ... on Commentary {
        tabs {
          ...Tab
          ... on Jurisprudentie {
            htmlBlob(baseUrl: $baseUrl)
            references(fromYear: $fromYear, toYear: $toYear, baseUrl: $baseUrl) {
              title
              swsLinkID
              canonicalLink
              linkStatus
              caseNumber
              caseDate
              contributor
              creator
              ecli
              ecliCanonicalLink
              ecliLinkStatus
              publication
              isInCassation
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __useJurisprudenceQuery__
 *
 * To run a query within a React component, call `useJurisprudenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useJurisprudenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJurisprudenceQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      fromYear: // value for 'fromYear'
 *      toYear: // value for 'toYear'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useJurisprudenceQuery(
  baseOptions: Apollo.QueryHookOptions<JurisprudenceQuery, JurisprudenceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JurisprudenceQuery, JurisprudenceQueryVariables>(
    JurisprudenceDocument,
    options,
  );
}
export function useJurisprudenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JurisprudenceQuery, JurisprudenceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JurisprudenceQuery, JurisprudenceQueryVariables>(
    JurisprudenceDocument,
    options,
  );
}
export type JurisprudenceQueryHookResult = ReturnType<typeof useJurisprudenceQuery>;
export type JurisprudenceLazyQueryHookResult = ReturnType<typeof useJurisprudenceLazyQuery>;
export type JurisprudenceQueryResult = Apollo.QueryResult<
  JurisprudenceQuery,
  JurisprudenceQueryVariables
>;
export const LawArticleDocument = gql`
  query LawArticle($swsId: String!, $baseUrl: String, $labels: [String!], $applicationKey: String) {
    content(documentKey: $swsId, labels: $labels, applicationKey: $applicationKey) {
      id
      ... on Commentary {
        tabs {
          ...Tab
          ... on WetsTab {
            laws {
              lawTitle
              lawIdentifier
              articleHtmlBlob(baseUrl: $baseUrl)
              articleAnchor
              isExpired
              isMultipleArticle
              isWholeLaw
              lawValidityStart
              articleData(baseUrl: $baseUrl) {
                title
                articleLink
              }
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __useLawArticleQuery__
 *
 * To run a query within a React component, call `useLawArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useLawArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLawArticleQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useLawArticleQuery(
  baseOptions: Apollo.QueryHookOptions<LawArticleQuery, LawArticleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LawArticleQuery, LawArticleQueryVariables>(LawArticleDocument, options);
}
export function useLawArticleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LawArticleQuery, LawArticleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LawArticleQuery, LawArticleQueryVariables>(
    LawArticleDocument,
    options,
  );
}
export type LawArticleQueryHookResult = ReturnType<typeof useLawArticleQuery>;
export type LawArticleLazyQueryHookResult = ReturnType<typeof useLawArticleLazyQuery>;
export type LawArticleQueryResult = Apollo.QueryResult<LawArticleQuery, LawArticleQueryVariables>;
export const LegislationDocument = gql`
  query Legislation(
    $swsId: String!
    $baseUrl: String
    $labels: [String!]
    $applicationKey: String
  ) {
    content(documentKey: $swsId, labels: $labels, applicationKey: $applicationKey) {
      id
      ... on Commentary {
        tabs {
          ...Tab
          ... on Besluiten {
            references(baseUrl: $baseUrl) {
              signatureDate
              validityDate
              title
              swsLinkID
              canonicalLink
              linkStatus
              source
              sourceCanonicalLink
              sourceLinkStatus
              kenmerk
              status
            }
            rulingReferences(baseUrl: $baseUrl) {
              identifier
              title
              canonicalLink
              linkStatus
              publicationDate
              kenmerk
              status
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __useLegislationQuery__
 *
 * To run a query within a React component, call `useLegislationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegislationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegislationQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useLegislationQuery(
  baseOptions: Apollo.QueryHookOptions<LegislationQuery, LegislationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LegislationQuery, LegislationQueryVariables>(LegislationDocument, options);
}
export function useLegislationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LegislationQuery, LegislationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LegislationQuery, LegislationQueryVariables>(
    LegislationDocument,
    options,
  );
}
export type LegislationQueryHookResult = ReturnType<typeof useLegislationQuery>;
export type LegislationLazyQueryHookResult = ReturnType<typeof useLegislationLazyQuery>;
export type LegislationQueryResult = Apollo.QueryResult<
  LegislationQuery,
  LegislationQueryVariables
>;
export const LiteratureDocument = gql`
  query Literature($swsId: String!, $baseUrl: String, $labels: [String!], $applicationKey: String) {
    content(documentKey: $swsId, labels: $labels, applicationKey: $applicationKey) {
      id
      ... on Commentary {
        tabs {
          ...Tab
          ... on Literatuur {
            htmlBlob(baseUrl: $baseUrl)
            references(baseUrl: $baseUrl) {
              title
              swsLinkID
              canonicalLink
              publicationName
              publicationYear
              publicationNumber
              number
              publicationDate
              author
              source
              linkType
              sourceUrl
              linkStatus
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __useLiteratureQuery__
 *
 * To run a query within a React component, call `useLiteratureQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiteratureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiteratureQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useLiteratureQuery(
  baseOptions: Apollo.QueryHookOptions<LiteratureQuery, LiteratureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LiteratureQuery, LiteratureQueryVariables>(LiteratureDocument, options);
}
export function useLiteratureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LiteratureQuery, LiteratureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LiteratureQuery, LiteratureQueryVariables>(
    LiteratureDocument,
    options,
  );
}
export type LiteratureQueryHookResult = ReturnType<typeof useLiteratureQuery>;
export type LiteratureLazyQueryHookResult = ReturnType<typeof useLiteratureLazyQuery>;
export type LiteratureQueryResult = Apollo.QueryResult<LiteratureQuery, LiteratureQueryVariables>;
export const CommentaryReferencesDocument = gql`
  query CommentaryReferences(
    $swsId: String!
    $parts: [String!]
    $baseUrl: String
    $labels: [String!]
    $applicationKey: String
  ) {
    content(documentKey: $swsId, parts: $parts, labels: $labels, applicationKey: $applicationKey) {
      ... on Law {
        contentParts(baseUrl: $baseUrl) {
          ... on LawContentPart {
            id
            tabs {
              ... on Commentaar {
                ...Tab
                references {
                  displayTitle
                  identifier
                  linkStatus
                  teaser
                  sources
                }
              }
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __useCommentaryReferencesQuery__
 *
 * To run a query within a React component, call `useCommentaryReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentaryReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentaryReferencesQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      parts: // value for 'parts'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useCommentaryReferencesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CommentaryReferencesQuery,
    CommentaryReferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommentaryReferencesQuery, CommentaryReferencesQueryVariables>(
    CommentaryReferencesDocument,
    options,
  );
}
export function useCommentaryReferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommentaryReferencesQuery,
    CommentaryReferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CommentaryReferencesQuery, CommentaryReferencesQueryVariables>(
    CommentaryReferencesDocument,
    options,
  );
}
export type CommentaryReferencesQueryHookResult = ReturnType<typeof useCommentaryReferencesQuery>;
export type CommentaryReferencesLazyQueryHookResult = ReturnType<
  typeof useCommentaryReferencesLazyQuery
>;
export type CommentaryReferencesQueryResult = Apollo.QueryResult<
  CommentaryReferencesQuery,
  CommentaryReferencesQueryVariables
>;
export const LawJurisprudentieDocument = gql`
  query LawJurisprudentie(
    $swsId: String!
    $parts: [String!]
    $baseUrl: String
    $labels: [String!]
    $applicationKey: String
    $article: String!
  ) {
    content(documentKey: $swsId, parts: $parts, labels: $labels, applicationKey: $applicationKey) {
      ... on Law {
        contentParts(baseUrl: $baseUrl) {
          ... on LawContentPart {
            id
            tabs {
              ... on LawJurisprudentie {
                ...Tab
                references(baseUrl: $baseUrl, article: $article, swsId: $swsId) {
                  identifier
                  title
                  documentDate
                  caseDate
                  caseNumber
                  creator
                  reference {
                    title
                    linkItem {
                      link
                      linkStatus
                    }
                  }
                  ecli
                  ecliCanonicalLink
                  ecliLinkStatus
                }
              }
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __useLawJurisprudentieQuery__
 *
 * To run a query within a React component, call `useLawJurisprudentieQuery` and pass it any options that fit your needs.
 * When your component renders, `useLawJurisprudentieQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLawJurisprudentieQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      parts: // value for 'parts'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *      article: // value for 'article'
 *   },
 * });
 */
export function useLawJurisprudentieQuery(
  baseOptions: Apollo.QueryHookOptions<LawJurisprudentieQuery, LawJurisprudentieQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LawJurisprudentieQuery, LawJurisprudentieQueryVariables>(
    LawJurisprudentieDocument,
    options,
  );
}
export function useLawJurisprudentieLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LawJurisprudentieQuery,
    LawJurisprudentieQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LawJurisprudentieQuery, LawJurisprudentieQueryVariables>(
    LawJurisprudentieDocument,
    options,
  );
}
export type LawJurisprudentieQueryHookResult = ReturnType<typeof useLawJurisprudentieQuery>;
export type LawJurisprudentieLazyQueryHookResult = ReturnType<typeof useLawJurisprudentieLazyQuery>;
export type LawJurisprudentieQueryResult = Apollo.QueryResult<
  LawJurisprudentieQuery,
  LawJurisprudentieQueryVariables
>;
export const LawNewsDocument = gql`
  query LawNews(
    $swsId: String!
    $parts: [String!]
    $baseUrl: String
    $labels: [String!]
    $applicationKey: String
    $article: String!
  ) {
    content(documentKey: $swsId, parts: $parts, labels: $labels, applicationKey: $applicationKey) {
      ... on Law {
        contentParts(baseUrl: $baseUrl) {
          ... on LawContentPart {
            id
            tabs {
              ... on LawNews {
                ...Tab
                references(baseUrl: $baseUrl, article: $article, swsId: $swsId) {
                  identifier
                  title
                  newsType
                  documentDate
                  reference {
                    title
                    linkItem {
                      link
                      linkStatus
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __useLawNewsQuery__
 *
 * To run a query within a React component, call `useLawNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLawNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLawNewsQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      parts: // value for 'parts'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *      article: // value for 'article'
 *   },
 * });
 */
export function useLawNewsQuery(
  baseOptions: Apollo.QueryHookOptions<LawNewsQuery, LawNewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LawNewsQuery, LawNewsQueryVariables>(LawNewsDocument, options);
}
export function useLawNewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LawNewsQuery, LawNewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LawNewsQuery, LawNewsQueryVariables>(LawNewsDocument, options);
}
export type LawNewsQueryHookResult = ReturnType<typeof useLawNewsQuery>;
export type LawNewsLazyQueryHookResult = ReturnType<typeof useLawNewsLazyQuery>;
export type LawNewsQueryResult = Apollo.QueryResult<LawNewsQuery, LawNewsQueryVariables>;
export const LawPracticeNotesDocument = gql`
  query LawPracticeNotes(
    $swsId: String!
    $parts: [String!]
    $baseUrl: String
    $labels: [String!]
    $applicationKey: String
    $article: String!
  ) {
    content(documentKey: $swsId, parts: $parts, labels: $labels, applicationKey: $applicationKey) {
      ... on Law {
        contentParts(baseUrl: $baseUrl) {
          ... on LawContentPart {
            id
            tabs {
              ... on LawPracticeNotes {
                ...Tab
                references(baseUrl: $baseUrl, article: $article, swsId: $swsId) {
                  identifier
                  title
                  teaser
                  documentDate
                  authors
                  reference {
                    title
                    linkItem {
                      link
                      linkStatus
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __useLawPracticeNotesQuery__
 *
 * To run a query within a React component, call `useLawPracticeNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLawPracticeNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLawPracticeNotesQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      parts: // value for 'parts'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *      article: // value for 'article'
 *   },
 * });
 */
export function useLawPracticeNotesQuery(
  baseOptions: Apollo.QueryHookOptions<LawPracticeNotesQuery, LawPracticeNotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LawPracticeNotesQuery, LawPracticeNotesQueryVariables>(
    LawPracticeNotesDocument,
    options,
  );
}
export function useLawPracticeNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LawPracticeNotesQuery, LawPracticeNotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LawPracticeNotesQuery, LawPracticeNotesQueryVariables>(
    LawPracticeNotesDocument,
    options,
  );
}
export type LawPracticeNotesQueryHookResult = ReturnType<typeof useLawPracticeNotesQuery>;
export type LawPracticeNotesLazyQueryHookResult = ReturnType<typeof useLawPracticeNotesLazyQuery>;
export type LawPracticeNotesQueryResult = Apollo.QueryResult<
  LawPracticeNotesQuery,
  LawPracticeNotesQueryVariables
>;
export const LawVersionsDocument = gql`
  query LawVersions(
    $swsId: String!
    $parts: [String!]
    $baseUrl: String
    $labels: [String!]
    $applicationKey: String
  ) {
    content(documentKey: $swsId, parts: $parts, labels: $labels, applicationKey: $applicationKey) {
      ... on Law {
        contentParts(baseUrl: $baseUrl) {
          ... on LawContentPart {
            id
            tabs {
              ... on Versies {
                ...Tab
                versions {
                  date
                  dateRetroactive
                  publications {
                    text
                    opLink(baseUrl: $baseUrl) {
                      link
                      linkStatus
                    }
                  }
                  dossierNumber
                  bwbCanonicalLink(baseUrl: $baseUrl) {
                    link
                    linkStatus
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __useLawVersionsQuery__
 *
 * To run a query within a React component, call `useLawVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLawVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLawVersionsQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      parts: // value for 'parts'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useLawVersionsQuery(
  baseOptions: Apollo.QueryHookOptions<LawVersionsQuery, LawVersionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LawVersionsQuery, LawVersionsQueryVariables>(LawVersionsDocument, options);
}
export function useLawVersionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LawVersionsQuery, LawVersionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LawVersionsQuery, LawVersionsQueryVariables>(
    LawVersionsDocument,
    options,
  );
}
export type LawVersionsQueryHookResult = ReturnType<typeof useLawVersionsQuery>;
export type LawVersionsLazyQueryHookResult = ReturnType<typeof useLawVersionsLazyQuery>;
export type LawVersionsQueryResult = Apollo.QueryResult<
  LawVersionsQuery,
  LawVersionsQueryVariables
>;
export const MagazineDocument = gql`
  query Magazine(
    $magazineId: String!
    $labels: [String!]
    $baseUrl: String!
    $applicationKey: String
  ) {
    magazines(id: $magazineId, labels: $labels, applicationKey: $applicationKey) {
      ...MagazineBase
      legislativeOverview {
        identifier
        editorialDate
        link(baseUrl: $baseUrl) {
          link
          linkStatus
        }
      }
    }
  }
  ${MagazineBaseFragmentDoc}
`;

/**
 * __useMagazineQuery__
 *
 * To run a query within a React component, call `useMagazineQuery` and pass it any options that fit your needs.
 * When your component renders, `useMagazineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMagazineQuery({
 *   variables: {
 *      magazineId: // value for 'magazineId'
 *      labels: // value for 'labels'
 *      baseUrl: // value for 'baseUrl'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useMagazineQuery(
  baseOptions: Apollo.QueryHookOptions<MagazineQuery, MagazineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MagazineQuery, MagazineQueryVariables>(MagazineDocument, options);
}
export function useMagazineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MagazineQuery, MagazineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MagazineQuery, MagazineQueryVariables>(MagazineDocument, options);
}
export type MagazineQueryHookResult = ReturnType<typeof useMagazineQuery>;
export type MagazineLazyQueryHookResult = ReturnType<typeof useMagazineLazyQuery>;
export type MagazineQueryResult = Apollo.QueryResult<MagazineQuery, MagazineQueryVariables>;
export const MagazineEditionDocument = gql`
  query MagazineEdition(
    $magazineId: String!
    $editionId: String!
    $magazineUrl: String
    $section: [String!]
    $type: [String!]
    $labels: [String!]
    $applicationKey: String
    $baseUrl: String
    $shouldFetchEditionPDF: Boolean = false
  ) {
    magazines(
      id: $magazineId
      labels: $labels
      applicationKey: $applicationKey
      baseUrl: $baseUrl
    ) {
      ...MagazineBase
      legislativeOverview {
        identifier
        editorialDate
        link(baseUrl: $baseUrl) {
          link
          linkStatus
        }
      }
      editions(id: $editionId) {
        ...MagazineEdition
        title
        sections
        types
        shortTitle
        editionPDF @include(if: $shouldFetchEditionPDF)
        prevNextEdition(id: $editionId, baseUrl: $magazineUrl) {
          next {
            link
            linkStatus
          }
          previous {
            link
            linkStatus
          }
        }
        articles(section: $section, type: $type) {
          ...MagazineEditionArticle
          teaser
          abstract
        }
      }
    }
  }
  ${MagazineBaseFragmentDoc}
  ${MagazineEditionFragmentDoc}
  ${MagazineEditionArticleFragmentDoc}
`;

/**
 * __useMagazineEditionQuery__
 *
 * To run a query within a React component, call `useMagazineEditionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMagazineEditionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMagazineEditionQuery({
 *   variables: {
 *      magazineId: // value for 'magazineId'
 *      editionId: // value for 'editionId'
 *      magazineUrl: // value for 'magazineUrl'
 *      section: // value for 'section'
 *      type: // value for 'type'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *      baseUrl: // value for 'baseUrl'
 *      shouldFetchEditionPDF: // value for 'shouldFetchEditionPDF'
 *   },
 * });
 */
export function useMagazineEditionQuery(
  baseOptions: Apollo.QueryHookOptions<MagazineEditionQuery, MagazineEditionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MagazineEditionQuery, MagazineEditionQueryVariables>(
    MagazineEditionDocument,
    options,
  );
}
export function useMagazineEditionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MagazineEditionQuery, MagazineEditionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MagazineEditionQuery, MagazineEditionQueryVariables>(
    MagazineEditionDocument,
    options,
  );
}
export type MagazineEditionQueryHookResult = ReturnType<typeof useMagazineEditionQuery>;
export type MagazineEditionLazyQueryHookResult = ReturnType<typeof useMagazineEditionLazyQuery>;
export type MagazineEditionQueryResult = Apollo.QueryResult<
  MagazineEditionQuery,
  MagazineEditionQueryVariables
>;
export const PnVerdiepingenDocument = gql`
  query PnVerdiepingen(
    $swsId: String!
    $baseUrl: String
    $labels: [String!]
    $applicationKey: String
  ) {
    content(documentKey: $swsId, labels: $labels, applicationKey: $applicationKey) {
      id
      ... on PracticeNote {
        tabs {
          ...Tab
          ... on PNVerdieping {
            references(baseUrl: $baseUrl) {
              documentDate
              creators
              reference {
                title
                linkItem {
                  link
                  linkStatus
                }
              }
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __usePnVerdiepingenQuery__
 *
 * To run a query within a React component, call `usePnVerdiepingenQuery` and pass it any options that fit your needs.
 * When your component renders, `usePnVerdiepingenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePnVerdiepingenQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function usePnVerdiepingenQuery(
  baseOptions: Apollo.QueryHookOptions<PnVerdiepingenQuery, PnVerdiepingenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PnVerdiepingenQuery, PnVerdiepingenQueryVariables>(
    PnVerdiepingenDocument,
    options,
  );
}
export function usePnVerdiepingenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PnVerdiepingenQuery, PnVerdiepingenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PnVerdiepingenQuery, PnVerdiepingenQueryVariables>(
    PnVerdiepingenDocument,
    options,
  );
}
export type PnVerdiepingenQueryHookResult = ReturnType<typeof usePnVerdiepingenQuery>;
export type PnVerdiepingenLazyQueryHookResult = ReturnType<typeof usePnVerdiepingenLazyQuery>;
export type PnVerdiepingenQueryResult = Apollo.QueryResult<
  PnVerdiepingenQuery,
  PnVerdiepingenQueryVariables
>;
export const PnJurisprudenceDocument = gql`
  query PnJurisprudence($swsId: String!, $baseUrl: String, $applicationKey: String) {
    content(documentKey: $swsId, applicationKey: $applicationKey) {
      id
      ... on PracticeNote {
        tabs {
          ...Tab
          ... on PNJurisprudentie {
            references(baseUrl: $baseUrl) {
              caseDate
              creator
              title
              abstract
              canonicalLink
              linkStatus
              publication
              ecliReferences {
                title
                linkItem {
                  link
                  linkStatus
                }
              }
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __usePnJurisprudenceQuery__
 *
 * To run a query within a React component, call `usePnJurisprudenceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePnJurisprudenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePnJurisprudenceQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function usePnJurisprudenceQuery(
  baseOptions: Apollo.QueryHookOptions<PnJurisprudenceQuery, PnJurisprudenceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PnJurisprudenceQuery, PnJurisprudenceQueryVariables>(
    PnJurisprudenceDocument,
    options,
  );
}
export function usePnJurisprudenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PnJurisprudenceQuery, PnJurisprudenceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PnJurisprudenceQuery, PnJurisprudenceQueryVariables>(
    PnJurisprudenceDocument,
    options,
  );
}
export type PnJurisprudenceQueryHookResult = ReturnType<typeof usePnJurisprudenceQuery>;
export type PnJurisprudenceLazyQueryHookResult = ReturnType<typeof usePnJurisprudenceLazyQuery>;
export type PnJurisprudenceQueryResult = Apollo.QueryResult<
  PnJurisprudenceQuery,
  PnJurisprudenceQueryVariables
>;
export const PnLawAndRegulationsDocument = gql`
  query PnLawAndRegulations(
    $swsId: String!
    $baseUrl: String
    $labels: [String!]
    $applicationKey: String
  ) {
    content(documentKey: $swsId, labels: $labels, applicationKey: $applicationKey) {
      id
      ... on PracticeNote {
        tabs {
          ...Tab
          ... on Wenr {
            references(baseUrl: $baseUrl) {
              ... on LawReference {
                canonicalLink
                linkStatus
                swsLinkID
                title
              }
              ... on ExternalReference {
                canonicalLink
                title
              }
            }
            sectionReferences {
              title
              references(baseUrl: $baseUrl)
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __usePnLawAndRegulationsQuery__
 *
 * To run a query within a React component, call `usePnLawAndRegulationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePnLawAndRegulationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePnLawAndRegulationsQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function usePnLawAndRegulationsQuery(
  baseOptions: Apollo.QueryHookOptions<PnLawAndRegulationsQuery, PnLawAndRegulationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PnLawAndRegulationsQuery, PnLawAndRegulationsQueryVariables>(
    PnLawAndRegulationsDocument,
    options,
  );
}
export function usePnLawAndRegulationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PnLawAndRegulationsQuery,
    PnLawAndRegulationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PnLawAndRegulationsQuery, PnLawAndRegulationsQueryVariables>(
    PnLawAndRegulationsDocument,
    options,
  );
}
export type PnLawAndRegulationsQueryHookResult = ReturnType<typeof usePnLawAndRegulationsQuery>;
export type PnLawAndRegulationsLazyQueryHookResult = ReturnType<
  typeof usePnLawAndRegulationsLazyQuery
>;
export type PnLawAndRegulationsQueryResult = Apollo.QueryResult<
  PnLawAndRegulationsQuery,
  PnLawAndRegulationsQueryVariables
>;
export const PnLiteratureDocument = gql`
  query PnLiterature(
    $swsId: String!
    $baseUrl: String
    $labels: [String!]
    $applicationKey: String
  ) {
    content(documentKey: $swsId, labels: $labels, applicationKey: $applicationKey) {
      id
      ... on PracticeNote {
        tabs {
          ...Tab
          ... on PNLiteratuur {
            htmlBlob(baseUrl: $baseUrl)
            html
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __usePnLiteratureQuery__
 *
 * To run a query within a React component, call `usePnLiteratureQuery` and pass it any options that fit your needs.
 * When your component renders, `usePnLiteratureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePnLiteratureQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function usePnLiteratureQuery(
  baseOptions: Apollo.QueryHookOptions<PnLiteratureQuery, PnLiteratureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PnLiteratureQuery, PnLiteratureQueryVariables>(
    PnLiteratureDocument,
    options,
  );
}
export function usePnLiteratureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PnLiteratureQuery, PnLiteratureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PnLiteratureQuery, PnLiteratureQueryVariables>(
    PnLiteratureDocument,
    options,
  );
}
export type PnLiteratureQueryHookResult = ReturnType<typeof usePnLiteratureQuery>;
export type PnLiteratureLazyQueryHookResult = ReturnType<typeof usePnLiteratureLazyQuery>;
export type PnLiteratureQueryResult = Apollo.QueryResult<
  PnLiteratureQuery,
  PnLiteratureQueryVariables
>;
export const PnNewsDocument = gql`
  query PnNews($swsId: String!, $baseUrl: String, $labels: [String!], $applicationKey: String) {
    content(documentKey: $swsId, labels: $labels, applicationKey: $applicationKey) {
      id
      ... on PracticeNote {
        tabs {
          ...Tab
          ... on PNNews {
            htmlBlob(baseUrl: $baseUrl)
            html
            references(baseUrl: $baseUrl) {
              title
              documentDate
              newsType
              reference {
                title
                linkItem {
                  link
                  linkStatus
                }
              }
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __usePnNewsQuery__
 *
 * To run a query within a React component, call `usePnNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePnNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePnNewsQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function usePnNewsQuery(
  baseOptions: Apollo.QueryHookOptions<PnNewsQuery, PnNewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PnNewsQuery, PnNewsQueryVariables>(PnNewsDocument, options);
}
export function usePnNewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PnNewsQuery, PnNewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PnNewsQuery, PnNewsQueryVariables>(PnNewsDocument, options);
}
export type PnNewsQueryHookResult = ReturnType<typeof usePnNewsQuery>;
export type PnNewsLazyQueryHookResult = ReturnType<typeof usePnNewsLazyQuery>;
export type PnNewsQueryResult = Apollo.QueryResult<PnNewsQuery, PnNewsQueryVariables>;
export const PnRelatedTopicsDocument = gql`
  query PnRelatedTopics(
    $swsId: String!
    $baseUrl: String
    $labels: [String!]
    $applicationKey: String
  ) {
    content(documentKey: $swsId, labels: $labels, applicationKey: $applicationKey) {
      id
      ... on PracticeNote {
        tabs {
          ...Tab
          ... on PNGerelateerdeOnderwerpen {
            references(baseUrl: $baseUrl) {
              documentDate
              reference {
                title
                linkItem {
                  link
                  linkStatus
                }
              }
              creators
              abstract
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __usePnRelatedTopicsQuery__
 *
 * To run a query within a React component, call `usePnRelatedTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePnRelatedTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePnRelatedTopicsQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function usePnRelatedTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<PnRelatedTopicsQuery, PnRelatedTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PnRelatedTopicsQuery, PnRelatedTopicsQueryVariables>(
    PnRelatedTopicsDocument,
    options,
  );
}
export function usePnRelatedTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PnRelatedTopicsQuery, PnRelatedTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PnRelatedTopicsQuery, PnRelatedTopicsQueryVariables>(
    PnRelatedTopicsDocument,
    options,
  );
}
export type PnRelatedTopicsQueryHookResult = ReturnType<typeof usePnRelatedTopicsQuery>;
export type PnRelatedTopicsLazyQueryHookResult = ReturnType<typeof usePnRelatedTopicsLazyQuery>;
export type PnRelatedTopicsQueryResult = Apollo.QueryResult<
  PnRelatedTopicsQuery,
  PnRelatedTopicsQueryVariables
>;
export const PnToolsDocument = gql`
  query PnTools($swsId: String!, $baseUrl: String, $labels: [String!], $applicationKey: String) {
    content(documentKey: $swsId, labels: $labels, applicationKey: $applicationKey) {
      id
      ... on PracticeNote {
        tabs {
          ...Tab
          ... on PNTools {
            references(baseUrl: $baseUrl) {
              editorialDate
              creator
              reference {
                title
                linkItem {
                  link
                  linkStatus
                }
              }
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __usePnToolsQuery__
 *
 * To run a query within a React component, call `usePnToolsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePnToolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePnToolsQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function usePnToolsQuery(
  baseOptions: Apollo.QueryHookOptions<PnToolsQuery, PnToolsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PnToolsQuery, PnToolsQueryVariables>(PnToolsDocument, options);
}
export function usePnToolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PnToolsQuery, PnToolsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PnToolsQuery, PnToolsQueryVariables>(PnToolsDocument, options);
}
export type PnToolsQueryHookResult = ReturnType<typeof usePnToolsQuery>;
export type PnToolsLazyQueryHookResult = ReturnType<typeof usePnToolsLazyQuery>;
export type PnToolsQueryResult = Apollo.QueryResult<PnToolsQuery, PnToolsQueryVariables>;
export const ToolJurisprudenceDocument = gql`
  query ToolJurisprudence($swsId: String!, $baseUrl: String, $applicationKey: String) {
    content(documentKey: $swsId, applicationKey: $applicationKey) {
      id
      ... on Tool {
        tabs {
          ...Tab
          ... on ToolsJurisprudentie {
            htmlBlob(baseUrl: $baseUrl)
            html
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __useToolJurisprudenceQuery__
 *
 * To run a query within a React component, call `useToolJurisprudenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useToolJurisprudenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useToolJurisprudenceQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useToolJurisprudenceQuery(
  baseOptions: Apollo.QueryHookOptions<ToolJurisprudenceQuery, ToolJurisprudenceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ToolJurisprudenceQuery, ToolJurisprudenceQueryVariables>(
    ToolJurisprudenceDocument,
    options,
  );
}
export function useToolJurisprudenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ToolJurisprudenceQuery,
    ToolJurisprudenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ToolJurisprudenceQuery, ToolJurisprudenceQueryVariables>(
    ToolJurisprudenceDocument,
    options,
  );
}
export type ToolJurisprudenceQueryHookResult = ReturnType<typeof useToolJurisprudenceQuery>;
export type ToolJurisprudenceLazyQueryHookResult = ReturnType<typeof useToolJurisprudenceLazyQuery>;
export type ToolJurisprudenceQueryResult = Apollo.QueryResult<
  ToolJurisprudenceQuery,
  ToolJurisprudenceQueryVariables
>;
export const ToolsLawDocument = gql`
  query ToolsLaw($swsId: String!, $baseUrl: String, $labels: [String!], $applicationKey: String) {
    content(documentKey: $swsId, labels: $labels, applicationKey: $applicationKey) {
      id
      ... on Tool {
        tabs {
          ...Tab
          ... on ToolsLaw {
            htmlBlob(baseUrl: $baseUrl)
            html
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __useToolsLawQuery__
 *
 * To run a query within a React component, call `useToolsLawQuery` and pass it any options that fit your needs.
 * When your component renders, `useToolsLawQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useToolsLawQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useToolsLawQuery(
  baseOptions: Apollo.QueryHookOptions<ToolsLawQuery, ToolsLawQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ToolsLawQuery, ToolsLawQueryVariables>(ToolsLawDocument, options);
}
export function useToolsLawLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ToolsLawQuery, ToolsLawQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ToolsLawQuery, ToolsLawQueryVariables>(ToolsLawDocument, options);
}
export type ToolsLawQueryHookResult = ReturnType<typeof useToolsLawQuery>;
export type ToolsLawLazyQueryHookResult = ReturnType<typeof useToolsLawLazyQuery>;
export type ToolsLawQueryResult = Apollo.QueryResult<ToolsLawQuery, ToolsLawQueryVariables>;
export const ToolNewsDocument = gql`
  query ToolNews($swsId: String!, $baseUrl: String, $labels: [String!], $applicationKey: String) {
    content(documentKey: $swsId, labels: $labels, applicationKey: $applicationKey) {
      id
      ... on Tool {
        tabs {
          ...Tab
          ... on ToolsNews {
            references(baseUrl: $baseUrl) {
              title
              documentDate
              newsType
              reference {
                title
                linkItem {
                  link
                  linkStatus
                }
              }
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __useToolNewsQuery__
 *
 * To run a query within a React component, call `useToolNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useToolNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useToolNewsQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useToolNewsQuery(
  baseOptions: Apollo.QueryHookOptions<ToolNewsQuery, ToolNewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ToolNewsQuery, ToolNewsQueryVariables>(ToolNewsDocument, options);
}
export function useToolNewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ToolNewsQuery, ToolNewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ToolNewsQuery, ToolNewsQueryVariables>(ToolNewsDocument, options);
}
export type ToolNewsQueryHookResult = ReturnType<typeof useToolNewsQuery>;
export type ToolNewsLazyQueryHookResult = ReturnType<typeof useToolNewsLazyQuery>;
export type ToolNewsQueryResult = Apollo.QueryResult<ToolNewsQuery, ToolNewsQueryVariables>;
export const ToolsRelatedTopicsDocument = gql`
  query ToolsRelatedTopics(
    $swsId: String!
    $baseUrl: String
    $labels: [String!]
    $applicationKey: String
  ) {
    content(documentKey: $swsId, labels: $labels, applicationKey: $applicationKey) {
      id
      ... on Tool {
        tabs {
          ...Tab
          ... on ToolsRelatedTopics {
            references(baseUrl: $baseUrl) {
              documentDate
              reference {
                title
                linkItem {
                  link
                  linkStatus
                }
              }
              creators
              abstract
            }
          }
        }
      }
    }
  }
  ${TabFragmentDoc}
`;

/**
 * __useToolsRelatedTopicsQuery__
 *
 * To run a query within a React component, call `useToolsRelatedTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useToolsRelatedTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useToolsRelatedTopicsQuery({
 *   variables: {
 *      swsId: // value for 'swsId'
 *      baseUrl: // value for 'baseUrl'
 *      labels: // value for 'labels'
 *      applicationKey: // value for 'applicationKey'
 *   },
 * });
 */
export function useToolsRelatedTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<ToolsRelatedTopicsQuery, ToolsRelatedTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ToolsRelatedTopicsQuery, ToolsRelatedTopicsQueryVariables>(
    ToolsRelatedTopicsDocument,
    options,
  );
}
export function useToolsRelatedTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ToolsRelatedTopicsQuery,
    ToolsRelatedTopicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ToolsRelatedTopicsQuery, ToolsRelatedTopicsQueryVariables>(
    ToolsRelatedTopicsDocument,
    options,
  );
}
export type ToolsRelatedTopicsQueryHookResult = ReturnType<typeof useToolsRelatedTopicsQuery>;
export type ToolsRelatedTopicsLazyQueryHookResult = ReturnType<
  typeof useToolsRelatedTopicsLazyQuery
>;
export type ToolsRelatedTopicsQueryResult = Apollo.QueryResult<
  ToolsRelatedTopicsQuery,
  ToolsRelatedTopicsQueryVariables
>;
