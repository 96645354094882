interface FlagValue {
  key: string;
  label: string;
}

interface StaticSiteConfigItem {
  key: string;
}

interface FeatureFlagValues extends StaticSiteConfigItem {
  values: FlagValue[];
}

export interface StaticSiteConfig {
  menu: StaticSiteConfigItem;
  featureFlags: FeatureFlagValues;
  cmsGraphqlUrl: StaticSiteConfigItem;
  graphqlUrl: StaticSiteConfigItem;
  searchGraphqlUrl: StaticSiteConfigItem;
  bluetickGraphqlUrl: StaticSiteConfigItem;
  printGraphqlUrl: StaticSiteConfigItem;
  alertsGraphqlUrl: StaticSiteConfigItem;
  userPreferencesGraphqlUrl: StaticSiteConfigItem;
  officeDocsGraphqlUrl: StaticSiteConfigItem;
  authUrl: StaticSiteConfigItem;
  autocompleteList: StaticSiteConfigItem;
  completemeGraphqlUrl: StaticSiteConfigItem;
  intelligentSearchCore: StaticSiteConfigItem;
  userSourcesGraphqlUrl: StaticSiteConfigItem;
}

const staticSiteConfig: StaticSiteConfig = {
  menu: {
    key: 'SDUSC_menu',
  },
  authUrl: {
    key: 'SDUSC_AuthUrl',
  },
  cmsGraphqlUrl: {
    key: 'SDUSC_CmsGraphqlUrl',
  },
  graphqlUrl: {
    key: 'SDUSC_GraphqlUrl',
  },
  searchGraphqlUrl: {
    key: 'SDUSC_SearchGraphqlUrl',
  },
  bluetickGraphqlUrl: {
    key: 'SDUSC_BluetickGraphqlUrl',
  },
  printGraphqlUrl: {
    key: 'SDUSC_PrintGraphqlUrl',
  },
  alertsGraphqlUrl: {
    key: 'SDUSC_AlertsGraphqlUrl',
  },
  userPreferencesGraphqlUrl: {
    key: 'SDUSC_UserPreferencesGraphqlUrl',
  },
  officeDocsGraphqlUrl: {
    key: 'SDUSC_OfficeDocsGraphqlUrl',
  },
  autocompleteList: {
    key: 'SDUSC_AutocompleteList',
  },
  completemeGraphqlUrl: {
    key: 'SDUSC_CompletemeGraphqlUrl',
  },
  userSourcesGraphqlUrl: {
    key: 'SDUSC_UserSourcesGraphqlUrl',
  },
  intelligentSearchCore: {
    key: 'SDUSC_IntelligentSearchCore',
  },
  featureFlags: {
    key: 'SDUSC_FeatureFlags',
    values: [
      {
        key: 'WITH_FEATURE_FLAGS',
        label: 'With feature flag tool',
      },
      {
        key: 'WITH_NEWS',
        label: 'With NDFR news feed',
      },
      {
        key: 'WITH_ALERTS',
        label: 'With Alerts',
      },
      {
        key: 'WITH_ONBOARDING',
        label: 'With NDFR onboarding bar & marketing block',
      },
      {
        key: 'WITH_ONBOARDING_TOOLTIPS',
        label: 'With onboarding tooltips',
      },
      {
        key: 'WITH_IN_PAGE_SEARCH',
        label: 'With in page search functionality',
      },
      {
        key: 'WITHOUT_MAGAZINE_EDITION_PDF',
        label: 'With full magazine edition pdf download',
      },
      {
        key: 'WITH_PUBLIC_SITE',
        label: 'With public website without auth',
      },
      {
        key: 'WITH_HOMEPAGE_SEARCH',
        label: 'With home page focused on searching',
      },
      {
        key: 'WITH_BLUETICK_CORE_CONSIDERATIONS',
        label: 'With Bluetick kernoverwegingen in search results',
      },
      {
        key: 'WITH_BLUETICK_AI',
        label: 'Enables GenIA-L QA',
      },
      {
        key: 'WITH_BLUETICK_AI_CHAT',
        label: 'Enables GenIA-L Chat',
      },
      {
        key: 'WITH_BLUETICK_AI_OTHER_SOURCES',
        label: 'Enables GenIA-L Other Sources Feature',
      },
      {
        key: 'WITH_XPOSI',
        label: 'Enables Xposi in the product site',
      },
      {
        key: 'WITH_ORIGINAL_SOURCE_LINKS',
        label: 'Enables original source links in the product site',
      },
      {
        key: 'WITH_GENIAL_INTEREST_HEYFLOW',
        label: 'Enables Genial interest Heyflow',
      },
    ],
  },
};

export { staticSiteConfig };
